import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// components:
import Loader from '../Loader'

const Roles = ({ screenSize, darkModeState, handleClick, handleDarkMode, roles, loader }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        buttonNew: {
            display: "flex",
            alignSelf: "flex-start",
            placeContent: "center",
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px 0",
            // margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 940 ? ('24vh') : (<></>)}`,
        },
        roles: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        singleRol: {
            display: "flex",
            //   width: `${screenSize > 940 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        nameRole: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        quantityAssets: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        quantityPeople: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",
        },
    };
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('ROL_LIST_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('ROL_LIST_SUBTITLE')}</h3>
                    </div>
                </div>

                <div style={styles.roles}>
                    <Link style={styles.buttonNew} className="btn btn-primary" to="/roles/create">{t('ROL_CREATE')}</Link>
                    {loader ? (
                        <div style={{ "marginTop": "-8vh" }}>
                            <Loader
                                type="tailspin"
                            />
                        </div>
                    ) : (
                        <>
                            {roles ? (
                                roles.map((singleRol, index) => {
                                    return (
                                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleRol} onClick={() => handleClick(`${index}`)} key={index}>
                                            <div style={styles.lineDetailCenter}>
                                                <div style={styles.nameRole}>{singleRol.rolName}</div>
                                            </div>
                                            <div style={styles.lineDetail}>
                                                <div style={styles.quantityAssets}>{singleRol.assetsList.length} {t('ASSETS_LABEL')}</div>
                                                <div style={styles.quantityPeople}>5 {t('PEOPLE_LABEL')}</div>
                                            </div>
                                        </div>
                                    )
                                })) : (null)}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Roles 