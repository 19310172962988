import React from "react";
import { useTranslation } from 'react-i18next';

//components
import SelectorLanguages from "./SelectorLanguages";

const SidebarActions = ({ handleLogout, loginUser, handleUser, screenSize, handleBack }) => {
    const styles = {
        icons: {
            marginRight: "15px",
            width: '18px',
            height: '20px',
        },
        linkButton: {
            padding: 15,
            paddingRight: 20,
            paddingLeft: 30,
            display: "flex",
            alignItems: "center",
            backgroundColor: '#fff',
            color: '#63687e',
            border: 'none',
            outline: 0,
            width: '100%',
            fontSize: '95%'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <ul className="list-unstyled" >
            <li>
                <button className="btn btn-link" style={styles.linkButton} onClick={handleBack}>
                    <svg style={styles.icons} fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                    </svg>
                    {t('SIDEBAR_HOME')}
                </button>
            </li>

            {loginUser ? (
                <li>
                    <button className="btn btn-link" style={styles.linkButton} onClick={handleUser}>
                        <svg style={styles.icons} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                        </svg>
                        {`${t('SIDEBAR_HELLO')} ${loginUser.name}`}
                    </button>
                </li>
            ) : (<li>
                <button className="btn btn-link" style={styles.linkButton} disabled>
                    <svg style={styles.icons} fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                    </svg>
                    {`${t('SIDEBAR_HELLO')}`}
                </button>
            </li>)}

            <li>
                {/* SELECTOR LENGUAJES, para resolucion grande lo incluimos aqui, en el sidebar, si es pequeña no lo incluimos pq lo mostraremos como en Login, arriba a la derecha de la pantalla*/}
                {
                    screenSize > 940 ?
                        <SelectorLanguages direction='dropright' screenSize={screenSize} />
                        :
                        <></>
                }
            </li>

            <li>
                <button className="btn btn-link" style={styles.linkButton} onClick={handleLogout}>
                    <svg style={styles.icons} fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                    </svg>
                    {t('SIDEBAR_LOGOUT')}
                </button>
            </li>
        </ul>
    );
};

export default SidebarActions