import React from "react"; //{ useState, useEffect }
import { useTranslation } from 'react-i18next';

// components:
import Loader from './Loader'

const User = ({ screenSize, darkModeState, loader, loginUser, entries }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        info: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        navbar: {
            display: "flex",
            // flex: 1.5, lo cambiamos para que los datos nos salgan donde queremos
            flex: 0.1,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textUser: {
            fontSize: "14px",
            fontFamily: "InterBold",
            alignSelf: "left",
            textDecoration: "none",
            color: 'inherit',
            border: 'none',
            outline: 'none',
            padding: 5,
            backgroundColor: `transparent`
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        user: {
            fontFamily: "InterBold",
            fontSize: "18px",
            display: "flex",
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4% 0px -4px') : ("10px")}`,
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('USER_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('USER_SUBTITLE')} {loginUser.name}</h3>
                    </div>
                </div>

                <div style={styles.info}>
                    {loader ? (
                        <Loader
                            type="tailspin"
                        />
                    ) : (

                        <div style={styles.user}>
                            <p>{t('USER_P1')}</p>
                            <h1 style={styles.textUser}>{t('USER_P1_H1_1')} {loginUser.name} {loginUser.lastname}</h1>
                            <h1 style={styles.textUser}>{t('USER_P1_H1_2')} {loginUser.uid}</h1>
                            <h1 style={styles.textUser}>{t('USER_P1_H1_3')} {loginUser.rol || 'No definido'}</h1>
                            <h1 style={styles.textUser}>{t('USER_P1_H1_4')} {loginUser.companyName} </h1>

                            <hr></hr>
                            <p>{t('USER_P2')}</p>
                            {entries && entries.length > 0 ? (
                                entries.map((key, index) => {
                                    //console.log(key, index)
                                    return (
                                        <h1 style={styles.textUser} key={index}>{key[0]}: {key[1]}</h1>
                                        //<h1 style={styles.textUser} key={index}>{key.charAt(0).toUpperCase() + key.slice(1)}: {values[index]}</h1>
                                    )
                                })
                            ) : (
                                <div style={styles.containerNoTasks}>
                                    <img
                                        src="./assets/img/eric-feliz.png"
                                        height="150"
                                        alt="Eric Feliz imagen"
                                    />
                                    <div style={styles.noTasks}>{t('NO_DATA')}</div>
                                </div>
                            )}

                        </div>
                    )}

                </div >
            </div >
        </>
    );
};

export default User