
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { app, getAuth, logOut, getData, saveData} from "./firebaseConfig";
import { pathPart } from "../utils/commons";

import Loader from '../components/Loader'


//creamos el contexto
const Auth = React.createContext({ name: '', user: null });

//1. con este export default Context/Auth lo que hacemos en el context es devolver tanto el consumidor como el provider
export default Auth

//2. y lo que podemos hacer es crear nuestro ppio Provider. El consumidor es el que es, pero el provider es el que tiene el value y podriamos crear el nuestro ppio, y que en el value metamos la info que nos interesa
export const AuthContextProvider = ({ children }) => {
    const nav = useNavigate();
    const [user, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    // const [company, setCompany] = useState(null);

    // const callbackDef = async (data=null) => {
    //     if(data) {
    //         const cmpStr = pathPart(window.location.host, 0);
    //         const retSave = await saveData({coll: `companies/${company?.companyID}`, data});
    //         if(retSave) {
    //             const company = (await getData({coll: `companies`, wee: ['companyPath', '==', cmpStr]}))[0];
    //             setCompany(company);
    //         }
    //     }
    // }

    useEffect(() => {
        const au = getAuth(app);
        au.onAuthStateChanged(async (user) => {
            const cus = au.currentUser;
            const hayUser = cus != null
            if (hayUser) {
                const cmpStr = pathPart(window.location.host, 0);
                const comp = (await getData({ coll: `companies`, wee: ['companyPath', '==', cmpStr] }))[0];

                if (comp?.companyID) {
                    const dbUser = (await getData({ coll: `users/${comp?.companyID}/users/${user?.email}` }))[0];
                    // CLARIFICATIONS
                    // esto es por que si no existe el usuario en la compañia no puede hacer login, al tener los usuarios de varias compañias en la misma BD
                    // se puede plantear este problema, imaginate que uno de proasas intenta entrar en wombat, aunque por menu no va a ver nada ni tendrá acceso a las pags
                    // estará logeado y eso no puede ser
                    if (dbUser) {
                        //OJO => que aqui nos está cogiendo los roles: vendedor, jefe, vago... en Proassa es un array, para saber si es admin o no y mostrar unos menuses u otros... 
                        user.rol = dbUser.rol;
                        setUsuario(user);
                        // setCompany(comp);
                        nav('/home')
                    } else {
                        logOut();
                        setUsuario(null);
                        // setCompany(null);
                        nav('/');
                    }
                }
            } else {
                setUsuario(null)
                // setCompany(null)
                nav('/');
            }
            setShowChild(true);
        });
    }, []);

    if (!showChild) {
        // le pasamos una prop type al loader para poder reutilizar el componente
        return <Loader type={"dots"} />;
    } else {
        //aqui lo que hacemos en envolver al provider con un provider nuestro, y aqui ocurre la magia
        return (
            // <Auth.Provider value={{ user, company, callbackDef }} key={+new Date()}></Auth.Provider>
            <Auth.Provider value={{ user }} key={+new Date()}>
                {children}
            </Auth.Provider>
        );
    }
};

export const getAuthHeader = (user) => {
    if (user && (user.accessToken || (user.stsTokenManager && user.stsTokenManager.accessToken))) {
        const tk = user.accessToken || user.stsTokenManager.accessToken;
        const ret = { Authorization: 'Bearer ' + tk }
        // console.log(ret)
        return ret;
    } else {
        return {};
    }
}


// Esto lo tenemos en useAuthUser.js en Iotracker. En Proassa lo tenemos aqui, en el context.... si fallase lo que tengo tendriamos que mirar PRoassa que si funciona ok
// function useFirebaseAuth() {
//     const context = React.useContext(Auth);
//     if (context === undefined) {
//         throw new Error("useFirebaseAuth must be used within a FirebaseAuthProvider");
//     }
//     return { user: context.user, company: context.company, refreshCmp: context.callbackDef };
// }

// export { Auth, AuthContext, useFirebaseAuth };