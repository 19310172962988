import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import './i18nextconfig';
import './index.css'; //este index no se usa en facturas, sobreescribe un estilo para que las letras aparezcan más gruesas, pero nos da igual que no nos aparezca el estilo como en original. 
import './assets/css/navbar.css'  
import './assets/css/font.css'   
import './assets/css/globalStyles.css' 

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//TODO: RES GEMA => probar la interfaz, no responde bien, porque en la pantalla completa sin la consola se desplaza el contenedor debajo del sidebar. Comprobar que en gestion-pedidos hacia lo mismo, por si es culpa de las css que machaquen estilos o  que haya roto algo al atomizar componentes. 


// TODO: RES GEMA  o ERR-TUTORIA => tambien tienes que terminar lo de las css en lugar del style en cada componente. Aunque parece que el style sobreescribe a bootstrap y el css no, con lo cual hay estilos que no me coge si lo hago desde css... problemilla que se presenta. 

//TODO: RES GEMA  => priemro probar un build como esta hacer deploy del hosting en produccion, 2º hacer build sin carpeta assets de public y ver que es lo que carga y lo que no tb en produccion. (posiblemente la unica que haga falta sea la de langs porque es libreria)



//PAQUETES NPM QUE PODRIA UTILIZAR 
/**
 *  "antd": "^4.20.2",                => Un lenguaje de diseño de interfaz de usuario de clase empresarial y una biblioteca de interfaz de usuario React.
 *  "country-flag-icons": "^1.4.26",  => Vector (*.svg) country flag icons in 3x2 aspect ratio. banderas de paises para usar en cambio del lenguaje i18
 * 
    "html-to-react": "^1.4.8",        => A lightweight library that converts raw HTML to a React DOM structure.
 */