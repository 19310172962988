// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import Swal from 'sweetalert2';
import axios from "axios";
import { pathPart } from "../../utils/commons";
//TODO:  TUTORIA-ERR ¿es mejor hacer el pathpart cada vez que lo necesite como aqui, o mejor guardarlo en una const como tengo en components/flujo347.jsx por ejemplo?

// components:
import Politicas from "../../components/politicas/Politicas";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
// import { userDB } from "../../context/firebaseConfig";


const PoliticasClient = () => {
    // hooks:
    const { t } = useTranslation();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [loader, setLoader] = useState(true);
    const [calidad, setCalidad] = useState([]);
    const [seguridad, setSeguridad] = useState([]);
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenariamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `./assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            // console.log(response)
            if (response.status === 'KO') throw new Error('Error intentando recuperar los pdf');
            setCalidad(response.calidad);
            setSeguridad(response.seguridad);
            setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando pdf: ${e}`);
            setLoader(false)
            Swal.fire({
                // title: 'Se ha producido un error',
                // text: `No se pueden obtener los pdf de manera temporal.`, //[${e}]
                title: t('SWAL_ERR_TITLE'),
                text: `${t('SWAL_ERR_MSG_DOCUMENTS')}`, //[${e}]
                icon: 'error',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        });
        // }, [localStorage.i18nextLng])
    }, [t, user])

    // funciones adicionales:
    const dbData = (user) => {
        //obtenemos idioma para mostrar tipo, status y prioridad de ticket correctamente
        let lang = localStorage.getItem('i18nextLng') || 'es'
        return new Promise((resolve, reject) => {
            // const user = auth_service.getCurrentUser(); // si necesitasemos al usuario loggeado para algo.... 
            const params = { lang: lang }
            const headers = { headers: getAuthHeader(user) }
            // axios.post(`${process.env.REACT_APP_API_URL}/politics/iotracker/getPoliticsUrls`, params, headers)
            axios.post(`${process.env.REACT_APP_API_URL}/politics/${companyPath}/getPoliticsUrls`, params, headers)
                .then(data => {
                    // console.log(data)
                    // MEJOR VALIDAR QUE LO QUE NOS DEVUELVE DE LA API ES UN OK AL MENOS CON UN IF
                    if (data.data.status === 'OK') {
                        // console.log({calidad:data.data.detail.calidad, seguridad: data.data.detail.calidad})
                        resolve({ calidad: data.data.detail.calidad, seguridad: data.data.detail.calidad });
                    }
                    if (data.data.status === 'KO') throw new Error('error al intentar obtener los documentos de la BD');
                }).catch(e => {
                    reject(e)
                })
        }).catch(e => {
            if (e.status) { return e; }
            else return { status: 'KO', detail: e.message || e }
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    return (
        <>
            <Sidebar
                // path="./assets/img/logo-clear.png"                
                path= {path}
                module={location.pathname}
                screenSize={screenSize}
            />
            <Politicas
                screenSize={screenSize}
                darkModeState={darkmode}
                handleDarkMode={handleDarkMode}
                loader={loader}
                calidad={calidad}
                seguridad={seguridad}
            />
        </>
    );
}

export default PoliticasClient;



/* ESTA ERA LA OTRA FORMA SACANDO LAS FUNCIONES ADICIONALES A CUSTOM HOOKS, PERO PARECE QUE CARGA MAS LENTO Y ADEMAS EL LOADER NO SE MUESTRA HASTA QUE SE HA CARGADO LA PAGINA, SINO QUE ME MUESTRA EL CONTENEDOR CON LOS TEXTOS HASTA QUE CARGA LOS DATOS DE LA BD... CON LO CUAL NO NOS SIRVE. 
// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser"
import { useDbDesks } from "../../hooks/useDbDesks"
import { useDbTypes } from "../../hooks/useDbTypes"
import {useDbPolitics} from "../../hooks/useDbPolitics"

import Swal from 'sweetalert2';

// components:
import Politicas from "../../components/politicas/Politicas";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
// import { userDB } from "../../context/firebaseConfig";


const PoliticasClient = () => {
    // hooks:
    const { t } = useTranslation();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [loader, setLoader] = useState(true); 
 
    const { hpolitics, errorPolitics } = useDbPolitics() //customhook para obtener las politicas

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        setLoader(true)
        if (hpolitics) {
            // console.log(hpolitics) 
            setLoader(false)
        }
    }, [hpolitics])

    useEffect(() => {
        if (errorPolitics) {
            // console.log(errorPolitics)
            setLoader(false)
            Swal.fire({
                // title: 'Se ha producido un error',
                // text: `No se pueden obtener los pdf de manera temporal.`, //[${e}]
                title: t('SWAL_ERR_TITLE'),
                text: `${t('SWAL_ERR_MSG_DOCUMENTS')}`, //[${e}]
                icon: 'error',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        }
    }, [t, errorPolitics])

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };


    return (
        < >
            <Sidebar
                path="./assets/img/logo-clear.png"
                module={location.pathname}
            />
            <Politicas
                screenSize={screenSize}
                darkModeState={darkmode}
                handleDarkMode={handleDarkMode}
                loader={loader}
                calidad={hpolitics.calidad}
                seguridad={hpolitics.seguridad}
            />
        </>
    );
}

export default PoliticasClient; */