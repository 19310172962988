// hooks:
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import { pathPart } from "../../utils/commons";
import Swal from 'sweetalert2'
import axios from "axios";

// components: 
import OrdersCreate from "../../components/pedidos/OrdersCreate";
//OJO import CreateOrders from "../../components/pedidos/CreateOrders";  => aqui importamos el nombre que exportamos en el componente como lo nombremos alli es aqui, no ponemoms el nombre del fichero!!!! aunque en este caso es el mismo el fichero y el componente... pero hay que tenerlo en cuenta

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext' 
// import { userDB } from "../../context/firebaseConfig";

const OrdersCreateContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [loader, setLoader] = useState(false);
    //nif, nif_emisor, nro_documento, cuenta, nombre_sociedad, importe_ml, fecha_doc,
    const [nif, setNif] = useState('');
    const [doc, setDoc] = useState('');
    const [sociedad, setSociedad] = useState('');
    const [fecha, setFecha] = useState('');
    const [importe, setImporte] = useState('');
    // const [cuenta, setCuenta] = useState(''); //no necesario
    // const [emisor, setEmisor] = useState(''); //no necesario
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`

    // funciones adicionales:
    const sendData = (nif, nro_documento, nombre_sociedad, importe_ml, fecha_doc, user) => { //nif_emisor,cuenta
        return new Promise((resolve, reject) => {
            let params = {
                "nif": nif,
                "nro_documento": nro_documento,
                "nombre_sociedad": nombre_sociedad,
                "importe_ml": importe_ml,
                "fecha_doc": fecha_doc,
                // faltaría fecha_envio y enviado, que al crear el movimiento hay que establecer valores por defecto
                "fecha_envio": "",
                "enviado": false,
            }
            // const user = auth_service.getCurrentUser(); // si necesitasemos al usuario loggeado para algo.... 
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/movimiento/${companyPath}/setMov`, { movimiento: params }, headers)
                .then(data => {
                    // resolve(true) MEJOR VALIDAR QUE LO QUE NOS DEVUELVE DE LA API ES UN OK AL MENOS
                    if (data.data.status === 'OK') resolve(true)
                    if (data.data.status === 'KO') throw new Error('error al intentar crear un nuevo movimiento');
                }).catch(e => {
                    reject(e)
                })
        }).catch(e => {
            if (e.status) { return e; }
            else return { status: 'KO', detail: e.message || e }
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // handle para controlar los cambios en los campos del fomrulario
    ///nif, nro_documento, nombre_sociedad, importe_ml, fecha_doc, [nif_emisor, cuenta no los pedimos al usuario]
    const handleChange = (e) => {
        if (e.target.name === 'nif') {
            const nif = e.target.value;
            setNif(nif);
        } else if (e.target.name === 'nro_documento') {
            const nro_documento = e.target.value;
            setDoc(nro_documento);
        } else if (e.target.name === 'nombre_sociedad') {
            const nombre_sociedad = e.target.value;
            setSociedad(nombre_sociedad);
        } else if (e.target.name === 'importe_ml') {
            const importe_ml = e.target.value;
            setImporte(importe_ml);
        } else if (e.target.name === 'fecha_doc') {
            const fecha_doc = e.target.value;
            setFecha(fecha_doc);
        }/* else if (e.target.name === 'nif_emisor') {
            const nif_emisor = e.target.value;
            setEmisor(nif_emisor);
        } *//* else if (e.target.name === 'cuenta') {
            const cuenta = e.target.value;
            setCuenta(cuenta);
        } */
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (nif === '' || doc === '' || sociedad === '' || fecha === '' || importe === '') { // ||emisor === '' || cuenta === ''
            Swal.fire({
                title: t('SWAL_TITLE_NO_DATA'),
                text: t('SWAL_TEXT_NO_DATA'),
                icon: 'warning',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        } else {
            setLoader(true)
            sendData(nif, doc, sociedad, importe, fecha, user) //emisor, cuenta,
                .then(response => {
                    if (response.status === 'KO') throw new Error('error intentando crear el movimiento');
                    Swal.fire({
                        title: t('SWAL_TITLE_CREATED'),
                        text: t('SWAL_TEXT_CREATED_SINGLE_ORDER'),
                        icon: 'success',
                        confirmButtonColor: '#4dc2f1',
                        confirmButtonText: t('SWAL_BTN_CONTINUE'),
                        showCloseButton: 'true',
                    })
                    nav(`/orders`);
                }).catch(e => {
                    console.error(`Error: ${e}`);
                    Swal.fire({
                        title: t('SWAL_ERR_TITLE'),
                        text: `${t('SWAL_ERR_MSG_ORDER_CREATE')}`,
                        icon: 'error',
                        confirmButtonColor: '#4dc2f1',
                        confirmButtonText: t('SWAL_BTN_CONTINUE'),
                        showCloseButton: 'true',
                    })
                    nav(`/orders`);
                });
        }
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                // path="../assets/img/logo-clear.png"
                path= {path}
                screenSize={screenSize}
            />
            <OrdersCreate
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                handleChange={handleChange}
                loader={loader}
                nif={nif}
                nro_documento={doc}
                nombre_sociedad={sociedad}
                fecha_doc={fecha}
                importe_ml={importe}
            // cuenta={cuenta}
            // nif_emisor={emisor}
            />
        </>
    );
}

export default OrdersCreateContainer;