import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
// import i18resources from '../i18resources';
import i18next from "i18next";
import { defaultLang } from "../../utils/constants";

// components:
import Loader from '../Loader'

const OrdersCreate = ({ screenSize, darkModeState, handleSubmit, handleChange, nif, nro_documento, nombre_sociedad, importe_ml, fecha_doc, loader }) => { //nif_emisor,cuenta,
    //clase por defecto 75, no hay que seleccionarlo
    //vencimiento por defecto la misma que fecha_doc, no hay que seleccionarlo,
    //ml por defecto euros no hay que seleccionarlo,
    const styles = {
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            width: `${screenSize > 940 ? ('180px') : (<></>)
                }`,
            margin: '10px 0px 0px'
        },
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        form: {
            width: '100%',
            margin: '10px 0px'
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        orders: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
            // justifyContent: 'center'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4',
            //  margin: '0px'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || defaultLang);
    // eslint-disable-next-line no-unused-vars
    const changeLang = (code, e) => {
        e.preventDefault();
        setCurrentLang(code);
        i18next.changeLanguage(code);
    }

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('CREATE_ORDER_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('CREATE_ORDER_SUBTITLE')}</h3>
                    </div>
                </div>

                <div style={styles.orders}>
                    {loader ? (
                        //<div style={{"marginTop": "8vh"}}>
                        <Loader
                            type="tailspin"
                        />
                        //</div>
                    ) : (
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            style={styles.form}>
                            <div className="form-group" >
                                <br></br>

                                <label style={styles.label}>{t('SINGLE_ORDER_NIF')}</label>
                                <input onChange={(e) => handleChange(e)} name="nif" type="text" className="form-control" style={styles.input} value={nif} placeholder={t('PH_SINGLE_ORDER_NIF')} />

                                {/*  <label style={styles.label}>{t('SINGLE_ORDER_EMISOR')}</label>
                                <input onChange={(e) => handleChange(e)} name="nif_emisor" type="text" className="form-control" style={styles.input} value={nif_emisor} placeholder={t('PH_SINGLE_ORDER_EMISOR')} /> */}

                                {/* <label style={styles.label}>{t('SINGLE_ORDER_CUENTA')}</label>
                                <input onChange={(e) => handleChange(e)} name="cuenta" type="text" className="form-control" style={styles.input} value={cuenta} placeholder={t('PH_SINGLE_ORDER_CUENTA')} /> */}

                                <label style={styles.label}>{t('SINGLE_ORDER_SOCIEDAD')}</label>
                                <input onChange={(e) => handleChange(e)} name="nombre_sociedad" type="text" className="form-control" style={styles.input} value={nombre_sociedad} placeholder={t('PH_SINGLE_ORDER_SOCIEDAD')} />

                                <label style={styles.label}>{t('SINGLE_ORDER_NRODOC')}</label>
                                <input onChange={(e) => handleChange(e)} name="nro_documento" type="text" className="form-control" style={styles.input} value={nro_documento} placeholder={t('PH_SINGLE_ORDER_NRODOC')} />

                                <label style={styles.label}>{t('SINGLE_ORDER_FECHADOC')}</label>
                                <input onChange={(e) => handleChange(e)} name="fecha_doc" type="date" className="form-control" style={styles.input} value={fecha_doc} placeholder={t('PH_SINGLE_ORDER_FECHADOC')} />

                                <label style={styles.label}>{t('SINGLE_ORDER_IMPORTE')}</label>
                                <input onChange={(e) => handleChange(e)} name="importe_ml" type="text" className="form-control" style={styles.input} value={importe_ml} placeholder={t('PH_SINGLE_ORDER_IMPORTE')} />

                                <button type="submit" className="btn" style={styles.buttonNew}>{t('CREATE_ORDER_TITLE')}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

// export default CreateOrders => por este nombre que exportamos es por el que luego importamos en la vista
export default OrdersCreate