// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom"; //useNavigate
import { useAuthUser } from "../../hooks/useAuthUser";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import KnowledgeBase from "../../components/personas-jml/KnowledgeBase";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
import { userDB } from "../../context/firebaseConfig";


//TODO: GEMA HACER SERVICIO EN BACKEND PARA OBTENER LOS DOCUMENTOS DE LA BASE DE CONOCIMIENTO. 
//HACER EL ROBOT QUE SERÁ EL QUE NOS HAGA EL FILTRO POR KEYWORDS (OTRO SERVICIO)
//ESTO NO ESTA HECHO!!!!!!!! COPY&PASTE DE OTRO ARCHIVO, PERO ESTÁ SIN HACER!!!!! Y TRANSLATION TAMPOCO


const KnowledgeBaseContainer = () => {
    // hooks:
    // const nav = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const { t } = useTranslation();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [articles, setArticles] = useState([]);
    const [loader, setLoader] = useState(true);
    const [comments, setComments] = useState('');
    const [idTicket, setIdTicket] = useState('');
    const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto    
    const companyPath = pathPart(window.location.host, 0)
    const path = `./assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        //console.log(user) usando el customhook del context
        userDB(user.email)
            .then(res => {
                return res
            }).then((u) => {
                setLoginUser(u)
                return dbData(user)
            }).then(response => {
                setArticles(response); setLoader(false)
            }).catch(e => {
                console.error(`Error recuperando los articles: ${e}`);
            });
    }, [user])

    useEffect(() => {
        if (comments !== '') {
            Swal.fire({
                title: 'Close the task?',
                showCancelButton: 'true',
                showCloseButton: 'true',
                confirmButtonText: 'Close task',
                cancelButtonText: 'Still open',
                confirmButtonColor: '#4dc2f1',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true)
                    sendData(user, idTicket, loginUser.serviceDeskId, comments, result)
                        .then(response => {
                            Swal.fire({
                                title: 'Completed!',
                                text: 'Your task has been completed.',
                                icon: 'success',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: 'Continue',
                                showCloseButton: 'true',
                            })
                            setIdTicket('')
                            setComments('')
                            dbData(user).then(response => {
                                setArticles(response); setLoader(false)
                            }).catch(e => {
                                console.error(`Error recuperando los articles: ${e}`);
                            });
                        }).catch(e => {
                            console.error(`Error: ${e}`);
                        });
                } else {
                    setLoader(true)
                    sendData(user, idTicket, loginUser.serviceDeskId, comments, result)
                        .then(response => {
                            Swal.fire({
                                title: 'Task still open',
                                text: '',
                                icon: 'info',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: 'Continue',
                                showCloseButton: 'true',
                            })
                            setIdTicket('')
                            setComments('')
                            dbData(user).then(response => {
                                setArticles(response); setLoader(false)
                            }).catch(e => {
                                console.error(`Error recuperando los articles: ${e}`);
                            });
                        }).catch(e => {
                            console.error(`Error: ${e}`);
                        });
                }
            })
        }
    }, [comments, idTicket, loginUser.serviceDeskId, user])

    // funciones adicionales: TODO: GEMA => ESTO EN TASKS.jsx LO TENEMOS DE OTRA FOrMA...revisarlo, podemos cogerlo de BD o de Constants, que lo tenemos ahi de momento tambie hasta hacer limpia
    const getStatus = (id) => {
        const ticket = {
            1: 'New',
            2: 'Open',
            3: 'Pending',
            4: 'Waiting',
            5: 'Solved',
            6: 'Closed'
        }
        return ticket[id];
    }

    const getPriority = (id) => {
        const ticket = {
            1: 'Low',
            2: 'Medium',
            3: 'High',
            4: 'Urgent',
            5: 'Critical'
        }
        return ticket[id];
    }

    const getType = (id) => {
        const ticket = {
            1: "Incident",
            2: "Service request",
            3: "Question",
            4: "Problem",
            5: "Change request",
            6: "Major incident"
        }
        return ticket[id];
    }

    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            // const params = { email: 'gjimenez@newba.cloud' }
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/articles/${companyPath}/kbarticles`, params, headers)
                //HACER PARA GET /kb.articles.by.keywords del SD el servicio en api
                .then(data => {
                    let responseArray = data.data.detail
                    responseArray.forEach(singleTicket => {
                        singleTicket.type = getType(singleTicket.type_id);
                        singleTicket.status = getStatus(singleTicket.status_id);
                        singleTicket.priority = getPriority(singleTicket.priority_id);
                    });
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const sendData = (user, idTicket, idAgent, comment, type) => {
        let agentString = idAgent.toString()
        return new Promise((resolve, reject) => {
            let params = {}
            if (type.isConfirmed) {
                params = {
                    "request_id": idTicket, // el id o número de ticket/incident en SD. Require
                    "comment": comment,
                    "author_id": agentString, //el id del agente en SD    serviceDeskId
                    "is_solution": "1", // para que cambie el estado a solucionado. OPCIONAL
                }
            } else {
                //ESTA PARTE ESTA SIN TERMINAR
                // eslint-disable-next-line no-unused-vars
                params = {
                    "request_id": idTicket, // el id o número de ticket/incident en SD. Require
                    "comment": comment,
                    "author_id": agentString, //el id del agente en SD    serviceDeskId
                }
            }
            // eslint-disable-next-line no-unused-vars
            const headers = { headers: getAuthHeader(user) }
                // axios.post(`${process.env.REACT_APP_API_URL}/tickets/${companyPath}/commentToTicket`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    //manejador de cada movimiento para poder acceder al detalle
    const handleClick = (idSingleTicket) => {
        Swal.fire({
            title: 'Comments for the task?',
            showCancelButton: 'true',
            showCloseButton: 'true',
            confirmButtonText: 'Save comment',
            cancelButtonText: 'Cancel',
            denyButtonText: `Still open`,
            input: 'text',
            confirmButtonColor: '#4dc2f1',
            //  confirmButtonColor: '#26B899',
            reverseButtons: true,
            inputAttributes: {
                autocapitalize: 'off'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setIdTicket(idSingleTicket)
                setComments(result.value)
            }
        })
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                // path="./assets/img/logo-clear.png"
                path={path}
                screenSize={screenSize}
            />
            <KnowledgeBase
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                articles={articles}
                loader={loader}
            />
        </>
    );
}

export default KnowledgeBaseContainer;