import { Outlet, Navigate } from 'react-router';

// hooks
import { useAuthUser } from "../hooks/useAuthUser";

const ProtectedRoute = () => {
  const a = useAuthUser();
  return  a ? <Outlet /> : <Navigate to='/' />;
}

export default ProtectedRoute