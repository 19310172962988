import React from "react";
import { Link } from "react-router-dom";
import { capitalizeFirst, pathPart } from '../../utils/commons'

// multilenguaje
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const DashboardJML = ({ screenSize, darkModeState, handleClick, tickets, loader }) => {
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    //TODO:GEMA => para hacerlo bien, tendriamos tomar el idioma de i118n en type, status y priority de cada ticket, lo tenemos en components/tickets.jsx. Ahora cambia tambien, pero tiene algo de delay

    const companyPath = capitalizeFirst(pathPart(window.location.host, 0))
    let title = t('TITLE_DASHBOARD').replace('{{path}}', companyPath)

    //TODO:GEMA => revisar qué estilos se usan y cuales no. Ordenar alfabeticamente
    const styles = {
        borderCardT: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(195, 100%, 50%)",
            borderRadius: '5px 5px 0px 0px',
        },
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        contentSingleIndicator: {
            display: "flex",
            height: '100%',
            //  flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '30px',
        },
        descriptionDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        detailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
            letterSpacing: '5px',
            marginBottom: '20px'
        },
        detailIndicators: {
            display: "flex",
            flex: 9,
            flexDirection: "column",
        },
        generalIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: `${screenSize > 940 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        idDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        priorityDetailCard: {
            display: "flex",
            // color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            margin: 0,
            fontSize: "10px",
            fontFamily: "InterRegular",
            padding: '3px 10px',
            backgroundColor: '#4dc2f1',
            borderRadius: '20px',
            width: '60px',
            alignItems: 'center',
            justifyContent: 'center'
        },
        rowDetailIndicators: {
            display: "flex",
            flex: 10,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // flexDirection: "row",
            flexDirection: `${screenSize > 940 ? ('row') : ("column")}`,
            flexWrap: `${screenSize > 940 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 940 ? ('0px') : ("20px 0px")}`,
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 940 ? ("47%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
        },
        singleGenIndicator: {
            display: "flex",
            width: `${screenSize > 940 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("20px 10px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleDetailIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            fontSize: "24px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
            margin: `${screenSize > 940 ? ("0px 20px 20px") : ("0px")}`,
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        typeDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        statusDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",

        },
    };


    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{title}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('SUBTITLE_DASHBOARD')}</h3>
                    </div>
                </div>

                {loader ? (
                    <Loader
                        type="tailspin"
                    />
                ) : (<>
                    {/* TODO:GEMA los robotitos no son responsive. Cuando sea pantalla pequeña que aparezcan de dos en dos */}
                    <div style={styles.indicators}>
                        <div style={styles.generalIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/people-management">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>{t('BOT_JML')}</div>
                                    <img
                                        // src="https://i.postimg.cc/m2xp08F0/robot-1.png" 
                                        src="./assets/img/robot-1.png"
                                        height="55"
                                        alt="Robot of Chatbot"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/requests">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>{t('BOT_REQUEST')}</div>
                                    <img
                                        src="./assets/img/robot-2.png"
                                        height="55"
                                        alt="Robot of Chatbot"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/maintenance">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>{t('BOT_MAINTENANCE')}</div>
                                    <img
                                        src="./assets/img/robot-3.png"
                                        height="55"
                                        alt="Robot of Chatbot"
                                    />
                                </div>
                            </Link>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/support">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>{t('BOT_SUPPORT')}</div>
                                    <img
                                        src="./assets/img/robot-4.png"
                                        height="55"
                                        alt="Robot of Chatbot"
                                    />
                                </div>
                            </Link>
                        </div>
                        <div style={styles.detailIndicators}>
                            <h3 style={styles.titleDetailIndicators}>{t('OVERVIEW_TASKS')}</h3>
                            <div style={styles.rowDetailIndicators}>
                                {tickets ? (
                                    tickets.map((ticket) => {
                                        return (
                                            <div key={ticket.id} className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator} onClick={(e) => handleClick(ticket.id)} >
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.idDetailCard}>#{ticket.id}</div>
                                                    <div style={styles.priorityDetailCard}>{ticket.priority}</div>
                                                </div>
                                                <div style={styles.lineDetailCenter}>
                                                    <div style={styles.descriptionDetailCard}>{ticket.description}</div>
                                                </div>
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.typeDetailCard}>{ticket.type}</div>
                                                    <div style={styles.statusDetailCard}>{ticket.status}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
};

export default DashboardJML