// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import { pathPart } from "../../utils/commons";
import Swal from 'sweetalert2';
import axios from "axios";

// components:
import Shippings from "../../components/pedidos/Shippings";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
// import { userDB } from "../context/firebaseConfig";

const ShippingsContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [envios, setEnvios] = useState([]);
    const [loader, setLoader] = useState(true);
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenariamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `./assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            // console.log(response)
            if (response.status === 'KO') throw new Error('Error intentando recuperar envíos');
            setEnvios(response);
            setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando envíos: ${e}`);
            setLoader(false)
            Swal.fire({
                title: t('SWAL_ERR_TITLE'),
                text: `${t('SWAL_ERR_MSG_SHIPPINGS')}`, //[${e}]
                icon: 'error',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        });
    }, [t, user])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            // En este caso SOLO QUEREMOS MOSTRAR LOS movimientos/<companyId>/movimientos/ que tengan el campo enviado=false unicamente. SOLO QUEREMOS ESTOS MOVS PARA MOSTRARLOS EN Envios.jsx q son los pedidos pendientes de enviar, independiente de la fecha_envio (solo cuando se pase ésta se creará la incidencia)
            const params = { selector: 'enviado', enviado: false }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/movimiento/${companyPath}/getMovs`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    if (data.data.status === 'OK') {
                        resolve(responseArray);
                    }
                    if (data.data.status === 'KO') throw new Error('error al intentar obtener los envíos de la BD');
                }).catch(e => {
                    reject(e)
                })
        }).catch(e => {
            if (e.status) { return e; }
            else return { status: 'KO', detail: e.message || e }
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    //manejador de cada envio para poder acceder al detalle
    const handleClick = (idMovSelected) => {
        nav(`/shipping/${idMovSelected}`);
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                // path="./assets/img/logo-clear.png"
                path={path}
                screenSize={screenSize}
            />
            <Shippings
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                envios={envios}
                loader={loader}
            />
        </>
    );
}

export default ShippingsContainer;