import { DEFAULT_PROJECT_COMPANYPATH } from '../utils/constants'


//Funcion adicional para poner la primera letra en mayuscúla de una cadena de texto
const capitalizeFirst = (str) => {
  if (typeof str !== 'string') {
    throw new Error('El argumento debe ser una cadena de texto.');
  }
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Extrae el pathPart de la url para poder identificar la compañia de la que se trata. Indispensable para soportar multicompañia
 * Preparado para extraer la primera parte del host <compania>.<dominio> Ejemplo: pepito-gordo.newba.cloud
 * @param {String} s    = location / host de la pagina en concreto
 * @param {Number} idx  = indice del valor que queremos extraer del host
 * @returns 
 */
const pathPart = (s = window.location.host, idx = 1) => {
  // Nos divide la url para encontrar el companyPath o devuelve la configurada por DEFAULT
  // Por ejemplo:
  // let s = 'company.newba.cloud';             // devuelve company
  // let s = 'pepe-gordo.newba.cloud'           // devuelve pepe-gordo
  // let s = "web-tracker.firebaseapp.com"      // devuelve DEFAULT
  // let s = "panel.newba.cloud"                // devuelve DEFAULT
  // let s = 'newba.wombat.com:3000/home';      // devuelve newba
  // let s = 'insideone.wombat.com:3000/home';  // devuelve insideone

  try {
    var i = idx;
    var ret = '';
    try {
      ret = s.split(".")[i];
      if (s === 'localhost:3000') ret = DEFAULT_PROJECT_COMPANYPATH; // este if para q aparezca logos en localhost (complementa a company.wombat.com)

      if (ret === 'web-tracker' || ret === 'panel') ret = DEFAULT_PROJECT_COMPANYPATH; // el hosting por defecto nos redirigirá a DEFAULT. Si hay mas excepciones de companyPath se incluirían aqui
    }
    catch (e) { console.error(`pathPart error ${e.toString()}`); }
    // console.log(ret)
    return ret;
  } catch (e) { console.error(`pathPart error ${e.toString()}`); }
}

export {
  capitalizeFirst,
  pathPart
}