import React from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const RolSingle = ({ screenSize, darkModeState, handleSubmit, edit, handleChange, handleDelete, loader, rolName, totalAssets, assets }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        buttons: {
            display: "flex",
            flexDirection: 'row',
            margin: 0
        },
        buttonEdit: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 940 ? ('180px') : (<></>)}`,
        },
        buttonDelete: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "hsl(356, 69%, 56%)",
            border: 'none',
            margin: "20px 0px",
            // margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 940 ? ('180px') : (<></>)}`,
        },
        roles: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        form: {
            width: '100%',
            margin: '10px 0px'
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    let msgRol = t('SINGLE_ROL_SUBTITLE').replace('{{name}}', rolName);

    return (
        <>
            <div style={styles.container}>
                {loader ? (
                    <div style={{ "marginTop": "10vh" }}>
                        <Loader
                            type="tailspin"
                        />
                    </div>
                ) : (<>
                    <div style={styles.navbar}>
                        <div style={styles.textsNavbar}>
                            <h3 style={styles.titleNavbar}>{rolName.toUpperCase()}</h3>
                            <h3 style={styles.subtitleNavbar}>{msgRol}</h3>
                        </div>
                    </div>
                    <div style={styles.buttons}>
                        {edit ? (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_ROL_SAVE')}</button>
                        ) : (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_ROL_EDIT')}</button>)}
                        <button style={styles.buttonDelete} className="btn btn-primary" onClick={() => { handleDelete() }}>{t('BUTTON_SINGLE_ROL_DELETE')}</button>
                    </div>
                    <div style={styles.roles}>
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            style={styles.form}>
                            <div className="form-group" >
                                <label style={styles.label}>{t('SINGLE_ROL_NAME')}</label>
                                <input onChange={(e) => handleChange(e, 'name')} name="nameOfRol" type="text" className="form-control" value={rolName} style={styles.input} placeholder={t('PH_SINGLE_ROL_NAME')} required disabled={edit ? (true) : (true)} />

                                <label style={styles.label}>{t('SINGLE_ROL_WITH_ASSETS')}</label>
                                {totalAssets.length !== '' ? (
                                    <>
                                        {totalAssets.map((singleAsset, index) => {
                                            return (
                                                <div className="form-check" key={index} style={styles.checkbox}>
                                                    <input className="form-check-input" style={styles.inputCheckbox} value={singleAsset.assetType} onChange={(e) => handleChange(index, 'type')} type="checkbox" id={singleAsset.assetType} checked={assets.some(e => e.assetType === singleAsset.assetType)} disabled={edit ? (false) : (true)} />
                                                    <label className="form-check-label" style={styles.labelCheckbox} htmlFor={singleAsset.assetType}>{singleAsset.assetType}</label>
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <div className="form-check" style={styles.checkbox}>
                                        <label className="form-check-label" style={styles.labelCheckbox} for="flexCheckChecked1">{t('SINGLE_ROL_NO_ASSETS')}</label>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </>
                )}
            </div>
        </>
    );
};

export default RolSingle