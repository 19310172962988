import React from "react";
import { useTranslation } from 'react-i18next';

const ChatPeople = ({ screenSize, darkModeState, messages, message, setMessage, typeBot, loginUser, handleSend, active, messageEl }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            // padding: `${screenSize > 940 ? ("3% 2% 3% 280px") : ("30px 0px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            //  overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px 30px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        groupTitleNavbar: {
            display: "flex",
            flexDirection: "row",
            alignItems: 'center'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        botNavbar: {
            height: '40px',
            margin: '0px 15px',
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: '40px 0px'
        },
        chatContainer: {
            display: "flex",
            height: "75vh",
            borderRadius: "5px",
            // backgroundColor: 'grey',
            // boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            boxShadow: `${screenSize > 940 ? ("0 3px 16px 0 rgba(150,150,150,0.2)") : (<></>)}`,
            // border: '1px solid rgb(230,230,230)',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px 0px 40px")}`,
        },
        chatMessages: {
            flex: 12,
            display: "flex",
            borderRadius: "10px 10px 0px 0px",
            flexDirection: "column",
        },
        chatMessagesAll: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            borderRadius: "10 10px 0px 0px",
            paddingBottom: "10px",
            overflow: "auto",
        },
        chatReceived: {
            margin: "10px 0px 0px",
            padding: "15px 20px",
            maxWidth: "60%",
            display: "flex",
            alignSelf: "flex-start",
            backgroundColor: "#fff",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            borderRadius: "0 10px 10px 0",
            boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            fontFamily: 'InterRegular'
        },
        chatSent: {
            padding: "15px 20px",
            maxWidth: "60%",
            margin: "10px 0px 0px",
            display: "flex",
            alignSelf: "flex-end",
            backgroundColor: "#4dc2f1",
            color: "#fff",
            borderRadius: "10px 0 0 10px",
            boxShadow: "0 3px 16px 0 rgba(90,90,90,0.2)",
            fontFamily: 'InterRegular'
        },
        textBoxChat: {
            flex: 1,
            display: "flex",
            borderRadius: "0px 10px 10px 10px",
            flexDirection: "row",
            borderTop: "solid 0.2px rgb(240,240,240)",
            alignItems: "center",
        },
        inputChat: {
            display: "flex",
            flex: 10,
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            border: "0px",
            padding: '0px 20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: 'InterRegular',
            outline: 'none',
            outlineWidth: '0px'
        },
        button: {
            display: "flex",
            flex: 2,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: "#4dc2f1",
            height: "100%",
            border: "0px",
            color: '#fff',
            fontSize: "16px",
            outline: 0
        }
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    let res1 = loginUser.name ? t('SUPPORT_POC_1').replace('{{name}}', loginUser.name) : t('SUPPORT_POC_1').replace('{{name}}', '');
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if (`${month}`.length === 1) { month = "0" + month }
    let date = new Date().getDate()
    if (`${date}`.length === 1) { date = "0" + date }
    let dateTotal = (date + " / " + month + " / " + year)

    let typebot = '';
    if (typeBot === 'people-management') { typebot = t('TITLE_BOT_JML') }
    if (typeBot === 'knowledge') { typebot = t('TITLE_BOT_SEARCH') }

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <div style={styles.groupTitleNavbar}>
                            <h3 style={styles.titleNavbar}>{typebot}</h3>
                            <img
                                src="../assets/img/robot-1.png"
                                style={styles.botNavbar}
                                alt="Robot of Chatbot"
                            />
                        </div>
                        <h3 style={styles.subtitleNavbar}>{dateTotal}</h3>
                    </div>
                </div>
                <div style={styles.indicators}>
                    <div style={styles.chatContainer}>
                        <div style={styles.chatMessages}>
                            <div style={styles.chatMessagesAll} ref={messageEl}>
                                <div style={styles.chatReceived} >
                                    {res1}
                                </div>
                                {messages.length ? (
                                    messages.map((message, index) => {
                                        if (message.owner === "admin") {
                                            return (
                                                <>
                                                    {index === messages.length - 1 ? (
                                                        <div style={styles.chatReceived} id="element_within_div" key={index}>
                                                            {message.message}
                                                        </div>
                                                    ) : (
                                                        <div style={styles.chatReceived}>
                                                            {message.message}
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <div style={styles.chatSent} key={index}>
                                                    {message.message}
                                                </div>
                                            );
                                        }
                                    })
                                ) : (
                                    <></>
                                )}
                            </div>
                            <form style={styles.textBoxChat}>
                                <input
                                    name="textChat"
                                    type="text"
                                    className="form-control"
                                    placeholder={t('PH_WRITE_HERE')}
                                    style={styles.inputChat}
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                //onChange={handlerChange}
                                />
                                <button type="submit" style={styles.button} onClick={handleSend} disabled={active ? '' : 'disabled'}>{t('BUTTON_SEND')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatPeople