import React from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const ShippingSingle = ({ screenSize, darkModeState, handleSubmit, edit, handleChange, optionSelected, loader, nif, nro_documento, enviado, fecha_doc, fecha_envio, shippingId }) => { //nif_emisor,cuenta, nombre_sociedad, importe_ml,
    const styles = {
        buttons: {
            display: "flex",
            flexDirection: 'row',
            margin: 0
        },
        buttonEdit: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px",
            // margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 940 ? ('180px') : (<></>)}`,
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        checkbox2: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 25px 3px'
        },
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        form: {
            width: '100%',
            margin: '10px 0px'
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        shipments: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    // let msgRol = t('SINGLE_ROL_SUBTITLE').replace('{{name}}', rolName);
    // let id = `${nif_emisor}-${nif}-${nro_documento}`; 
    // usamos shippingId que lo pasamos por props al quitar de la interfaz el nif emisor, que no nos hace falta y establecemos el de Newba por defecto en elbacken
    // console.log(shippingId)
    let msgOrderId = t('SINGLE_SHIPPING_SUBTITLE').replace('{{id}}', shippingId);

    return (
        <>
            <div style={styles.container}>
                {loader ? (
                    <div style={{ "marginTop": "10vh" }}>
                        <Loader
                            type="tailspin"
                        />
                    </div>
                ) : (<>
                    <div style={styles.navbar}>
                        <div style={styles.textsNavbar}>
                            <h3 style={styles.titleNavbar}>{t('SINGLE_SHIPPING_TITLE')}</h3>
                            <h3 style={styles.subtitleNavbar}>{msgOrderId}</h3>
                        </div>
                    </div>

                    <div style={styles.shipments}>
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            style={styles.form}>
                            <div className="form-group" >
                                <br></br>

                                <label style={styles.label}>{t('SINGLE_ORDER_NIF')}</label>
                                <input onChange={(e) => handleChange(e)} name="nif" type="text" className="form-control" style={styles.input} value={nif} placeholder={t('PH_SINGLE_ORDER_NIF')} disabled={(true)} />

                                <label style={styles.label}>{t('SINGLE_ORDER_NRODOC')}</label>
                                <input onChange={(e) => handleChange(e)} name="nro_documento" type="text" className="form-control" style={styles.input} value={nro_documento} placeholder={t('PH_SINGLE_ORDER_NRODOC')} disabled={(true)} />

                                <label style={styles.label}>{t('SINGLE_ORDER_FECHADOC')}</label>
                                <input onChange={(e) => handleChange(e)} name="fecha_doc" type="date" className="form-control" style={styles.input} value={fecha_doc} placeholder={t('PH_SINGLE_ORDER_FECHADOC')} disabled={(true)} />

                                <label style={styles.label}>{t('SINGLE_ORDER_FECHAENVIO')}</label>
                                <input onChange={(e) => handleChange(e)} name="fecha_envio" type="date" className="form-control" style={styles.input} value={fecha_envio} placeholder={t('PH_SINGLE_ORDER_FECHAENVIO')} disabled={edit ? (false) : (true)} />

                                <label style={styles.label}>{t('SINGLE_ORDER_ENVIADO')}</label>
                                <div className="form-check" style={styles.checkbox}>
                                    <input className="form-check-input" style={styles.inputCheckbox} name="enviadoTrue" onChange={(e) => handleChange(e, 'type')} type="radio" value="option1" id="flexCheckChecked1" disabled={edit ? (false) : (true)} checked={optionSelected === "option1"} />
                                    <label className="form-check-label" style={styles.labelCheckbox} htmlFor="flexCheckChecked1">{t('YES')}</label>
                                </div>
                                <div className="form-check" style={styles.checkbox2}>
                                    <input className="form-check-input" style={styles.inputCheckbox} name="enviadoFalse" onChange={(e) => handleChange(e, 'type')} type="radio" value="option2" id="flexCheckChecked2" disabled={edit ? (false) : (true)} checked={optionSelected === "option2"} />
                                    <label className="form-check-label" style={styles.labelCheckbox} htmlFor="flexCheckChecked2">{t('NO')}</label>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div style={styles.buttons}>
                        {/*     //edit a true para que muestre siempre la ventana de edición, no queremos que cree o borre desde aqui, solo que actualice envios y el mismo nombre a los dos botones para que no haga cosas extrañas */}
                        {/* {edit ? (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_SHIPPING_SAVE')}</button>
                        ) : (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_SHIPPING_EDIT')}</button>)} */}
                        {edit ? (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_SHIPPING_SAVE')}</button>
                        ) : (<button type="submit" className="btn" style={styles.buttonEdit} onClick={() => { handleSubmit() }}>{t('BUTTON_SINGLE_SHIPPING_SAVE')}</button>)}
                    </div>
                </>
                )}
            </div>
        </>
    );
};

export default ShippingSingle