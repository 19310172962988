// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import getConfig from "../../utils/services.js"

// components:
import Home from "../../components/facturas/Home";

const HomeContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // const [config, setConfig] = useState({});
    const [phoneWA, setPhoneWA] = useState('');
    const [error, setError] = useState(false);//para controlar errores de servidor

    // useEffect al cargar el componente y dependencias que hacen q recargue:
    //TODO: deploy ¡¡¡¡¡¡ PONEMOS COMO PHONE EL SANDBOX DE MESSAGEBIRD CUIDADORRRRR!!!!!!!!!
    // al cargar la pagina recuperamos los datos de configuracion de la compañia, como es una promesa de un web service tenemos que hacerlo asi para que no nos de error. const phoneWA = +447418310508;
    useEffect(() => {
        async function fetchData() {
            try {
                const conf = await getConfig()
                // console.log(conf)
                if (conf.data?.status === 'KO') {
                    setError(true);
                } else {
                    //seteamos el estado con los hooks:
                    // setConfig(conf); // en este hook tenemos toda la config
                    setPhoneWA(conf.wa_phone_facturas) // en este hook almacenamos solo el telf
                    setError(false);
                }
            } catch (error) {
                console.log(error);
                setError(true);
            }
        };
        fetchData();
    }, []); //corchetes necesarios para que se ejecute una vez y evitar bucle infinito

    // manejadores de eventos 
    const handleBack = (e) => {
        // nav(-1)  // con esto volveriamos un atrás del navegador
        nav('/home')// de momento queremos que siempre vuelva a la Home, donde se muestran los modulos
    }

    if (error) {
        return (
            <>
                <div className="card offset-3 text-center mt-5 mb-2 w-50 border border-danger shadow-lg p-3 mb-5 bg-body rounded" >
                    <div className="card-header bg-danger text-white">
                        {/* Servicio Interrumpido */}
                        {t('INVOICES_HOME_VIEW_ERROR_TITLE')}
                    </div>
                    <div className="card-body bg-light text-dark">
                        <h5 className="card-title">{t('INVOICES_HOME_VIEW_ERROR_SUBTITLE')}</h5>
                        <p className="card-text">{t('INVOICES_HOME_VIEW_ERROR_P')}</p>
                    </div>
                </div>
                {/* <div className='text-center mt-5 mb-2'>Se ha producido un error interno al cargar los datos</div>
                <div className='text-center mb-5'>Intente de nuevo más tarde refrescando la página</div> */}
            </>
        )
    } else {
        return (
            <Home
                screenSize={screenSize}
                phoneWA={phoneWA}
                handleBack={handleBack}
            />
        );
    }
}

export default HomeContainer;