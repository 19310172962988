// hooks:
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import {NEWBA_TAG, FLUJOS, CHANNELS} from "../../utils/constants"
import { pathPart } from "../../utils/commons";
import axios from 'axios';
import Swal from 'sweetalert2'; //https://sweetalert2.github.io/#examples

// components:
import Listado from "../../components/facturas/Listado";

const ListadoContainer = () => {
    // variables y constantes:
    const url = process.env.REACT_APP_API_URL;
    const channel = CHANNELS.WEB;
    const flujo = FLUJOS.LISTADO;
    const companyPath = pathPart(window.location.host, 0)

    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const [nif, setNif] = useState('');
    const [sinceDate, setSinceDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [message, setMessage] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    // funciones adicionales:
    const validaNif = (data) => axios.post(
        `${url}/validateNif/${companyPath}/validaNif`,
        data,
        {
            headers: {
                'Authorization': NEWBA_TAG
            }
        }
    )

    const checkListado = (data) => axios.post(
        `${url}/formList/${companyPath}/listInvoices`,
        data,
        {
            headers: {
                'Authorization': NEWBA_TAG
            }
        }
    ).then((res) => res.data)

    // manejadores de eventos   
    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e) => {
        const toChange = e.target.name
        const toChangeValue = e.target.value
        if (toChange === "nif") {
            setNif(toChangeValue);
        }
        if (toChange === "sinceDate") {
            setSinceDate(toChangeValue);
        }
        if (toChange === "toDate") {
            setToDate(toChangeValue);
        }
    };

    // handle para volver a la home de la aplicación, no del módulo
    const handleBack = (e) => {
        // nav(-1)  // con esto volveriamos un atrás del navegador
        nav('/home')// de momento queremos que siempre vuelva a la Home, donde se muestran los modulos
    }

    // handle para manejar el submit del form
    const handleSend = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let validacion = await validaNif({ nif: nif, channel, flujo });
            if (validacion.data.code === '0') {
                // SE ENCUENTRA EL NIF CON EMAIL ASOCIADO EN LA BASE DE DATOS
                let check = await checkListado({
                    nif: nif,
                    initialDate: sinceDate,
                    endDate: toDate,
                    channel,
                    flujo,
                    detailClient: validacion.data.detailClient
                });
                //para los casos que haya algun error o excepcion en backend se devuelve un status=KO
                if (check.status === 'KO') throw new Error('error al obtener el listado de facturas');
                setLoading(false);
                setFinished(true);
                setNif('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '1') {
                // NO SE ENCUENTRA EL NIF EN LA BASE DE DATOS
                setLoading(false);
                setMessage('1');
                setNif('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '2') {
                // SE ENCUENTRA EL NIF EN LA BD PERO NO EXISTE MAIL ASOCIADO
                setLoading(false);
                setMessage('2');
                setNif('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '-1') {
                //el caso de error de formato NO ENVIA CORREO A DESBORDE. EL USUARIO DEBE REPETIR OPERACION
                setLoading(false);
                setMessage('3');
                setNif('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '-2') {
                //el caso de dejar el campo en blanco NO ENVIA CORREO A DESBORDE. EL USUARIO DEBE REPETIR OPERACION
                setLoading(false);
                setMessage('4');
                setNif('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.status === 'KO') {
                //para los casos que haya algun error o excepcion en backend se devuelve un status=KO
                throw new Error('error en la validación del nif')
                /* setLoading(false);
                setMessage('5');
                setNif('') */
            }
        } catch (error) {
            setLoading(false);
            setMessage('5');
            setNif('')
            setSinceDate('')
            setToDate('')
        }
    };

    // este handler es para mostrar el popup con la info al usuario al pulsar el boton de información establecido en components y le pasamos por props desde aqui la info a mostrar.
    const handleInfo = async (e) => {
        Swal.fire({
            width: '50em',
            height: '50em',
            title: t('INVOICES_HELP_TITLE'),
            /* text: 'You have to complete all the fields.', */
            html: `
            <li style="text-align:justify; padding:0.5em; list-style-type:none;">
            ${t('INVOICES_HELP_LISTADO_MSG_P1')}
            </li></br>
            <li style="text-align:justify; padding:0.5em; list-style-type:none;">
            ${t('INVOICES_HELP_LISTADO_MSG_P2')}
            </li>
            `,
            icon: 'info',
            confirmButtonColor: '#4DC2F1',
            confirmButtonText: t('INVOICES_HELP_BTN'),
            showCloseButton: 'true',
            focusConfirm: true,
        })
    };

    return (
        <Listado
            screenSize={screenSize}
            loading={loading}
            handleChange={handleChange}
            handleSend={handleSend}
            nif={nif}
            sinceDate={sinceDate}
            toDate={toDate}
            finished={finished}
            message={message}
            handleInfo={handleInfo}
            handleBack={handleBack}
        />
    );
}

export default ListadoContainer;