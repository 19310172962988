const LIMIT_TIME = 90 * 1000; // 90"
const RESET_TIME = 1000 * 60 * 60; // 60'
const DAY_MILLISECS = 24 * 60 * 60 * 1000; // 1 día (86400000);
const CODESIZE = 1; //8;
const NEWBA_TAG = 'iotagpepap%a(inponpan&35';
const CHARS = "qwertyuiopasdfghhjklñçzxcvbnm1234567890¡!$%&/POIUYTREWQASDFGHJKLÑÇMNBVCXZ";


const REGION = 'europe-west1'; // Londres   us-central1 por defecto
// Esta constante corresponde al proyecto por defecto, si en alguna version no existe el dato propio, recupera la información de el de default. Esto lo controlamos en el metodo getCompanyDoc, que es el primer paso de exec(), si el companyPath no se localiza, se asigna éste por defecto
const DEFAULT_PROJECT_COMPANYPATH = "newba";
const contactEmail = {
  newba: 'mailDEMO.bot@newba.cloud',
  insideone: 'mailDEMO.bot@insideone.cloud'
}

const defaultLang = 'es'

// urls para imagenes de modulos (almacenadas en /public/assets/img, comunes a todas las companys, se podrian tomar publicas mediante una url tb, pero si cae el servidor externo no se verían)
const srcPedidos = "./assets/img/pedidos.png"         //"https://i.postimg.cc/6qxKfZmk/pedidos.png"
const srcFacturas = "./assets/img/facturas.png"       //"https://i.postimg.cc/qR30Kwjc/facturas.png"
const srcPersonas = "./assets/img/personas.jpg"       //"https://i.postimg.cc/kMWqXjVq/personas.jpg"
const srcDocumentos = "./assets/img/admision-Doc.png" //"https://i.postimg.cc/v8915LKZ/admision-Doc.png"

// src="https://i.postimg.cc/ydB9XXzt/npombreArchivo.png" => si las publicamos en postimg en lugar de incluirlas en el proyecto. PERO SOLO LAS IMG QUE NOS SUMINISTRASEN LAS COMPAÑIAS DESDE SU SERVIDOR Y SE ENCARGASEN DE ACTUALIZARLAS ELLOS.
//Tambien sería aplicable a imagenes que cambiasen periodicamente para campañas o similar. Las fijas es mejor dentro porque si el servidor desde donde se toman se cae, estariamos sin imagenes


//TODO: GEMA hacer limpieza => ver que podemos usar y lo que no borrarlo de este fichero

const LOG_LEVEL = {
  LOG: "LOG",
  INFO: "INFO",
  WARM: "WARM",
  ERROR: "ERROR",
  APOCALYPSE: "APOCALYPSE"
}

const LOG_SERVICES = {
  TEST: "TEST",
  DESK: "SERVICE_DESK"
}

// category_id para el proceso global de alta/baja/modif de empleados. Se define en BD por lo que no lo usamos desde aqui
const DESKS = {
  // Se define en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > category_id
  JOINER: 26,
  LEAVER: 27,
  MOVER: 32,
}

// category_id para el proceso atomico de alta/baja de assets (BPM). Se define en BD por lo que no lo usamos desde aqui
const ATOMIC_PROCESS = {
  // Se define en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > category_id
  ALTA: 34,
  BAJA: 36,
}

// Se corresponde con mesa_resuelve de cada asset.  Los tickets de creación o liberación de activo pueden ser asignados a diferentes mesas. Esa asignación se hace dentro del proceso atómico correspondiente y la elección se hace al configurar cada uno de los activos. Por lo que no lo usamos desde aqui
const DESKS_ID_SD = {
  // Va indicado en cada asset en BD con el campo mesa_resuelve. Se introduce desde la web, el usuario indica la mesa que gestiona ese activo. 
  "Soporte IT Nivel 1": 4, // tickets de creación o liberación de activo 
  "Financiero Nivel 1": 21, // tickets de creación o liberación de activo 
  "Facilities Nivel 1": 23, // tickets de creación o liberación de activo 
  "Gestion personal (JML) Nivel 1": 15, // mesa de servicio de Recursos humanos para los tickets de Alta, Baja y Modificación
}

// TODO:TUTORIA RES: pues igual es mejor que devuelva el numero a la web y que sea ésta con i18n la que traduzca en funcion del idioma... si resulta que la traduccion en el backen no la utilizamos para nada sería mucho mejor.  
// priority_id de las solicitudes en ServiceDesk, se define por defecto que se creen los tickets con id = 2. Se define en BD por lo que no lo usamos desde aqui. // TODO Si cambiase de compañia a otra se tendria que meter en el asset concreto
const PRIORITY_TICKETS_SD = {
  // De momento todo es fixed
  // Se define en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > priority_tickets_sd
  en: {
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Urgent',
    5: 'Critical'
  },
  es: {
    1: 'Baja',
    2: 'Media',
    3: 'Alta',
    4: 'Urgente',
    5: 'Crítica'
  }
}

// type_id, tipo de la solicitud, tipo del ticket, se define en la documentacion por defecto se crean los tickets con id = 2, pero podría. Se define en BD por lo que no lo usamos desde aqui // TODO Si cambiase de compañia a otra se tendria que meter en el asset concreto
const TYPE_TICKETS_SD = {
  // De momento todo es fixed
  // Se define en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > type_tickets_sd
  en: {
    1: "Incident",
    2: "Service request",
    3: "Question",
    4: "Problem",
    5: "Change request",
    6: "Major incident"
  },
  es: {
    1: "Incidente",
    2: "Solicitud de servicio",
    3: "Pregunta",
    4: "Problema",
    5: "Solicitud de cambio",
    6: "Incidente Importante"
  }
}

// estado de la solicitud, estado del ticket en sistema Gestor de Tickets (ServiceDesk),
const STATUS_TICKETS_SD = {
  // Se define en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > status_tickets_sd
  es: {
    1: 'Nuevo',
    2: 'Abierto',
    3: 'Pendiente',
    4: 'Esperando',
    5: 'Resuelto',
    6: 'Cerrado'
  },
  en: {
    1: 'New',
    2: 'Open',
    3: 'Pending',
    4: 'Waiting',
    5: 'Solved',
    6: 'Closed'
  }
}

// ? mejor en cada compañia como el resto?? podemos crear nuevos estados, como PEndiente Entrega que lo creó Cesar A.... igual cada compañia puede definir cada estado¿?¿?¿? En FB está en coll constants aparte.... 
// estados genericos de los activos en el Sistema Gestor de Activos (p.e Insight) que están en collection de la BD /constants 
const ASSETS_STATUS = {
  ACTIVE: 2,
  DELETED: 3,
  IN_STOCK: 4,
  DECOMMISSIONED: 5,
  STOLEN: 6,
  IN_REPAIR: 7,
  ASSIGNED: 8,
  PENDIENTE_ENTREGA: 9,
}

const MANDATORY_KEYS = {
  movimientos: ['nif', 'nro_documento', 'clase', 'cuenta', 'nombre_sociedad', 'sociedad', 'fecha_doc', 'importe_ml', 'ml', 'vencimiento', 'nif_emisor'],
  //no mandatory movimientos: ['doc_compensacion', 'vp', 'albaran']
  // fecha_envio, deliverDate, enviado
  clientes: ['nif', 'cuenta', 'email', 'razon_social'],
  //no mandatory clientes: [] 
  // add_address
  // add_city
  // add_zipcode
  // add_state
  // add_country
}

const FLUJOS = {
  MOD_347: 'Modelo 347',
  LISTADO: 'Listado Facturas',
  DUPLICADO: 'Duplicado Factura',
  ALBARAN: 'Albarán',
  NUM_DOC: 'Número de documento',
  IMPORTE: 'Importe',
  FECHA: 'Fecha (rango)',
  UNKNOWN: 'Flujo desconocido',
  VALIDANIF: 'Validar NIF/EMAIL',
}

const CHANNELS = {
  WEB: 'Web',
  IVR: 'IVR',
  WHATSAPP: 'Whatsapp',
}

const ESTADISTICA = {
  DESBORDE: 'Desborde',
  GESTION: 'Gestión',
}

const COLLECTIONS_FB = {
  COMPANIES: 'companies',
  CLIENTES: 'clientes',
  MOVS: 'movimientos',
  MAILS: 'mails',
  LOG: 'log',
  LOG_BLACK: 'logBlack',
  ESTADISTICAS: 'statistics',
  CARGA_FICHEROS: 'uploads',
  USUARIOS: 'users',
  WHATSAPP: 'whatsapp',
  RESET_PASS: 'resetPassword',
  TICKETS: 'tickets',
  NIF: 'nif',
  COMPENSACION: 'doc_compensacion',
  FECHA_DOC: 'fecha_doc',
  FECHA_ENVIO: 'fecha_envio',
  ENVIADO: "enviado",
  CONFIGS: 'configs',
  METODOS: 'methods',
  CTS: "constants",
  TEMPLATES: "templates",
  PARAMS: "params",
  ASSETS: "assets",
  ROLES: "roles",
}

module.exports = Object.freeze({
  DEFAULT_PROJECT_COMPANYPATH,
  LOG_LEVEL,
  LOG_SERVICES,
  LIMIT_TIME,
  RESET_TIME,
  DAY_MILLISECS,
  CODESIZE,
  CHARS,
  NEWBA_TAG,
  DESKS, // definido en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > category_id
  ATOMIC_PROCESS, // definido en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > category_id
  DESKS_ID_SD, // definido en cada asset con el campo mesa_resuelve
  STATUS_TICKETS_SD, // definido tb en companies > configs > Gestor Tickets (p.e ServiceDesk) > constants > status_tickets_sd
  TYPE_TICKETS_SD, // definido tb en companies > configs > Sistema Gestor Tickets (p.e ServiceDesk) > constants > type_tickets_sd
  PRIORITY_TICKETS_SD, // definido tb en companies > configs > Gestor Tickets (p.e ServiceDesk) > constants > priority_tickets_sd
  ASSETS_STATUS, // definido en BD en constants > Sistema Gestor de Activos (p.e Insight) 
  FLUJOS,
  CHANNELS,
  ESTADISTICA,
  MANDATORY_KEYS,
  COLLECTIONS_FB,
  REGION,
  contactEmail,
  defaultLang,
  srcPedidos, srcFacturas, srcPersonas, srcDocumentos,
})