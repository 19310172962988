// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom"
import { useAuthUser } from "../../hooks/useAuthUser";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import Dashboard from "../../components/personas-jml/DashboardJML";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
import { userDB } from "../../context/firebaseConfig";

const DashboardContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    const [tickets, setTickets] = useState([]);
    const [loader, setLoader] = useState(true);
    const [comments, setComments] = useState('');
    const [idTicket, setIdTicket] = useState('');
    const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `./assets/img/${companyPath}/logo-clear.png`


    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        //console.log(user) usando el customhook del context
        userDB(user.email)
            .then(res => {
                return res
            }).then((u) => {
                setLoginUser(u)
                return dbData(user)
            }).then(response => {
                if (response.status === 'KO') throw new Error('Error intentando recuperar las incidencias/tickets');
                setTickets(response);
                setLoader(false)
            }).catch(e => {
                console.error(`Error recuperando los tickets: ${e}`);
                Swal.fire({
                    // title: 'Se ha producido un error',
                    title: t('SWAL_ERR_TITLE'),
                    // text: `No se pueden obtener los tickets de los envios pendientes de manera temporal.`, //[${e}]
                    text: `${t('SWAL_ERR_MSG_TICKETS')}`,
                    icon: 'error',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
            });
    }, [user, t])

    useEffect(() => {
        if (comments !== '') {
            Swal.fire({
                title: t('SWAL_TITLE_COMMENTS_CLOSE_TASKS'),
                showCancelButton: 'true',
                showCloseButton: 'true',
                confirmButtonText: t('SWAL_BTN_CLOSE_TASKS'),
                cancelButtonText: t('SWAL_BTN_STILL_OPEN_TASKS'),
                confirmButtonColor: '#4dc2f1',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true)
                    sendData(user, idTicket, loginUser.serviceDeskId, comments, result)
                        .then(response => {
                            if (response.status === 'KO') throw new Error('Error intentando cerrar el ticket');
                            Swal.fire({
                                title: t('SWAL_TITLE_COMMENTS_COMPLETED_TASKS'),
                                text: t('SWAL_TEXT_COMMENTS_COMPLETED_TASKS'),
                                icon: 'success',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                                showCloseButton: 'true',
                            })
                            setIdTicket('')
                            setComments('')
                            dbData(user).then(response => {
                                //revisar, porque se queda en el mensaje de añadir comentario bloqueado si provocamos un error en el backend al recuperar los tickets tras añadir comentario 
                                if (response.status === 'KO') throw new Error('error intentando recuperar los tickets (1)');
                                setTickets(response);
                                setLoader(false)
                            }).catch(e => {
                                setIdTicket('')
                                setComments('')
                                setLoader(false)
                                console.error(`error recuperando tickets (1): ${e}`);
                                throw e
                            });
                        }).catch(e => {
                            console.error(e);
                            Swal.fire({
                                title: t('SWAL_ERR_TITLE'),
                                text: `${t('SWAL_ERR_MSG_NOSERVICE')} [${e}]`,
                                icon: 'error',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                                showCloseButton: 'true',
                            })
                            setIdTicket('')
                            setComments('')
                            setLoader(false)
                        });
                } else {
                    setLoader(true)
                    sendData(user, idTicket, loginUser.serviceDeskId, comments, result)
                        .then(response => {
                            if (response.status === 'KO') throw new Error('Error intentando agregar comentario');
                            Swal.fire({
                                title: t('SWAL_TITLE_COMMENTS_STILL_OPEN_TASKS'),
                                text: '',
                                icon: 'info',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                                showCloseButton: 'true',
                            })
                            setIdTicket('')
                            setComments('')
                            dbData(user).then(response => {
                                //revisar, porque se queda en el mensaje de añadir comentario bloqueado si provocamos un error en el backend al recuperar los tickets tras añadir comentario 
                                if (response.status === 'KO') throw new Error('error intentando recuperar los tickets (2)');
                                setTickets(response);
                                setLoader(false)
                            }).catch(e => {
                                setIdTicket('')
                                setComments('')
                                setLoader(false)
                                console.error(`error recuperando tickets (2): ${e}`);
                                throw e
                            });
                        }).catch(e => {
                            console.error(e);
                            setIdTicket('')
                            setComments('')
                            setLoader(false)
                            Swal.fire({
                                title: t('SWAL_ERR_TITLE'),
                                text: `${t('SWAL_ERR_MSG_NOSERVICE')} [${e}]`,
                                icon: 'error',
                                confirmButtonColor: '#4dc2f1',
                                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                                showCloseButton: 'true',
                            })
                        });
                }
            })
        }
    }, [comments, idTicket, loginUser.serviceDeskId, t, user])


    // funciones adicionales:
    const dbData = (user) => {
        //obtenemos idioma para mostrar tipo, status y prioridad de ticket correctamente
        let lang = localStorage.getItem('i18nextLng') || 'es'
        return new Promise((resolve, reject) => {
            // const user = auth_service.getCurrentUser(); BORRAR
            const params = { email: user.email, lang: lang }
            // const params = { email: 'gjimenez@newba.cloud' } //si no aparecen tareas en algun usuario
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/tickets/${companyPath}/incidentsByAgent`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    /* Este array lo usábamos para renonmbrar las constantes según idioma y el id que tienen, al hacerlo desde el backend no es necesario
                    responseArray.forEach(singleTicket => {
                        //console.log(singleTicket);
                        singleTicket.type = getType(singleTicket.type_id, lang);
                        singleTicket.status = getStatus(singleTicket.status_id, lang);
                        singleTicket.priority = getPriority(singleTicket.priority_id, lang); 
                    });*/
                    resolve(responseArray);


                    //TODO:GEMA => no urge pero como en Tickets.jsx MEJOR VALIDAR QUE LO QUE NOS DEVUELVE DE LA API ES UN OK AL MENOS CON UN IF
                    // if (data.data.status === 'OK') {
                    //     let responseArray = data.data.detail
                    //     resolve(responseArray);
                    // }
                    // if (data.data.status === 'KO') throw new Error('error al intentar obtener los tickets de la BD')
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const sendData = (user, idTicket, idAgent, comment, type) => {
        let agentString = idAgent.toString()
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            let params = {}
            if (type.isConfirmed) {
                params = {
                    "request_id": idTicket, // el id o número de ticket/incident en SD. Require
                    "comment": comment,
                    "author_id": agentString, //el id del agente en SD    serviceDeskId
                    "is_solution": "1", // para que cambie el estado a solucionado. OPCIONAL
                }
            } else {
                params = {
                    "request_id": idTicket, // el id o número de ticket/incident en SD. Require
                    "comment": comment,
                    "author_id": agentString, //el id del agente en SD    serviceDeskId
                }
            }
            const headers = { headers: getAuthHeader(user) }
            //TODO: CORREGIR los tikets de alta joiner desaparecen del listado si los cerramos, pero no se cierran en SD, se quedan esperando al customer. Para los assets, si lo dejamos abierto no cambia el estado, y si confirmamos cerrar no hace nada absolutamente. tal vez tendriamos que usar el sistema + metodo que usamos en pedidos
            axios.post(`${process.env.REACT_APP_API_URL}/tickets/${companyPath}/commentToTicket`, params, headers)
                .then(data => {
                    resolve(true)
                    //TODO:GEMA => no urge pero como en Tickets.jsx MEJOR VALIDAR QUE LO QUE NOS DEVUELVE DE LA API ES UN OK AL MENOS CON UN IF
                    // if (data.data.status === 'OK') resolve(true)
                    // if (data.data.status === 'KO') throw new Error('error al intentar cerrar el ticket');
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    //manejador de cada ticket para poder acceder al ticket seleccionado
    const handleClick = (idSingleTicket) => {
        Swal.fire({
            title: t('SWAL_TITLE_COMMENTS_QUESTION_TASKS'),
            showCancelButton: 'true',
            showCloseButton: 'true',
            confirmButtonText: t('SWAL_BTN_SAVE_COMMENTS'),
            cancelButtonText: t('SWAL_BTN_CANCEL'),
            denyButtonText: t('SWAL_BTN_STILL_OPEN_TASKS'),
            input: 'text',
            confirmButtonColor: '#4dc2f1',
            //  confirmButtonColor: '#26B899',
            reverseButtons: true,
            inputAttributes: {
                autocapitalize: 'off'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setIdTicket(idSingleTicket)
                setComments(result.value)
            }
        })
    };

    return (
        <>
            <Sidebar
                // module='jml' tambien pasando el module con un nombre fijo nos ahorrariamos espeficiar las rutas, pero asi usamos el hook Location
                module={location.pathname}
                path= {path}
                screenSize={screenSize}
            />
            <Dashboard
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                tickets={tickets}
                loader={loader}
            />
        </>
    );
}

export default DashboardContainer;