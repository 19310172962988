// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";
import { defaultLang } from "../../utils/constants";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import RolesCreate from "../../components/personas-jml/RolesCreate";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
// import { userDB } from "../../context/firebaseConfig";

const RolesCreateContainer = () => {
    // hooks:
    const { t } = useTranslation();
    // const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || defaultLang);
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [assets, setAssets] = useState([]);
    const [loader, setLoader] = useState(true);
    const [name, setName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [assetsToSend, setAssetsToSend] = useState([]);
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            setAssets(response);
            setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando los tickets: ${e}`);
        });
    }, [user])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/getAssets`, params, headers)
                .then(data => {
                    // console.log(data)
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const sendData = (user, nameOfRol, assets) => {
        return new Promise((resolve, reject) => {
            let params = {
                "rolName": nameOfRol,
                "assets": assets,
                "lang": localStorage.getItem('i18nextLng') || defaultLang
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/rol/${companyPath}/setRol`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e, state) => {
        if (state === 'name') {
            const name = e.target.value;
            setName(name);
        } else if (state === 'type') {
            const isInArray = (element) => element.name === assets[e].assetType;
            let result = assetsToSend.findIndex(isInArray)
            if (result !== -1) {
                assetsToSend.splice(result, 1)
            } else {
                //   assets.push(assets[e])
                assetsToSend.push({
                    //"name":assets[e].assetType
                    name: assets[e].assetType
                })
            }
        }
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (name === '' || assetsToSend.length < 1) {
            Swal.fire({
                title: t('SWAL_TITLE_NO_DATA'),
                text: t('SWAL_TEXT_NO_DATA'),
                icon: 'warning',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        } else {
            setLoader(true)
            //  let assetsToSendFinal = []
            //   assetsToSend.map((singleAsset) => assetsToSendFinal.push(singleAsset.id))
            sendData(user, name, assetsToSend)
                .then(response => {
                    Swal.fire({
                        title: t('SWAL_TITLE_CREATED'),
                        text: t('SWAL_TEXT_CREATED_SINGLE_ROL'),
                        icon: 'success',
                        confirmButtonColor: '#4dc2f1',
                        confirmButtonText: t('SWAL_BTN_CONTINUE'),
                        showCloseButton: 'true',
                    })
                    nav(`/roles`);
                }).catch(e => {
                    console.error(`Error: ${e}`);
                });
        }
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                path={path}
                screenSize={screenSize}
            />
            <RolesCreate
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                handleChange={handleChange}
                name={name}
                assets={assets}
                loader={loader}
            />
        </>
    );
}

export default RolesCreateContainer;