import React from "react";
import { pathPart } from "../utils/commons";
import "../assets/css/_404.css";

const NotFound = () => {
    const path = `/assets/img/${pathPart(window.location.host, 0)}/logo404.png`

    return (
        <>
            <div className="_404body">
                {/* {<img src='assets/img/tgd/logo.png' className='_404logo' alt='kaps.es' />}  OJO NO OLVIDAR LA BARRA INICIAL PARA QUE TOME LA CARPETA DE PUBLIC PARA CUALQUIER PATH, SINO, EN PATHS COMPUESTOS NO SE MOSTRARÁ LA IMAGEN*/}
                <img src={path} style={{ width: '30%' }} className='_404logo' alt='imagen no disponible' />
                <h1 className='_404h1' data-text="404 Not f0und">
                    <span>404 Not f0und</span>
                </h1>
            </div>
        </>
    )
}

export default NotFound;