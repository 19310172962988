import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, } from "react-router-dom"; //useNavigate
import { useAuthUser } from "../../hooks/useAuthUser";

// import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import Chat from "../../components/personas-jml/Chat";
import ChatPeople from "../../components/personas-jml/ChatPeople";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
import { userDB } from "../../context/firebaseConfig";

const ChatContainer = () => {
    // hooks:
    // const nav = useNavigate();
    const { t } = useTranslation();
    const location = useLocation()
    const messageEl = useRef(null);
    const { idBot } = useParams();
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // eslint-disable-next-line no-unused-vars
    const [lang, setLang] = useState('es');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [active, setActive] = useState(true);
    const [idx, setIdx] = useState(0);
    const [sessionPath, setSessionPath] = useState('');
    const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        //console.log(user) usando el customhook del context
        userDB(user.email)
            .then(res => {
                return res
            }).then((u) => {
                setLoginUser(u)
                // setLoader(false)
            }).catch(e => {
                console.error(`Error en chats recuperando el usuario: ${e}`);
            });
    }, [user])

    let res2 = t('SUPPORT_POC_2').replace('{{name}}', loginUser.name)
    let res3 = t('SUPPORT_POC_3').replace('{{name}}', loginUser.name)
    let responsesPoc = [
        { message: res2, owner: 'admin' },
        { message: res3, owner: 'admin' }
    ]

    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [])

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        if (idBot === "people-management" || idBot === "knowledge") {
            if (message.length > 0) {
                setActive(false);
                var msgs = messages;
                msgs = [...msgs, { message: message, owner: 'user' }];
                setMessages(msgs);
                const tmp = message;
                setMessage('');
                const url = process.env.REACT_APP_API_URL;
                setMessages(msgs);
                const headers = { headers: getAuthHeader(user) }
                const response = await axios.post(url + `/chat/${companyPath}/manteniance`,
                    { message: tmp, sessionPath: sessionPath }, headers);

                let responseGoogle = response.data.detail.text;
                if (!sessionPath) setSessionPath(response.data.detail.sessionPath);
                //msgs[0] = [...msgs, { message: responseGoogle, owner: 'admin' }];
                msgs = [...msgs, { message: responseGoogle, owner: 'admin' }];
                setMessages(msgs);
                setActive(true)
            }
        } else {
            if (message.length > 0) {
                setActive(false);
                var msgss = messages;
                msgss = [...msgss, { message: message, owner: 'user' }];
                setMessages(msgss);
                setMessage('');
                msgss = [...msgss, { message: `${responsesPoc[idx].message}`, owner: 'admin' }];
                setMessages(msgss);
                setIdx(idx + 1);
                if (idx < 1) setActive(true);
                else {
                    const url = process.env.REACT_APP_API_URL;
                    const headers = { headers: getAuthHeader(user) }
                    // axios.post(url + `/mailing/iotracker/reset`,
                    axios.post(url + `/mailing/${companyPath}/reset`,
                        { lang: lang }, headers);
                } // LLAMAR a enviar mail de reset
                //    inputRef.current.focus();
            }
        }
    }

    return (
        <>
            <Sidebar
                module={location.pathname}
                path={path}
                screenSize={screenSize}
            />
            {idBot === "people-management" || idBot === "knowledge" ? (
                <ChatPeople
                    screenSize={screenSize}
                    darkModeState={darkmode}
                    handleDarkMode={handleDarkMode}
                    messages={messages}
                    message={message}
                    setMessage={setMessage}
                    typeBot={idBot}
                    loginUser={loginUser}
                    handleSend={handleSend}
                    active={active}
                    messageEl={messageEl}
                />
            ) : (
                <Chat
                    screenSize={screenSize}
                    darkModeState={darkmode}
                    handleDarkMode={handleDarkMode}
                    messages={messages}
                    message={message}
                    setMessage={setMessage}
                    typeBot={idBot}
                    loginUser={loginUser}
                    handleSend={handleSend}
                    active={active}
                    messageEl={messageEl}
                />
            )}

        </>
    );
}

export default ChatContainer;