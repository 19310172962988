// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import AssetsCreate from "../../components/personas-jml/AssetsCreate";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext' 
// import { userDB } from "../../context/firebaseConfig";

const CreateAssetsContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [name, setName] = useState('');
    const [isFisico, setIsFisico] = useState(true);
    const [mesa_resuelve, setMesa_resuelve] = useState('');
    const [metodoGeneracion, setMetodoGeneracion] = useState(10);
    const [urlGeneracion, setUrlGeneracion] = useState('');
    const [urlAditionalParams, setUrlAditionalParams] = useState('');
    const [optionSelected, setOptionSelected] = useState('option1');
    const [totalDesks, setTotalDesks] = useState([]);
    const [types, setTypes] = useState([]);
    const [loader, setLoader] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [methods, setMethods] = useState([{ name: t('SINGLE_ASSET_METHOD_10'), id: 10 }, { name: t('SINGLE_ASSET_METHOD_1'), id: 1 }, { name: t('SINGLE_ASSET_METHOD_2'), id: 2 }, { name: t('SINGLE_ASSET_METHOD_3'), id: 3 }]);
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`


    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            setTotalDesks(response)
            typesData(user).then(responseTypes => {
                setTypes(responseTypes)
                // console.log('types:', responseTypes)
                setLoader(false)
            })
        }).catch(e => {
            console.error(`Error recuperando los tickets: ${e}`);
        });
    }, [user])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/tickets/${companyPath}/getDesks`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e);
                })
        })
    }

    const typesData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/getTypes`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e);
                })
        })
    }

    const sendData = (user, nameOfAsset, type, mesa_resuelve, metodoGeneracion, urlGeneracion, urlAditionalParams) => {
        return new Promise((resolve, reject) => {
            let parseUrlAditionalParams = [];
            let tmp = [];
            let parsed = {};
            if (urlAditionalParams === ' ' || urlAditionalParams === '' || urlAditionalParams === null || urlAditionalParams === undefined) {
                parsed = {};
            } else {
                //parseamos urlAditionalParams para que se guarde en FB como un objeto con pares clave-valor
                parseUrlAditionalParams = urlAditionalParams.replaceAll(':', ' : ').replaceAll(',', ' , ').replaceAll(';', ' , ').replaceAll('=', ' : ').split(' ');
                tmp = parseUrlAditionalParams.filter(n => n !== '');
                parseUrlAditionalParams = tmp.join(" ");
                parseUrlAditionalParams = `{"${parseUrlAditionalParams.trim().replaceAll(' ', '"')}"}`; //formateamos a JSON
                parsed = JSON.parse(parseUrlAditionalParams) //convertimos el JSON a Objeto JS,
            }

            let params = {
                "name": nameOfAsset,
                "assetType": nameOfAsset,
                "isFisico": type === true ? "true" : "false",
                "mesa_resuelve": Number(mesa_resuelve),
                "metodoGeneracion": Number(metodoGeneracion),
                "urlGeneracion": urlGeneracion,
                "urlAditionalParams": parsed
                // "priority_id": nameOfAsset,
                // "type_id": nameOfAsset,
                // "category_id": nameOfAsset,
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/setAsset`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e) => {
        const type = e.target.name;
        const value = e.target.value;
        if (type === 'nameOfRol') { setName(value) }
        if (type === 'mesa_resuelve') { setMesa_resuelve(value) }
        if (type === 'metodoGeneracion') { setMetodoGeneracion(value) }
        if (type === 'urlGeneracion') { setUrlGeneracion(value) }
        if (type === 'urlAditionalParams') { setUrlAditionalParams(value) }
        if (type === 'Hardware') { setIsFisico(true); setOptionSelected(value) }
        if (type === 'Software') { setIsFisico(false); setOptionSelected(value) }
        if (type === 'selectOptions') { setMesa_resuelve(value) }
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name === '' || mesa_resuelve === '') {
            Swal.fire({
                title: t('SWAL_TITLE_NO_DATA'),
                text: t('SWAL_TEXT_NO_DATA'),
                icon: 'warning',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
        } else {
            setLoader(true)
            sendData(user, name, isFisico, mesa_resuelve, metodoGeneracion, urlGeneracion, urlAditionalParams)
                .then(response => {
                    Swal.fire({
                        title: t('SWAL_TITLE_CREATED'),
                        text: t('SWAL_TEXT_CREATED_SINGLE_ASSET'),
                        icon: 'success',
                        confirmButtonColor: '#4dc2f1',
                        confirmButtonText: t('SWAL_BTN_CONTINUE'),
                        showCloseButton: 'true',
                    })
                    nav(`/asset`);
                }).catch(e => {
                    console.error(`Error: ${e}`);
                });
        }
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                path= {path}
                screenSize={screenSize}
            />
            <AssetsCreate
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                loader={loader}
                name={name}
                type={isFisico}
                handleChange={handleChange}
                optionSelected={optionSelected}
                mesa_resuelve={mesa_resuelve}
                metodoGeneracion={metodoGeneracion}
                urlGeneracion={urlGeneracion}
                urlAditionalParams={urlAditionalParams}
                totalDesks={totalDesks}
                types={types}
                methodsTypes={methods}
            />
        </>
    );
}

export default CreateAssetsContainer;