import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const Politicas = ({ screenSize, darkModeState, handleDarkMode, calidad, seguridad, loader }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        documents: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `5px 0px`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        listOptions: {
            margin: 0,
            fontSize: "18px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
        },
        namePdf: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        singleURL: {
            display: "flex",
            //   width: `${screenSize > 940 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "5px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            overflow: "hidden", //con este overflow corregimos que se salia el nombre de los archivos del boton
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        textsOptions: {
            display: "flex",
            padding: "1.1em",
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('DOC_LIST_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('DOC_LIST_SUBTITLE')}</h3>
                    </div>
                </div>

                <div style={styles.documents}>
                    {loader ? (
                        <Loader
                            type="tailspin"
                        />
                    ) : (
                        <>
                            <div style={styles.textsOptions}>
                                <h3 style={styles.listOptions}>{t('DOC_CALIDAD')}</h3>
                            </div>

                            {calidad ? (
                                calidad.map((single, index) => {
                                    //console.log(single)
                                    let substr = single.split('/')
                                    //console.log(substr) 
                                    let pdf = substr[substr.length - 1].trim()
                                    //console.log(pdf)
                                    return (
                                        <Fragment key={index}>
                                            <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleURL} key={`div_${index}`}>
                                                <div style={styles.lineDetailCenter} key={`detail_${index}`} onClick={async () =>
                                                    (window.open(`${single}`)) //abre en nueva ventana la url indicada
                                                }>
                                                    <div style={styles.namePdf} key={`pdf_${index}`}>{pdf}</div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })) : (null)}

                            <div style={styles.textsOptions}>
                                <h3 style={styles.listOptions}>{t('DOC_SEGURIDAD')}</h3>
                            </div>
                            {seguridad ? (
                                seguridad.map((single, index) => {
                                    let substr = single.split('/')
                                    let pdf = substr[substr.length - 1].trim()
                                    return (
                                        <Fragment key={index}>
                                            <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleURL} key={`div_${index}`}>
                                                <div style={styles.lineDetailCenter} key={`detail_${index}`} onClick={async () =>
                                                    (window.open(`${single}`)) //abre en nueva ventana la url indicada
                                                }>
                                                    <div style={styles.namePdf} key={`pdf_${index}`}>{pdf}</div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })) : (null)}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Politicas