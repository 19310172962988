// hooks:
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'


//TODO: ERR.TUTORIA => TOMADO DE LA ANTIGUA WEB DE LA POC, PQ EN LA NUEVA NO ESTABA HECHO. INTENTO CORREGIR CUANDO PODAMOS DAR DE ALTA USUARIOS COMPROBAR CON NUEVO USUARIO QUE TODO EL PROCESO DE JML FUNCIONA OK, INCLUIDO ESTO. 


const Reset = () => {
    const { user, companyPath } = useParams();
    const { t } = useTranslation();
    const [newpassword, setNewPassword] = useState("");
    const [reppassword, setRepPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeNewPassword = (e) => {
        const newpassword = e.target.value;
        setNewPassword(newpassword);
    };

    const onChangeRepPassword = (e) => {
        const reppassword = e.target.value;
        setRepPassword(reppassword);
    };

    const handleReset = async (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);

        if (!reppassword || !newpassword || reppassword.length <= 0 || newpassword.length <= 0) {
            setMessage(t('ERROR_REQUIRED_FIELD_MISING'))
        } else if (reppassword !== newpassword) {
            setMessage(t('ERROR_MISMACHT_PASSWORDS'))
        } else if (newpassword.length < 6 || newpassword.length > 50) {
            setMessage(t('ERROR_WRONG_PASSWORD_FORMAT'))
        } else {
            const url = process.env.REACT_APP_API_URL;
            var headers = { headers: getAuthHeader(user) }
            headers.headers.ioreset = `ioreset ${user}`
            const ret = await axios.post(url + `/action/${companyPath}/reset`, { password: newpassword }, headers);
            if (ret.data === 'RESET_SUCCESSFUL') alert(t('RESET_SUCCESSFUL'))
            else setMessage(t(ret.data))
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <form onSubmit={handleReset} >
                    <div className="form-group">
                        <label htmlFor="newpassword">
                            {t('NEW_PASSWORD')}
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            name="newpassword"
                            value={newpassword}
                            onChange={onChangeNewPassword}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="reppassword">
                            {t('REPEAT_PASSWORD')}
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            name="reppassword"
                            value={reppassword}
                            onChange={onChangeRepPassword}

                        />
                    </div>

                    <div className="form-group pt-3 text-center">
                        <button className="btn btn-lg btn-primary btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>{t('BUTTON_RESET')}</span>
                        </button>
                    </div>
                    <br />
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {t(message)}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Reset;