import React from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const Tickets = ({ screenSize, darkModeState, handleClick, tickets, loader }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerNoTickets: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '80%'
        },
        descriptionDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        idDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        noTickets: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '30px',
            fontSize: '20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`
        },
        priorityDetailCard: {
            display: "flex",
            // color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            margin: 0,
            fontSize: "10px",
            fontFamily: "InterRegular",
            padding: '3px 10px',
            backgroundColor: '#4dc2f1',
            borderRadius: '20px',
            width: '60px',
            alignItems: 'center',
            justifyContent: 'center'
        },
        singleTicket: {
            display: "flex",
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4% 0px -4px') : ("10px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        statusDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        tickets: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        typeDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('TITLE_TICKETS')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('SUBTITLE_TICKETS')}</h3>
                    </div>
                </div>
                <div style={styles.tickets}>
                    {loader ? (
                        <Loader
                            type="tailspin"
                        />
                    ) : (<>
                        {tickets && tickets.length > 0 ? (
                            tickets.map((ticket) => {
                                //Esta linea 225 la incluimos para controlar que en el array de los tickets nos venaga algun null, en ese caso recargamos la pagina para que no se quede en blanco.
                                //Tenemos control sobre lo que mostramos aqui, al crear nuevos registros en BBDD tickets/cid/tickets para actualizar bd si crean tickets directamente desde SD, que deben actualizarse a la bd y que no den error
                                if (ticket === null) window.location.reload()

                                return (
                                    <div key={ticket.id} className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleTicket} onClick={(e) => handleClick(ticket.id)} >
                                        <div style={styles.lineDetail}>
                                            <div style={styles.idDetailCard}> {t('LABEL_TICKET_NUMBER')} #{ticket.id}</div>
                                            <div style={styles.idDetailCard}>{t('LABEL_TYPE_TICKET')} {t(`TYPE_TICKETS_ID_${ticket.type_id}`)} </div>
                                            <div style={styles.priorityDetailCard}>{t(`PRIORITY_TICKETS_ID_${ticket.priority_id}`)}</div>
                                        </div>
                                        <div style={styles.lineDetailCenter}>
                                            <div style={styles.descriptionDetailCard}>{ticket.description}</div>
                                        </div>
                                        <div style={styles.lineDetail}>
                                            {/* <div style={styles.typeDetailCard}>{ticket.type}</div> */}
                                            <div style={styles.typeDetailCard}>{t('LABEL_ORDER_NUMBER')} {ticket.movimiento.id}</div>
                                            {/* <div style={styles.statusDetailCard}>{ticket.statusT}</div> */}
                                            <div style={styles.statusDetailCard}>{t(`STATUS_TICKETS_ID_${ticket.status_id}`)}</div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div style={styles.containerNoTickets}>
                                <img
                                    src="./assets/img/eric-feliz.png"
                                    height="150"
                                    alt="Eric feliz imagen"
                                />
                                <div style={styles.noTickets}>{t('MSG_NO_TICKETS')}</div>
                            </div>
                            // <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleAsset}>
                            //     <div style={styles.nameAsset}>No tickets assigned to you.</div>
                            // </div>
                        )}
                    </>)}
                </div>
            </div>
        </>
    );
};

export default Tickets