// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import { pathPart } from "../../utils/commons"
import Swal from 'sweetalert2'
import axios from "axios";

// components:
import ShippingSingle from "../../components/pedidos/ShippingSingle";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext' 
// import { userDB } from "../../context/firebaseConfig";

const ShippingSingleContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    //edit a true para que muestre siempre la ventana de edición, no queremos que cree o borre desde aqui, solo que actualice envios
    const [edit, setEdit] = useState(true);
    const [loader, setLoader] = useState(true);
    const { shippingId } = useParams();
    //nif, nif_emisor, nro_documento, cuenta, nombre_sociedad, importe_ml, fecha_doc, fecha_envio y enviado
    const [nif, setNif] = useState('');
    const [doc, setDoc] = useState('');
    const [sociedad, setSociedad] = useState('');
    const [fecha, setFecha] = useState('');
    const [importe, setImporte] = useState('');
    const [fechaEnvio, setFechaEnvio] = useState('');
    const [optionSelected, setOptionSelected] = useState('option1');
    const [enviado, setEnviado] = useState(false);
    // const [cuenta, setCuenta] = useState(''); //no necesario
    // const [emisor, setEmisor] = useState(''); //no necesario
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png` 

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            // console.log(response)
            if (response.status === 'KO') throw new Error('Error intentando recuperar el detalle del movimiento');
            setLoader(false)
            setNif(response.nif)
            setDoc(response.nro_documento)
            setSociedad(response.nombre_sociedad)
            setFecha(response.fecha_doc)
            setImporte(response.importe_ml)
            setFechaEnvio(response.fecha_envio)
            // setCuenta(response.cuenta)
            // setEmisor(response.nif_emisor)
            if (response.enviado === true) {
                setOptionSelected('option1')
            } else {
                setOptionSelected('option2')
            }
            /* 
            // si fuese un array la respuesta:
            setRol(response[idRol])
            setRolName(response[idRol].rolName)
            setAssets(response[idRol].assets)
            dbDataAssets().then(responseAssets => {
                setTotalAssets(responseAssets)
            }) */
        }).catch(e => {
            console.error(`Error recuperando el detalle del movimiento: ${e}`);
            setLoader(false)
            Swal.fire({
                title: t('SWAL_ERR_TITLE'),
                text: `${t('SWAL_ERR_MSG_SHIPPING_SINGLE_GET')}`, //[${e}]
                icon: 'error',
                confirmButtonColor: '#4dc2f1',
                confirmButtonText: t('SWAL_BTN_CONTINUE'),
                showCloseButton: 'true',
            })
            nav(`/shipping`); //redirigimos al listado tras info al usuario
        });
    }, [nav, t, user])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { movId: shippingId }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/movimiento/${companyPath}/getMov`, params, headers)
                .then(data => {
                    if (data.data.status === 'OK') {
                        let response = data.data.detail
                        resolve(response);
                    }
                    if (data.data.status === 'KO') throw new Error('error al intentar obtener el detalle del movimiento de la BD');
                }).catch(e => {
                    reject(e)
                })
        }).catch(e => {
            if (e.status) { return e; }
            else return { status: 'KO', detail: e.message || e }
        })
    }

    const sendData = (user, nif, nro_documento, nombre_sociedad, importe_ml, fecha_doc, fecha_envio, enviado) => { //nif_emisor,cuenta
        return new Promise((resolve, reject) => {
            let params = {
                //nif, nif_emisor, nro_documento, cuenta, nombre_sociedad, importe_ml, fecha_doc,
                "nif": nif,
                "nro_documento": nro_documento,
                "nombre_sociedad": nombre_sociedad,
                "importe_ml": importe_ml,
                "fecha_doc": fecha_doc,
                "fecha_envio": fecha_envio,
                "enviado": enviado,
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/movimiento/${companyPath}/setMov`, { movimiento: params }, headers)
                .then(data => {
                    // resolve(true) MEJOR VALIDAR QUE LO QUE NOS DEVUELVE DE LA API ES UN OK AL MENOS
                    if (data.data.status === 'OK') resolve(true)
                    if (data.data.status === 'KO') throw new Error('error al intentar actualizar el detalle del movimiento');
                }).catch(e => {
                    reject(e)
                })
        }).catch(e => {
            if (e.status) { return e; }
            else return { status: 'KO', detail: e.message || e }
        })
    }

    /* const deleteData = (user, movId) => {
        return new Promise((resolve, reject) => {
            let params = {
                "movId": movId
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/movimiento/${companyPath}/delMov`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    } */

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        //radiobutton
        if (name === 'enviadoTrue') { setEnviado(true); setOptionSelected(value) }
        if (name === 'enviadoFalse') { setEnviado(false); setOptionSelected(value) }

        //if (e.target.name === 'nif') {setNif(e.target.value);}
        if (name === 'nif') { setNif(value); }
        if (name === 'nro_documento') { setDoc(value); }
        if (name === 'nombre_sociedad') { setSociedad(value); }
        if (name === 'importe_ml') { setImporte(value); }
        if (name === 'fecha_doc') { setFecha(value); }
        if (name === 'fecha_envio') { setFechaEnvio(value); }
        // if (name === 'cuenta') { setCuenta(value); }
        // if (name === 'nif_emisor') { setEmisor(value); }
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (edit) {
            if (nif === '' || doc === '' || sociedad === '' || fecha === '' || importe === '' || fechaEnvio === '' || enviado === '') { //|| emisor === ''  || cuenta === ''
                Swal.fire({
                    title: t('SWAL_TITLE_NO_DATA'),
                    text: t('SWAL_TEXT_NO_DATA'),
                    icon: 'warning',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
            } else {
                setEdit(!edit);
                sendData(user, nif, doc, sociedad, importe, fecha, fechaEnvio, enviado) //emisor, cuenta
                    .then(response => {
                        // console.log(response)
                        if (response.status === 'KO') throw new Error('error intentando actualizar el movimiento');
                        Swal.fire({
                            title: t('SWAL_TITLE_SAVED'),
                            text: t('SWAL_TEXT_SAVED_SINGLE_SHIPPING'),
                            icon: 'success',
                            confirmButtonColor: '#4dc2f1',
                            confirmButtonText: t('SWAL_BTN_CONTINUE'),
                            showCloseButton: 'true',
                        })
                        nav(`/shipping`);
                    }).catch(e => {
                        console.error(`Error: ${e}`);
                        Swal.fire({
                            title: t('SWAL_ERR_TITLE'),
                            text: `${t('SWAL_ERR_MSG_SHIPPING_SINGLE_UPDATE')}`,
                            icon: 'error',
                            confirmButtonColor: '#4dc2f1',
                            confirmButtonText: t('SWAL_BTN_CONTINUE'),
                            showCloseButton: 'true',
                        })
                        nav(`/shipping`);
                    });
            }
        } else {
            setEdit(!edit);
        }
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                path={path}
                screenSize={screenSize}
            />
            <ShippingSingle
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                edit={edit}
                loader={loader}
                handleChange={handleChange}
                nif={nif}
                nro_documento={doc}
                nombre_sociedad={sociedad}
                fecha_doc={fecha}
                importe_ml={importe}
                fecha_envio={fechaEnvio}
                enviado={enviado}
                optionSelected={optionSelected}
                shippingId={shippingId}
            // cuenta={cuenta}
            // nif_emisor={emisor}
            />
        </>
    );
}

export default ShippingSingleContainer;