import { initializeApp, getApps } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { getFirestore, collection, orderBy, limit, startAfter, query, getDocs, doc, getDoc, where, setDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { pathPart } from "../utils/commons";
import { DEFAULT_PROJECT_COMPANYPATH, COLLECTIONS_FB } from '../utils/constants'
const C_FB = COLLECTIONS_FB; //para acortar nombres

//Esta config WEB, no hay problema de que se publique a bitbuclet pq es visible desde F12 y no pasa nada porque es necesario hacer login con user+pass si las reglas en firebase estan puestas como usuario authenticate
const app = initializeApp({
    apiKey: "AIzaSyAzNzCYOCAmqnBU_uTe0Auhc8TDeLHv67s",
    authDomain: "fir-tracker-94835.firebaseapp.com",
    projectId: "fir-tracker-94835",
    storageBucket: "fir-tracker-94835.appspot.com",
    messagingSenderId: "928783195883",
    appId: "1:928783195883:web:8e7ab702d0bc1d0c6828ae",
    measurementId: "G-NXBRRGDR4Y"
});

/* const IOapp = initializeApp({
    apiKey: "AIzaSyCcVV2MKBxctNJOmLzF_vRVNVBPLVQ_3G8",
    authDomain: "io-tracker-6fbc6.firebaseapp.com",
    projectId: "io-tracker-6fbc6",
    storageBucket: "io-tracker-6fbc6.appspot.com",
    messagingSenderId: "322184499664",
    appId: "1:322184499664:web:91517c1da2f00615f5a9d5",
    measurementId: "G-P8ZBY0BK2L"
}); */

/**
 * Desautenticar la aplicacion
 */
const logOut = async () => {
    const au = getAuth(app);
    await signOut(au);
}

/**
 * Obtiene token de usuario autenticado que es lo que devuelve
 * @returns token
 */
const getBearer = () => {
    return getAuth(app).currentUser.getIdToken(true);
}

const getCompanyDoc = async () => {
    try {
        const db = getFirestore(app);
        //si estamos en localhost toma el valor pordefecto
        // let hostname = 'panel.newba.cloud'
        // let hostname = 'educacion.petete.es'
        let companyPath = pathPart(window.location.host, 0) || DEFAULT_PROJECT_COMPANYPATH;

        const companiesRef = collection(db, C_FB.COMPANIES)
        const q = query(companiesRef, where('companyPath', "==", companyPath));
        const querySnapshot = await getDocs(q);
        let companyDoc = null;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            companyDoc = (doc.id, " => ", doc.data());
        });
        // console.log(companyDoc)

        //validamos que encontremos la compañia en la BD o establecemos por defecto
        if (companyDoc != null) {
            // si no es nulo seguimos con el siguiente then
            return companyDoc;
        } else {
            //si es nulo, nueva busqueda de compañia con companyPath por defecto
            const companiesRef = collection(db, C_FB.COMPANIES)
            const q = query(companiesRef, where('companyPath', "==", DEFAULT_PROJECT_COMPANYPATH));
            const querySnapshot = await getDocs(q);
            let doc = {};
            /* //otra forma de obtener la info 
               querySnapshot.docs.forEach(item => {
               doc = { ...item.data(), id: item.id };
             }); */
            querySnapshot.forEach((item) => {
                doc = (item.id, " => ", item.data());
            });
            // console.log(doc)
            return doc;
        }
    } catch (e) {
        console.log(`ERROR en getCompanyDoc ${JSON.stringify(e)}`);
        return (e)
    }
}

const getUserDB = async (cid, mail) => {
    try {
        // console.log( cid, mail)
        const db = getFirestore(app);
        const docRef = doc(db, C_FB.USUARIOS, cid, C_FB.USUARIOS, mail);
        const docSnap = await getDoc(docRef);
        // if (docSnap.exists()) {
        //     console.log("Document data:", docSnap.data());
        // } else {
        //     // doc.data() will be undefined in this case
        //     console.log("No such document!");
        // }
        return docSnap.data();
    } catch (e) {
        console.log(`ERROR en getUserDB ${JSON.stringify(e)}`);
        return (e)
    }
}

const userDB = (userMail) => {
    let cc = {}
    return getCompanyDoc()
        .then(c => {
            cc = c
            // throw new Error('petete')
            return getUserDB(cc.companyID, userMail)
        }).then((user) => {
            let usuario = user;
            usuario = { ...usuario, companyName: cc.companyName }
            // console.log('usuario DB', usuario)
            return usuario
        }).catch(e => {
            console.log(`ERROR en userDB ${JSON.stringify(e)}`);
            if (e.status) { return e; }
            return { status: 'KO', detail: e.message || e }
        })
};

/**
 * Permite realizar las distintas consultas a Firestore para obtener los datos deseados según los parámetros recibidos.
 * Por ejemplo, los parámetros pueden ser: collection, limit, orderBy, where, operador... 
 * Por ejemplo el tipo de consulta puede ser: collection, query...
 * @param {*} params = parametros necesarios para realizar las distintas consultas a Firestore según el tipo de consulta a realizar 
 * @returns Array con el resultado, ya sea un elemento (document) o varios
 */
const getData = async (params) => {
    try {
        const { coll, lim = null, start = null, ord = null, wee = null } = params;
        let q = query(getRef(coll));
        if (ord) q = query(q, orderBy(ord));
        if (start) q = query(q, startAfter(start));
        if (lim) q = query(q, limit(lim));

        for (var i = 0; q && i <= wee?.length - 3; i = i + 3) { // i + 3
            const field = wee[i];
            const operator = wee[i + 1];
            const value = wee[i + 2];

            if (field && operator && value) {
                q = query(q, where(field, operator, value));
            }
        }

        let ret = [];
        // queremos que sea == y no ===. Está hecho a propósito
        // eslint-disable-next-line eqeqeq
        if (q.type == 'query') {
            const snapshot = await getDocs(q);
            snapshot.forEach(snap => {
                ret.push(snap.data());
            })
            // eslint-disable-next-line eqeqeq
        } else if (q.type == 'collection') {
            const snap = await getDocs(q);
            snap.forEach(snap => {
                ret.push(snap.data());
            })
        } else {
            const snap = await getDoc(q);
            ret.push(snap.data());
        }
        return ret;

    } catch (error) {
        console.log('Error en getDATA de firebaseConfig', error)
    }
}

/**
 * Permite guardar en Firestore según los parámetros recibidos.
 * Por ejemplo, los parámetros suelen ser: collection/path, objeto y si se trata de una actualización o una creación 
 * @param {*} params = parametros que son necesarios para crear o actualizar un documento en Firestore
 * @returns true si se realiza correctamente y null si no se realiza la creacion/actualizacion
 */
const saveData = async (params) => {
    let ret = null;
    try {
        const { coll, data, update = true } = params;
        const ref = getRef(coll, true);
        if (ref) {
            if (update) { await updateDoc(ref, data); }
            else { await setDoc(ref, data); }
            ret = true;
        }
    } catch (error) {
        console.log(error);
    }
    return ret;
}

/**
 * Permite borrar en Firestore según los parámetros recibidos.
 * Por ejemplo, los parámetros pueden ser: collection/path,
 * @param {*} params = parametros que son necesarios para borrar un documento en Firestore
 * @returns true si se realiza correctamente y null si no se realiza el borrado
 */
const deleteData = async (params) => {
    let ret = null;
    try {
        const { coll } = params;
        const ref = getRef(coll, true);
        if (ref) {
            await deleteDoc(ref);
            ret = true;
        }
    } catch (error) {
        console.log(error);
    }
    return ret;
}

/**
 * Obtiene la referencia a un documento o collection de Firestore
 * @param {*} path  = el path al documento en cuestion
 * @param {*} toDoc = para saber si es ruta a documento o a collection
 * @returns la referncia obtenida
 */
const getRef = (path, toDoc = false) => {
    try {
        const a = (typeof path == 'string') ? path.split('/') : path;
        // eslint-disable-next-line eqeqeq
        if (toDoc && a.length % 2 != 0) {
            console.log(`Es una ruta hasta documento y no es par`);
            return null;
        }
        let ref = getFirestore(app);
        for (var i in a) {
            // eslint-disable-next-line eqeqeq
            if (i % 2 == 0) { ref = collection(ref, a[i]); }
            else { ref = doc(ref, a[i]); }
        }
        return ref;
    } catch (error) {
        console.log(error);
        return null
    }
}

/**
 * Carga un fichero en Storage, a una ruta especificada, borrando antes los elementos que contenga el directorio en cuestión para no crear conflicto si se trata de distintas extensiones de fichero. Despues se realiza la carga del fichero deseado.
 * @param {String} path  = la ruta a la que subir el fichero (hasta la carpeta contenedora)
 * @param {*} f     = el fichero a subir
 * @param {String} name  = nombre con el que se subirá el fichero
 * @returns null, porque la respuesta no se maneja
 */
const uploadFile = async (path, f, name = 'logo') => {
    // subimos fichero a Storage. como se permiten varias extensiones de logo, tenemos que vaciar el directorio de Storage primero
    // asi nos aseguramos que no se quedan varios logos de distinta extension en Storage, pq crearía conflicto en la web de qué mostrar. 
    let storageRef = null;
    try {
        const metadata = {
            contentType: f.type,
            size: f.size
        };
        const ext = f.name.match(/[^.]+$/)[0];
        const storage = getStorage();

        // primero vaciamos el directorio path de los posibles logos que haya
        let directoryRef = ref(storage, path);
        const fileList = await listAll(directoryRef);
        await Promise.all(fileList.items.map((fileRef) => deleteObject(fileRef)));

        // despues actualizamos el directorio con el logo correcto
        storageRef = ref(storage, `${path}/${name}.${ext}`, metadata);
        // eslint-disable-next-line no-unused-vars
        const res = await uploadBytes(storageRef, f);
    } catch (e) {
        console.log(e)
    }

    try {
        const pepe = await getDownloadURL(storageRef)
        return pepe;
    } catch (e) { }

    return null;
}

/**
 * Borra un directorio y todos sus elementos del Storage. Se localiza el directorio o carpeta contenedora según el path indicado y se elimina dicho directorio y sus elementos.
 * @param {*} path = directorio a borrar
 * @returns null, porque la respuesta no se maneja
 */
const deleteFolder = async (path) => {
    // borramos todos los logos que haya en el directorio que pasamos como path, hasta id_concesionario
    // es decir, aqui borramos el directorio completo, el id_concesionario + todo lo que contiene
    try {
        const storage = getStorage();
        const listRef = ref(storage, path);
        // Find all the prefixes and items.
        listAll(listRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                    // console.log('prefixex que imagino es para subdirectorio')
                });
                res.items.forEach(async (itemRef) => {
                    await deleteObject(itemRef);
                    // All the items under listRef.
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.log('Uh-oh, an error occurred: ', error)
            });
    } catch (e) {
        console.log(e)
    }
    return null;
}

/**
 * Obtiene la url de un fichero del Storage, en este caso se trata de imagenes. 
 * @param {*} path = path o directorio del recurso que buscamos
 * @param {*} img  = nombre del recurso que buscamos
 * @returns la url si localiza el recurso, y null si no lo hace
 */
const getImageUrl = async (path, img = 'logo') => {
    try {
        const exts = ['jpg', 'png', 'jpeg'];
        let pepe = null;
        const storage = getStorage();
        for (var i = 0; !pepe && i < exts.length; i++) {
            const storageRef = ref(storage, `${path}/${img}.${exts[i]}`);
            try {
                pepe = await getDownloadURL(storageRef)
                // eslint-disable-next-line eqeqeq
                if (path == '85') console.log(pepe)
            } catch (e) { }
        }
        return pepe;
    } catch (e) { }
    return null;
}

export {
    app,
    initializeApp,
    getApps,
    signInWithEmailAndPassword,
    getFirestore,
    getAuth,
    logOut,
    getBearer,
    getCompanyDoc,
    getUserDB,
    userDB,
    getData,
    saveData,
    deleteData,
    uploadFile,
    getImageUrl,
    deleteFolder,
}