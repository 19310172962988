import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pathPart } from "../../utils/commons";

// components:
import SelectorLanguages from "../SelectorLanguages";

const Home = ({ screenSize, phoneWA, handleBack }) => {
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    const companyPath = pathPart(window.location.host, 0)

    const styles = {
        columnInputs: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            margin: 0,
            padding: 0,
        },
        columnImage: {
            display: "flex",
            height: "100vh",
            width: "60%",
            // margin: 0,
            padding: 0, 
            backgroundImage: `url(./assets/img/contact-center.jpg)`,
            // SI FUERA POR COMPAÑIA SERIA ASI:  backgroundImage: `url(./assets/img/${companyPath}/contact-center.jpg)`, 
            // backgroundImage: `url(${"https://i.postimg.cc/526TkQ8M/contact-center.jpg"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        container: {
            overflow: "auto",
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            backgroundColor: '#fff'
        },
        icons: {
            marginRight: "15px",
            width: '18px',
            height: '20px',
        },
        imageLogo: {
            height: "9%",
            width: "auto",
            position: "relative",
            // left: "10%",
            // top: "85%",
            left: "6%",
            top: "88%",
        },
        form: {
            width: "100%",
            padding: 40,
        },
        formFooter: {
            // fontSize: 16,
            fontSize: `${screenSize > 450 ? ("16px") : ("13px")}`,
            margin: '40px 0px 0px',
            textAlign: 'center',
            width: '80%',
            lineHeight: 1.5
        },
        formGroup: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 0,
            padding: `${screenSize > 800 ? ('2%') : ('5%')}`,
        },
        formTitle: {
            fontSize: `${screenSize > 800 ? ("28px") : ("30px")}`,
            // marginBottom: 0,
            marginBottom: 10,
            marginTop: -20
        },
        linkButton: {
            paddingRight: 20,
            paddingLeft: 30,
            // marginTop: "2.5%",
            display: "flex",
            alignItems: "center",
            backgroundColor: '#fff',
            color: '#63687e',
            border: 'none',
            outline: 0,
            width: '100%',
            fontSize: '100%'
        },
        linkHome: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            margin: "30px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: '90%',
            // padding: '8px 32px',
            padding: '13px 32px',
            //height: 50,
            textAlign: 'center'
        },
        linkHomeWTP: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#fff',
            borderRadius: 25,
            border: 'solid 1px #23B23B',
            padding: '4px 32px',
            margin: "30px 0px 0px",
            color: '#23B23B',
            fontWeight: 500,
            outline: 0,
            width: '90%',
            // height: `${screenSize > 800 ? ("50px") : ("70px")}`,
        },
        logoHeader: {
            // height: 130,
            // margin: '-9% 0 10% 0'
            height: `${screenSize > 450 ? ("130px") : ("100px")}`,
            margin: `${screenSize > 450 ? ("-9% 0 10% 0") : ("-20% 0 10% 0")}`
        },
        selector: {
            width: `${screenSize > 800 ? ("50vw") : ("100vw")}`,
            display: "flex",
            justifyContent: 'right',
            alignItems: "center",
            padding: '1%',
            // marginTop: "-8%"
        },
        row: {
            marginRight: 0,
            marginLeft: 0,
            padding: "0.2%"
        }
    }


    return (
        <div style={styles.container}>
            {screenSize > 940 ? (
                <div style={styles.columnImage} >
                    <img
                        // src="https://www.unaURL.es/wp-content/uploads/2021/07/cropped-logo-io-transparent.png"
                        // src="../assets/img/logo-transparent.png"
                        src={`../assets/img/${companyPath}/logo-transparent.png`}
                        alt={`Logo of of company ${companyPath}`}
                        style={styles.imageLogo}
                    />
                </div>
            ) : (<></>)}
            <div>
                <div className="row" style={styles.row}>
                    <button className="col btn btn-link" style={styles.linkButton} onClick={handleBack}>
                        <svg style={styles.icons} fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                        </svg>
                        {t('SIDEBAR_HOME')}
                    </button>
                    <div className="col" style={styles.selector}>
                        {/* SELECTOR LENGUAJES */}
                        <SelectorLanguages direction='dropleft' screenSize={screenSize} />
                    </div>
                </div>

                <div style={styles.columnInputs} >
                    <div style={styles.form}>
                        <div className="form-group" style={styles.formGroup}>
                            {/* <img src="https://i.postimg.cc/R0RvH3TJ/isologo.png" alt="logo de una compañia" style={{ height: 45, margin: '0px 0px 50px' }} /> */}
                            <img
                                src={`../assets/img/${companyPath}/logo-high.png`}
                                alt={`Logo of of company ${companyPath}`}
                                style={styles.logoHeader}
                            />

                            <h1 style={styles.formTitle}>{t('INVOICES_HOME_TITLE')}</h1>
                            <Link style={styles.linkHome} to="/invoices/solicitud-347">{t('INVOICES_HOME_LINK1')}</Link>
                            <Link style={styles.linkHome} to="/invoices/listado-facturas">{t('INVOICES_HOME_LINK2')}</Link>
                            <Link style={styles.linkHome} to="/invoices/duplicado-factura">{t('INVOICES_HOME_LINK3')}</Link>

                            {/* con un ancho de pantalla menor a 450 mostraremos btnWa sin tanto texto */}
                            {screenSize > 450 ? (
                                <button style={styles.linkHomeWTP}
                                    // disabled
                                    onClick={async () =>
                                        //https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=es
                                        // (window.location.href = "https://wa.me/34646834071")//redirige a la url indicada
                                        (window.open(`https://wa.me/${phoneWA}`)) //abre en nueva ventana la url indicada
                                    }
                                >
                                    <img
                                        src="/assets/img/WhatsApp_Logo_1.png"
                                        alt="logo whatsapp"
                                        style={{ width: '2.5em', margin: '0 1em 0 0.5em' }}
                                    />
                                    {t('INVOICES_HOME_WA_BTN_LARGE')}
                                </button>
                            ) : (
                                <button style={styles.linkHomeWTP}
                                    onClick={async () =>
                                        (window.open(`https://wa.me/${phoneWA}`)) //abre en nueva ventana la url indicada
                                    }
                                >
                                    <img
                                        src="/assets/img/WhatsApp_Logo_1.png"
                                        alt="logo whatsapp"
                                        style={{ width: '2.5em', margin: '0 1em 0 0' }}
                                    />
                                    {t('INVOICES_HOME_WA_BTN_SHORT')}
                                </button>
                            )}
                            <h1 style={styles.formFooter}>{t('INVOICES_HOME_FOOTER')}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home


/* ¡¡¡¡¡¡Si queremos cambiar los textos de los otros botones este es el codigo completo del return!!!!!!!! 
return (
    <div style={styles.container}>
        {/* con un ancho de pantalla menor a 800px no mostraremos la img de la izqda //}
        {screenSize > 800 ? (
            <div style={styles.columnImage} >
                <img
                    src="https://i.postimg.cc/hG8scn3m/casen-recordati.png"
                    alt="Logo of Casen Recordati"
                    style={styles.imageLogo}
                />
            </div>
        ) : (<></>)}

        <div style={styles.columnInputs} >
            {/* con un ancho de pantalla menor a 400px reduciremos los textos que se muestran //}
            {screenSize > 399 ? (
                <div style={styles.form}>
                    <div className="form-group" style={styles.formGroup}>
                        <img src="https://i.postimg.cc/brcGDWXy/log-casen-recordati.gif" alt="logo Casen Recordati" style={{ height: 45, margin: '0px 0px 50px' }} />
                        <h1 style={styles.formTitle}>Autogestión</h1>
                        <Link style={styles.linkHome} to="/solicitud-347">Consulta 347</Link>
                        <Link style={styles.linkHome} to="/listado-facturas">Listado de Factura</Link>
                        <Link style={styles.linkHome} to="/duplicado-factura">Duplicado de Factura</Link>
                        <button style={styles.linkHomeWTP}
                            onClick={async () =>
                                // (window.location.href = "https://api.whatsapp.com/send/?phone=34646834071") //deprecated
                                //https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=es
                                // (window.location.href = "https://wa.me/34646834071")
                                // (window.location.href = `https://wa.me/${phoneWA}`) //redirige a la url indicada
                                (window.open(`https://wa.me/${phoneWA}`)) //abre en nueva ventana la url indicada
                            }>
                            <img src="/WhatsApp_Logo_1.png" alt="logo whatsapp" style={{ width: '1.5em', margin: '0 1em 0 0.5em' }} />
                            Autogestión desde WhatsApp
                        </button>
                        <h1 style={styles.formFooter}>Las respuestas a este formulario no son inmediatas. La información puede tardar en llegar a su correo electrónico.</h1>
                    </div>
                </div>
            ) : (
                <div style={styles.form}>
                    <div className="form-group" style={styles.formGroup}>
                        <img src="https://i.postimg.cc/brcGDWXy/log-casen-recordati.gif" alt="logo Casen Recordati" style={{ height: 45, margin: '0px 0px 50px' }} />
                        <h1 style={styles.formTitle}>Autogestión</h1>
                        <Link style={styles.linkHome} to="/solicitud-347">347</Link>
                        <Link style={styles.linkHome} to="/listado-facturas">Listado</Link>
                        <Link style={styles.linkHome} to="/duplicado-factura">Duplicado</Link>
                        <button style={styles.linkHomeWTP}
                            onClick={async () =>
                                (window.open(`https://wa.me/${phoneWA}`)) //abre en nueva ventana la url indicada
                            }>
                            <img src="/WhatsApp_Logo_1.png" alt="logo whatsapp" style={{ width: '1.5em', margin: '0 1em 0 0.5em' }} />
                        </button>
                        <h1 style={styles.formFooter}>Las respuestas a este formulario no son inmediatas. La información puede tardar en llegar a su correo electrónico.</h1>
                    </div>
                </div>
            )}
        </div>
    </div>
) */