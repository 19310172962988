import { useContext } from "react";
import AuthContext from '../context/AuthContext'  //OJO QUE AQUI NOS IMPORTAMOS EL CONTEXTO COMPLETO, EL ARCHIVO DEL CONTEXTO

// Custom hook que recupera el contexto auth para devolver unicamente el usuario de Firebase autenticado, y asi solo hacemos una sola importacion del Context en toda la aplicacion. En cada llamada que necesitemos recuperar este contexto, podemos llamar directamente a este hook que nos devuelva al usuario
export const useAuthUser = () => {  
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error(
            "useAuthUser must be used within a FirebaseAuthProvider"
        );
    }
    // console.log(context.user)
    return context.user;
}