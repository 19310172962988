import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// components:
import Loader from '../Loader'

const Orders = ({ screenSize, darkModeState, handleClick, handleDarkMode, movimientos, loader }) => {
    const styles = {
        buttonNew: {
            display: "flex",
            alignSelf: "flex-start",
            placeContent: "center",
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            margin: "20px 0",
            // margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
            width: `${screenSize > 940 ? ('24vh') : (<></>)}`,
        },
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            // padding: `${screenSize > 940 ? ("3% 2% 3% 280px") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerNoOrders: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '80%'
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        nameOrder: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        noOrders: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '30px',
            fontSize: '20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`
        },
        orders: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
        },
        singleOrder: {
            display: "flex",
            //   width: `${screenSize > 940 ? ("95%") : ("80vw")}`,
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('ORDER_LIST_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('ORDER_LIST_SUBTITLE')}</h3>
                    </div>
                </div>

                <div style={styles.orders}>
                    <Link style={styles.buttonNew} className="btn btn-primary" to="/orders/create">{t('ORDER_CREATE')}</Link>

                    {loader ? (
                        <div style={{ "marginTop": "-8vh" }}>
                            <Loader
                                type="tailspin"
                            />
                        </div>
                    ) : (
                        <>
                            {movimientos ? (
                                movimientos.map((singleMov, index) => {
                                    return (
                                        <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleOrder} onClick={() => handleClick(`${singleMov.id}`)} key={index}>
                                            <div style={styles.lineDetailCenter}>
                                                <div style={styles.nameOrder}>{singleMov.id}</div>
                                            </div>
                                            {/* <div style={styles.lineDetail}>
                                            <div style={styles.quantityAssets}>{singleMov.assetsList.length} {t('ASSETS_LABEL')}</div>
                                            <div style={styles.quantityPeople}>5 {t('PEOPLE_LABEL')}</div>
                                        </div> */}
                                        </div>
                                    )
                                })) : (
                                <div style={styles.containerNoOrders}>
                                    <img
                                        src="./assets/img/eric-feliz.png"
                                        height="150"
                                        alt="Eric feliz imagen"
                                    />
                                    <div style={styles.noOrders}>{t('MSG_NO_ORDERS')}</div>
                                </div>
                                // <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleAsset}>
                                //     <div style={styles.nameAsset}>No tickets assigned to you.</div>
                                // </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Orders