import React from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const Tasks = ({ screenSize, darkModeState, handleClick, tickets, loader }) => {
    //TODO:GEMA => revisar qué estilos se usan y cuales no. Ordenar alfabeticamente
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        containerNoTasks: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '80%'
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        tasks: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
        },
        singleTask: {
            display: "flex",
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4% 0px -4px') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        idDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
        },
        priorityDetailCard: {
            display: "flex",
            // color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            margin: 0,
            fontSize: "10px",
            fontFamily: "InterRegular",
            padding: '3px 10px',
            backgroundColor: '#4dc2f1',
            borderRadius: '20px',
            width: '60px',
            alignItems: 'center',
            justifyContent: 'center'
        },
        descriptionDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        typeDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        statusDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        noTasks: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '30px',
            fontSize: '20px',
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`
        },
        singleAsset: {
            display: "flex",
            justifyContent: 'center',
            width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
            borderRadius: '5px',
            padding: "25px",
        },
        nameAsset: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('TITLE_TASKS')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('SUBTITLE_TASKS')}</h3>
                    </div>
                </div>
                <div style={styles.tasks}>
                    {loader ? (
                        <Loader
                            type="tailspin"
                        />
                    ) : (<>
                        {tickets && tickets.length > 0 ? (
                            tickets.map((ticket) => {
                                return (
                                    <div key={ticket.id} className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleTask} onClick={(e) => handleClick(ticket.id)} >
                                        <div style={styles.lineDetail}>
                                            <div style={styles.idDetailCard}>#{ticket.id}</div>
                                            <div style={styles.priorityDetailCard}>{ticket.priority}</div>
                                        </div>
                                        <div style={styles.lineDetailCenter}>
                                            <div style={styles.descriptionDetailCard}>{ticket.description}</div>
                                        </div>
                                        <div style={styles.lineDetail}>
                                            <div style={styles.typeDetailCard}>{ticket.type}</div>
                                            <div style={styles.statusDetailCard}>{ticket.status}</div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div style={styles.containerNoTasks}>
                                <img 
                                    // src="https://i.postimg.cc/XYJ7ZYR2/eric-feliz.png"
                                    src="./assets/img/eric-feliz.png"
                                    height="150"
                                    alt="Eric feliz imagen no tasks"
                                />
                                <div style={styles.noTasks}>{t('MSG_NO_TASKS')}</div>
                            </div>
                            // <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleAsset}>
                            //     <div style={styles.nameAsset}>No tasks assigned to you.</div>
                            // </div>
                        )}
                    </>)}
                </div>
            </div>
        </>
    );
};

export default Tasks