// hooks:
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuthUser } from "../hooks/useAuthUser";

import Swal from 'sweetalert2'

// components:
import User from "../components/User";

//views
import Sidebar from "./Sidebar";
// import FatalError from './500Unexpected';

// contexts: 
import { userDB } from "../context/firebaseConfig";

//utils:
import { pathPart } from "../utils/commons";

const UserContainer = () => {
    // hooks:
    const location = useLocation()
    const { t } = useTranslation();
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // eslint-disable-next-line no-unused-vars
    const [loader, setLoader] = useState(true);
    // const [error500, setError500] = useState('')
    const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const [entries, setEntries] = useState([]) //aqui almacenamos la info de la BD para ese usuario en forma de array
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const path = `./assets/img/${pathPart(window.location.host, 0)}/logo-clear.png`

    // useEffect al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        //console.log(user) usando el customhook del context
        userDB(user.email)
            .then(res => {
                // console.log(res)
                if (res.status === 'KO') throw new Error('Error intentando recuperar la informacion de usuario');
                return res
            }).then((u) => {
                let lu = u;
                //parseamos la fecha de joiner si la hay
                let tm = u.joindate ? u.joindate.seconds * 1000 : null;
                let date = tm ? new Date(tm) : null;
                let cid = u.companyName ? u.companyName : t('UNDEFINED')

                lu = {
                    ...u,
                    joindate: date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : t('UNDEFINED'),
                    companyName: cid,
                }

                setLoginUser(lu)
                setEntries(Object.entries(lu))
                setLoader(false)
            }).catch(e => {
                console.error(`Error recuperando el usuario: ${e}`);
                Swal.fire({
                    // title: 'Se ha producido un error',
                    title: t('SWAL_ERR_TITLE'),
                    text: `${t('SWAL_ERR_NO_USER')}`,
                    icon: 'error',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
                setLoader(false)
                // setError500(t(e.code || e))
            })
    }, [user, t])

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // if (error500)
    // return <FatalError />

    return (
        <div>
            <Sidebar
                module={location.pathname}
                path={path}
                screenSize={screenSize}
            />
            <User
                screenSize={screenSize}
                darkModeState={darkmode}
                handleDarkMode={handleDarkMode}
                loader={loader}
                loginUser={loginUser}
                entries={entries}
            />
        </div>
    );
}

export default UserContainer;