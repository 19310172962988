import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import i18resources from './i18resources'
const availableLanguages = i18resources.langShort; //['en', 'es'];

i18n
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .use(LanguageDetector) // detect user language
    .use(HttpApi) // load translations using http (default public/assets/locals/en/translations)
    .init({
        supportedLngs: availableLanguages,
        fallbackLng: 'es', // fallback language is spanish.
        detection: {
        checkWhitelist: true, // options for language detection
        },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json?v=0.1'
            // loadPath: '/assets/newba/{{lng}}/translation.json?v=0.1'
        },
        react: { useSuspense: false}
    });

export default i18n;