import axios from "axios";
import { pathPart } from "./commons";
import {NEWBA_TAG} from "./constants"


// Con este servicio obtenemos la info de la compañia, pero solo a nivel de la tabla, las subcollections no están contempladas de momento en el web service...porque de momento no necesitamos obtener nada de alli, // TODO si se necesitase habría que desarrollarlo en el servicio para que lo devuelva todo y tomarlo. 
export default async function getConfig(data) {
    return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_API_URL;
        return axios.post(
            `${url}/companies/${pathPart(window.location.host, 0)}/getConfig`,
            data,
            {
                //aqui los headers son para pasar el control de la api al tratarse de una ruta publica ya que no se necesita usuario autenticado, si no, habria que obtener el token igual que en pedidos
                headers: {
                    'Authorization': NEWBA_TAG
                }
            }
        ).then(validacion => {
            // console.log(validacion);
            if (validacion.status === 200 && !validacion.data.status) return resolve(validacion.data)
            else if (validacion.data.status === 'KO') throw new Error('imposible obtener configuración')
            else throw new Error('error desconocido')
        }).catch(e => {
            // console.error(`(c1) reject getConfig en services.js: ${e}`);
            return reject(e);
        })
    }).catch(e => {
        // console.error(`(c2) ERROR getConfig en services.js: ${e}`);
        throw e;
    })
}