import React from "react";

const SidebarLogo = ({ path }) => {
    const styles = {
      logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 10px 5px 5px",
        // padding: "20px 20px 10px"
      },
    };
  
    return (
      <div style={styles.logoContainer}>
        <img
          // src="https://i.postimg.cc/bN6SJ6fx/logo.png" 
          // src="./assets/img/logo-clear.png"
          src={path}
  
          height={window.innerWidth > 940  ? window.innerWidth < 1340 ? "60" : "90" :''}
          // height="45"
          alt="logo on sidebar"
        />
      </div>
    );
};

export default SidebarLogo