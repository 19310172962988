import React from "react";
import { useTranslation } from 'react-i18next';
import { capitalizeFirst, pathPart } from "../utils/commons";

//components
import SelectorLanguages from "./SelectorLanguages";

const Login = ({ screenSize, handleLogin, username, password, message, loading, onChangeUsername, onChangePassword }) => {
    const companyPath = pathPart(window.location.host, 0)
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    const styles = {
        buttonFloat: {
            position: "absolute",
            bottom: "4%",
            right: -100,
            display: "flex",
            justifyContent: "flex-end",
            height: '250px'
        },
        columnImage: {
            display: "flex",
            height: "100vh",
            width: "50vw",
            margin: 0,
            padding: 0,
            // backgroundImage: `url(${"https://i.postimg.cc/pr1b0Vf9/login-image.jpg"})`,            
            backgroundImage: `url("./assets/img/${companyPath}/login-image.jpg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        columnInputs: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            margin: 0,
            padding: 0,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            backgroundColor: '#fff'
        },
        imageLogo: {
            height: "12%",
            width: "auto",
            position: "relative",
            left: "8%",
            top: "83%",
        },
        form: {
            width: "100%",
            padding: "8%",
            marginRight: "2.5%",
            marginTop: "3%"
        },
        formButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '8px 32px',
            margin: "35px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: 130,
            height: 40
        },
        formContainer: {
            width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            marginTop: "1%",
            padding: 1,
        },
        formInput: {
            marginBottom: 20,
            fontFamily: "InterRegular",
            width: "85%",
        },
        formLabel: {
            marginBottom: 10,
            fontFamily: "InterRegular",
        },
        formTitle: {
            fontSize: `${screenSize > 940 ? ("40px") : ("30px")}`,
            marginBottom: "8%",
            marginTop: "3%"
        },
        selector: {
            width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            display: "flex",
            justifyContent: 'right',
            padding: '1%',
            marginRight: "3%",
            marginBottom: "0.5%"
        },
        textError: {
            margin: 0,
            fontSize: "16px",
            color: 'hsl(356, 69%, 56%)',
            alignSelf: 'center'
        },
    }

    
    return (
        <div style={styles.container}>
            {/* IMAGEN IZQUIERDA depende de resolucion de pantalla */}
            {screenSize > 940 ? (
                <div style={styles.columnImage} >
                    <img
                        // src="https://i.postimg.cc/ydB9XXzt/npombreArchivo.png" => si las publicamos en postimg en lugar de incluirlas en el proyecto. PERO SOLO LAS IMG QUE NOS SUMINISTRASEN LAS COMPAÑIAS DESDE SU SERVIDOR Y SE ENCARGASEN DE ACTUALIZARLAS ELLOS.
                        //Tambien sería aplicable a imagenes que cambiasen periodicamente para campañas o similar. Las fijas es mejor dentro porque si el servidor desde donde se toman se cae, estariamos sin imagenes
                        src={`./assets/img/${companyPath}/logo-white.png`}
                        // src="./assets/img/newba/logo-white.png" 
                        // src="./assets/img/logo-white.png" //esta es ya una de newba pero habria que aumentar el heigt de imageLogo
                        alt={`Logo of company ${companyPath}`}
                        style={styles.imageLogo}
                    />
                </div>
            ) : (<></>)}

            {/* CONTENEDOR DERECHA se muestra siempre */}
            <div style={styles.formContainer}>
                <div style={styles.selector}>
                    {/* SELECTOR LENGUAJES */}
                    <SelectorLanguages direction='dropleft' screenSize={screenSize} />
                </div>

                <div>
                    {/* FORMULARIO */}
                    <div style={styles.columnInputs} >
                        <form onSubmit={handleLogin} style={styles.form}>
                            <div className="form-group">
                                <h1 style={styles.formTitle}>{t('LOGIN_TITLE')} {`${capitalizeFirst(companyPath)}`}</h1>
                                <label style={styles.formLabel} htmlFor="username">{t('LOGIN_LABEL_MAIL')}</label>
                                <input
                                    name="username"
                                    type="email"
                                    className="form-control"
                                    id="username"
                                    value={username}
                                    onChange={onChangeUsername}
                                    style={styles.formInput}
                                //  validations={[required]}
                                />
                                {/* <small id="emailHelp" className="form-text text-muted"> No compartiremos su información. </small> */}
                                <label style={styles.formLabel} htmlFor="inputPassword">{t('LOGIN_LABEL_PASS')}</label>
                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    id="inputPassword"
                                    value={password}
                                    onChange={onChangePassword}
                                    style={styles.formInput}
                                />

                                {/* <Link style={{ marginLeft: "20px", }} to="/recover" >  Recuperar contraseña  </Link> */}

                                {/* mensaje de error si los campos no están cumplimentados */}
                                {/* {message ? <h3 style={styles.textError}>{message}</h3> : <></>} */}
                                {message ? <h3 style={styles.textError}>{t('LOGIN_MSG_INCORRECT_MAIL_OR_PASS')}</h3> : <></>}

                                {loading ? (
                                    <button style={styles.formButton} disabled>
                                        <div className="spinner-border text-light spinner-border-sm" role="status" />
                                    </button>
                                ) : (
                                    //si ponemos evento onclick o type submit en el ultimo boton nos perdemos lo de dar a enter y que haga submit el formulario
                                    <button className="btn btn-link" style={styles.formButton}>{t('LOGIN_BUTTON')}</button>
                                )}
                            </div>
                        </form>
                    </div>

                    <div>
                        {/* MUÑECO GIF depende de resolucion de pantalla */}
                        {screenSize > 940 ? (
                            <img
                                style={styles.buttonFloat}
                                src="./assets/img/login-Eric-Apuntando.gif"
                                alt={`A bot's images of company ${companyPath}`}
                            />
                        ) : (<></>)}
                    </div>

                    {/* FOOTER depende de resolucion de pantalla */}
                    {screenSize > 940 ? (
                        <div className="fixed-bottom text-right text-muted" style={{ marginRight: "2.4%", marginBottom: "0.5%" }}>
                            <h6 style={{ fontSize: "13px" }}>Powered by <a href='https://www.newba.cloud/' target="_blank" rel="noopener noreferrer"> Newba S.L.</a></h6>
                        </div>
                    ) : (
                        <div className="fixed-bottom text-center text-muted" style={{ marginBottom: "0.5%" }}>
                            <h6 style={{ fontSize: "13px" }}>Powered by <a href='https://www.newba.cloud/' target="_blank" rel="noopener noreferrer"> Newba S.L.</a></h6>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Login