import React from "react";
import { useTranslation } from 'react-i18next';

// components:
import Loader from '../Loader'

const AssetsCreate = ({ screenSize, darkModeState, handleSubmit, loader, name, handleChange, optionSelected, mesa_resuelve, metodoGeneracion, urlGeneracion, urlAditionalParams, totalDesks, types, methodsTypes }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
            // justifyContent: 'center'
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4',
            //  margin: '0px'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        assets: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
            alignItems: 'center',
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            //  justifyContent: 'space-between',
            //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        form: {
            width: '100%',
            margin: '10px 0px'
        },
        label: {
            margin: '0px 0px 15px',
            fontSize: "18px",
            fontFamily: "InterBold",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        input: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
            margin: '0px 0px 25px',
            // color: 'rgb(99, 104, 126)',
        },
        checkbox: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 10px 3px'
        },
        checkbox2: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 0px 25px 3px'
        },
        labelCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")} `,
        },
        inputCheckbox: {
            fontFamily: "InterRegular",
            fontSize: "16px",
            border: '1px solid red',
        },
        buttonNew: {
            textDecoration: "none",
            color: "#fff",
            backgroundColor: "#4dc2f1",
            border: 'none',
            width: `${screenSize > 940 ? ('180px') : (<></>)}`,
            margin: '10px 0px 0px'
        },
        optionsForm: {
            margin: "0px 0px 25px 0px",
            width: "100%",
            padding: '8px',
            color: 'rgb(99, 104, 126)',
            border: '1px solid lightgrey',
            borderRadius: '4px',
            fontFamily: 'InterRegular'
        },
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('CREATE_ASSET_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('CREATE_ASSET_SUBTITLE')}</h3>
                    </div>
                </div>
                <div style={styles.assets}>
                    {loader ? (
                        <Loader
                            type="tailspin"
                        />
                    ) : (
                        <form
                            onSubmit={(e) => handleSubmit(e)}
                            style={styles.form}>
                            <div className="form-group" >
                                <label style={styles.label}>{t('SINGLE_ASSET_TYPE')}</label>
                                <select className="form-select" aria-label="Asset Type" name="nameOfRol" style={styles.optionsForm} onChange={(e) => handleChange(e)}>
                                    <option value={t('PH_SELECT')} >{t('PH_SELECT')}</option>
                                    {types ? (types.map((singleType) => {
                                        return (<option key={singleType.name} value={singleType.name} selected={name === singleType.name}>{singleType.name}</option>)
                                    })) : (null)}
                                </select>
                                {/* <input onChange={(e) => handleChange(e)} name="nameOfRol" type="text" className="form-control" style={styles.input} value={name} placeholder="Name of asset..." required /> */}

                                <label style={styles.label}>{t('SINGLE_ASSET_PHYSICAL')}</label>
                                <div className="form-check" style={styles.checkbox}>
                                    <input className="form-check-input" style={styles.inputCheckbox} name="Hardware" onChange={(e) => handleChange(e)} type="radio" value="option1" id="flexCheckChecked1" checked={optionSelected === "option1"} />
                                    <label className="form-check-label" style={styles.labelCheckbox} htmlFor="flexCheckChecked1">{t('YES')}</label>
                                </div>
                                <div className="form-check" style={styles.checkbox2}>
                                    <input className="form-check-input" style={styles.inputCheckbox} name="Software" onChange={(e) => handleChange(e)} type="radio" value="option2" id="flexCheckChecked2" checked={optionSelected === "option2"} />
                                    <label className="form-check-label" style={styles.labelCheckbox} htmlFor="flexCheckChecked2">{t('NO')}</label>
                                </div>

                                <label style={styles.label}>{t('SINGLE_ASSET_DESK')}</label>
                                <select className="form-select" aria-label="Mesa de resolución" name="selectOptions" style={styles.optionsForm} onChange={(e) => handleChange(e)}>
                                    <option selected>{t('PH_SELECT')}</option>
                                    {totalDesks ? (totalDesks.map((singleDesk) => {
                                        return (<option key={singleDesk.id} value={singleDesk.id} selected={mesa_resuelve === singleDesk.id}>{singleDesk.name}</option>)
                                    })) : (null)}
                                </select>

                                <label style={styles.label}>{t('SINGLE_ASSET_METHOD')}</label>
                                <select className="form-select" aria-label="Método de generación" name="metodoGeneracion" style={styles.optionsForm} onChange={(e) => handleChange(e)}>
                                    {/* <option selected>Select the option</option> */}
                                    {methodsTypes ? (methodsTypes.map((singleMethod) => {
                                        return (<option key={singleMethod.name} value={singleMethod.id} selected={metodoGeneracion === singleMethod.id}>{singleMethod.name}</option>)
                                    })) : (null)}
                                </select>
                                {/* <input onChange={(e) => handleChange(e)} name="metodoGeneracion" type="text" className="form-control" style={styles.input} value={metodoGeneracion} placeholder="Generation method of asset..." required /> */}

                                <label style={styles.label}>{t('SINGLE_ASSET_URL')}</label>
                                <input onChange={(e) => handleChange(e)} name="urlGeneracion" type="text" className="form-control" style={styles.input} value={urlGeneracion} placeholder={t('PH_SINGLE_ASSET_URL')} />

                                <label style={styles.label}>{t('SINGLE_ASSET_PARAMS')}</label>
                                <input onChange={(e) => handleChange(e)} name="urlAditionalParams" type="text" className="form-control" style={styles.input} value={urlAditionalParams} placeholder={t('PH_SINGLE_ASSET_PARAMS')} />
                                <button type="submit" className="btn" style={styles.buttonNew}>{t('CREATE_ASSET_TITLE')}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default AssetsCreate