// hooks:
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import {NEWBA_TAG, FLUJOS, CHANNELS} from "../../utils/constants"
import { pathPart } from "../../utils/commons";
import axios from 'axios';
import moment from "moment";
import Swal from 'sweetalert2'; //https://sweetalert2.github.io/#examples

// components:
import Duplicado from "../../components/facturas/Duplicado";

const DuplicadoContainer = () => {
    // variables y constantes:
    const url = process.env.REACT_APP_API_URL;
    const channel = CHANNELS.WEB;   
    const flujo = FLUJOS.DUPLICADO;
    const companyPath = pathPart(window.location.host, 0)

    // hooks:
    const { t } = useTranslation();
    const nav = useNavigate();
    const [nif, setNif] = useState('');
    const [typeSelector, setTypeSelector] = useState('2');
    const [information, setInformation] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [message, setMessage] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [sinceDate, setSinceDate] = useState('');
    const [toDate, setToDate] = useState('');

    // funciones adicionales:
    const validaNif = (data) => axios.post(
        `${url}/validateNif/${companyPath}/validaNif`,
        data,
        {
            headers: {
                'Authorization': NEWBA_TAG
            }
        }
    )

    const checkListado = (data) => axios.post(
        `${url}/formDuplicate/${companyPath}/duplicateInvoice`,
        data,
        {
            headers: {
                'Authorization': NEWBA_TAG
            }
        }
    ).then((res) => res.data)

    // manejadores de eventos
    // handle para volver a la home de la aplicación, no del módulo
    const handleBack = (e) => {
        // nav(-1)  // con esto volveriamos un atrás del navegador
        nav('/home')// de momento queremos que siempre vuelva a la Home, donde se muestran los modulos
    }

    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e) => {
        const toChange = e.target.name
        const toChangeValue = e.target.value
        if (toChange === "nif") {
            setNif(toChangeValue);
        }
        if (toChange === "information") {
            setInformation(toChangeValue);
        }
        if (toChange === "selector") {
            setTypeSelector(toChangeValue);
        }
        if (toChange === "date") {
            setDate(toChangeValue);
        }
        if (toChange === "sinceDate") {
            setSinceDate(toChangeValue);
        }
        if (toChange === "toDate") {
            setToDate(toChangeValue);
        }
    };

    // handle para manejar el submit del form
    const handleSend = async (e) => {
        e.preventDefault();
        setLoading(true);

        let selector = ''
        if (typeSelector === '1') { selector = 'albaran' }
        if (typeSelector === '2') { selector = 'nro_documento' }
        if (typeSelector === '3') { selector = 'importe' }
        if (typeSelector === '4') { selector = 'date' } // deshabilitado en Component Duplicado.jsx. Con el de rango buscaremos también una sola factura (si solo existe una, o que busque por otro selector más especifico)
        // if (typeSelector === '5') { selector = 'multifactura' } //consideramos rango de fechas y fecha la misma consulta
        if (typeSelector === '5') { selector = 'rango' }

        try {
            let validacion = await validaNif({ nif: nif, channel, flujo });
            // console.log(validacion.data)
            if (validacion.data.code === '0') {
                // SE ENCUENTRA EL NIF CON EMAIL ASOCIADO EN LA BASE DE DATOS
                let check = await checkListado({
                    nif: nif,
                    selector: selector,
                    number: typeSelector === '4' ? moment(date).format('DD/MM/YYYY') : information, //fecha parseada
                    sinceDate: typeSelector === '5' ? moment(sinceDate).format('DD/MM/YYYY') : '', //fecha parseada
                    toDate: typeSelector === '5' ? moment(toDate).format('DD/MM/YYYY') : '', //fecha parseada
                    channel,
                    flujo,
                    detailClient: validacion.data.detailClient
                });
                // console.log(check)
                //TODO igual se deberían validar el check como aqui en 347 y listado, pero como no se devuelven casos de check.status ko que traigan check.code, (no mov, no file...) son todo errores y no hace falta mas que en el duplicado validarlo
                if (check.status === 'OK') {
                    setLoading(false);
                    setFinished(true);
                    setNif('')
                    setInformation('')
                    setDate('')
                    setSinceDate('')
                    setToDate('')
                }
                if (check.status === 'KO') {
                    setNif('')
                    setInformation('')
                    setDate('')
                    setSinceDate('')
                    setToDate('')
                    // throw new Error('error al obtener el duplicado de factura'); //no queremos que nos salte siempre el mensaje de "Hibo un problema en la solicutud" pq queremos controlar los casos de No MOV, No value y No File como en WHatsapp, se le dice al user que se le enviará y se manda mail de desborde
                    if (!check.code) throw new Error('error al obtener el duplicado de factura');
                    setLoading(false);
                    setFinished(true);
                }
                /* ESTE IF de arriba SE PODRIA CONTROLAR COMO IVR CON ALGO ASI, PERO HAY QUE REVISARLO PARA AFINAR EL MENSAJE AL USUARIO
                if (check.status === 'KO') {
                    if (check.code == '-1') {
                        setMessage('6');
                    } else {
                        setMessage('5');
                        setFinished(true);
                        throw new Error('error al obtener el duplicado de factura');
                    }
                    setLoading(false);
                    setNif('')
                    setInformation('')
                    setDate('')
                    setSinceDate('')
                    setToDate('')
                } */
            } else if (validacion.data.code === '1') {
                // NO SE ENCUENTRA EL NIF EN LA BASE DE DATOS
                setLoading(false);
                setMessage('1');
                setNif('')
                setInformation('')
                setDate('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '2') {
                // SE ENCUENTRA EL NIF EN LA BD PERO NO EXISTE MAIL ASOCIADO
                setLoading(false);
                setMessage('2');
                setNif('')
                setInformation('')
                setDate('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '-1') {
                //el caso de error de formato NO ENVIA CORREO A DESBORDE. EL USUARIO DEBE REPETIR OPERACION
                setLoading(false);
                setMessage('3');
                setNif('')
                setInformation('')
                setDate('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.code === '-2') {
                //el caso de dejar el campo en blanco NO ENVIA CORREO A DESBORDE. EL USUARIO DEBE REPETIR OPERACION
                setLoading(false);
                setMessage('4');
                setNif('')
                setInformation('')
                setDate('')
                setSinceDate('')
                setToDate('')
            } else if (validacion.data.status === 'KO') {
                //para los casos que haya algun error o excepcion en backend se devuelve un status=KO
                throw new Error('error en la validación del nif')
                /* setLoading(false);
                setMessage('5');
                setNif('') */
            }
        } catch (error) {
            setLoading(false);
            setMessage('5');
            setNif('')
            setInformation('')
            setDate('')
            setSinceDate('')
            setToDate('')
        }
    };

    // este handler es para mostrar el popup con la info al usuario al pulsar el boton de información establecido en components y le pasamos por props desde aqui la info a mostrar.
    const handleInfo = async (e) => {
        Swal.fire({
            width: '50em',
            height: '50em',
            title: t('INVOICES_HELP_TITLE'),
            /* text: 'You have to complete all the fields.', es esta opcion o la de html q es mas customizable */
            html: `
            <li style="text-align:justify; padding:0.5em; list-style-type:none;">
            ${t('INVOICES_HELP_DUPLICADO_MSG_P1')}            
            </li> <br/>
            <ul>
            <li style="text-align:justify; padding:0.5em; list-style-type:none;">${t('INVOICES_HELP_DUPLICADO_MSG_P2')}</li>
            <li style="text-align:justify; padding:0.5em;"> ${t('INVOICES_HELP_DUPLICADO_MSG_P3')}</li>
            <li style="text-align:justify; padding:0.5em;"> ${t('INVOICES_HELP_DUPLICADO_MSG_P4')}</li>
            <li style="text-align:justify; padding:0.5em;"> ${t('INVOICES_HELP_DUPLICADO_MSG_P5')}</li>
            <li style="text-align:justify; padding:0.5em;"> ${t('INVOICES_HELP_DUPLICADO_MSG_P6')}</li>
            </ul>
            `,
            icon: 'info',
            confirmButtonColor: '#4DC2F1',
            confirmButtonText: t('INVOICES_HELP_BTN'),
            showCloseButton: 'true',
            focusConfirm: true,
        })
    };

    return (
        <Duplicado
            screenSize={screenSize}
            loading={loading}
            handleSend={handleSend}
            nif={nif}
            typeSelector={typeSelector}
            information={information}
            date={date}
            sinceDate={sinceDate}
            toDate={toDate}
            finished={finished}
            message={message}
            handleChange={handleChange}
            handleInfo={handleInfo}
            handleBack={handleBack}
        />
    );
}

export default DuplicadoContainer;