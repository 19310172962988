// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";
import { defaultLang } from "../../utils/constants";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import RolSingle from "../../components/personas-jml/RolSingle";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext' 
// import { userDB } from "../../context/firebaseConfig";

const RolSingleContainer = () => {
    // hooks:
    const { t } = useTranslation();
    const location = useLocation()
    const nav = useNavigate();
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [edit, setEdit] = useState(false);
    const [loader, setLoader] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [rol, setRol] = useState([]);
    const [rolName, setRolName] = useState('');
    const [assets, setAssets] = useState([]);
    const [totalAssets, setTotalAssets] = useState([]);
    const { idRol } = useParams();
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            setRol(response[idRol])
            setRolName(response[idRol].rolName)
            setAssets(response[idRol].assets)
            dbDataAssets(user).then(responseAssets => {
                setTotalAssets(responseAssets)
                setLoader(false)
            })
        }).catch(e => {
            console.error(`Error recuperando el rol: ${e}`);
        });
    }, [user, idRol])


    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/rol/${companyPath}/getRolesWithAssets`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const dbDataAssets = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/getAssets`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const sendData = (user, nameOfRol, assets) => {
        return new Promise((resolve, reject) => {
            let params = {
                "rolName": nameOfRol,
                "assets": assets, 
                "lang": localStorage.getItem('i18nextLng') || defaultLang
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/rol/${companyPath}/setRol`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const deleteData = (user, nameOfRol) => {
        return new Promise((resolve, reject) => {
            let params = {
                "rolName": nameOfRol
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/rol/${companyPath}/delRol`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e, state) => {
        if (state === 'name') {
            const name = e.target.value;
            setRolName(name);
        } else if (state === 'type') {
            const isInArray = (element) => element.assetType === totalAssets[e].assetType;
            let result = assets.findIndex(isInArray)
            if (result !== -1) {
                assets.splice(result, 1)
                let newTotal = totalAssets.slice()
                setTotalAssets(newTotal)
            } else {
                assets.push(totalAssets[e])
                let newTotal = totalAssets.slice()
                setTotalAssets(newTotal)
            }
        }
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (edit) {
            if (rolName === '' || assets.length < 1) {
                Swal.fire({
                    title: t('SWAL_TITLE_NO_DATA'),
                    text: t('SWAL_TEXT_NO_DATA'),
                    icon: 'warning',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
            } else {
                setEdit(!edit);
                let assetsToSendFinal = []
                assets.map((singleAsset) => assetsToSendFinal.push({ name: singleAsset.assetType }))
                sendData(user, rolName, assetsToSendFinal)
                    .then(response => {
                        Swal.fire({
                            title: t('SWAL_TITLE_SAVED'),
                            text: t('SWAL_TEXT_SAVED_SINGLE_ROL'),
                            icon: 'success',
                            confirmButtonColor: '#4dc2f1',
                            confirmButtonText: t('SWAL_BTN_CONTINUE'),
                            showCloseButton: 'true',
                        })
                        nav(`/roles`);
                    }).catch(e => {
                        console.error(`Error: ${e}`);
                    });
            }
        } else {
            setEdit(!edit);
        }
    };

    const handleDelete = (e) => {
        if (e) { e.preventDefault() }
        deleteData(user, rolName)
            .then(response => {
                Swal.fire({
                    title: t('SWAL_TITLE_DELETED'),
                    text: t('SWAL_TEXT_DELETED_SINGLE_ROL'),
                    icon: 'success',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
                nav(`/roles`);
            }).catch(e => {
                console.error(`Error: ${e}`);
            });
    };

    return (
        <>
            <Sidebar
                module={location.pathname}           
                path= {path}
                screenSize={screenSize}
            />
            <RolSingle
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                handleDarkMode={handleDarkMode}
                edit={edit}
                loader={loader}
                handleChange={handleChange}
                handleDelete={handleDelete}
                rolName={rolName}
                totalAssets={totalAssets}
                assets={assets}
            />
        </>
    );
}

export default RolSingleContainer;