// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom"; //Link
import { useAuthUser } from "../hooks/useAuthUser"; //importamos ntro customHook en lugar del context

//components: 
import Home from "../components/Home";
import FatalError from './500Unexpected';

// views:
import Sidebar from "./Sidebar";

// contexts:
import { logOut } from "../context/firebaseConfig";

//utils:
import { pathPart } from "../utils/commons";

const HomeContainer = () => {
  // hooks:
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const nav = useNavigate();
  const location = useLocation()
  const [darkmode, setDarkmode] = useState(false);
  const [error500, setError500] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [loader, setLoader] = useState(true);
  const user = useAuthUser() //aqui obtenemos el usuario de Firebase
  const companyPath = pathPart(window.location.host, 0)
  const path = `./assets/img/${companyPath}/logo-clear.png`

  // useEffect al cargar el componente y dependencias que hacen q recargue:
  useEffect(() => {
    try {
      setLoader(false)
    } catch (error) {
      console.log(error);
      //TODO: TUTORIA-ERR => porque dentro de t? para que lo traduzca o algo asi?
      setError500(t(error.code || error))
    }
  }, [t])

  // manejadores de eventos   
  //handle para tema oscuro (no desarrollado)
  const handleDarkMode = () => {
    setDarkmode(!darkmode);
  };

  //handle para cerrar session
  const handleLogout = (e) => {
    logOut()
    nav('/login');
  };

  if (error500)
    return <FatalError />

  return (
    <>
      <Sidebar
        module={location.pathname}
        // path="./assets/img/logo-clear.png"
        path={path}
        screenSize={screenSize}
      />
      <Home
        screenSize={screenSize}
        darkModeState={darkmode}
        handleLogout={handleLogout}
        handleDarkMode={handleDarkMode}
        loader={loader}
        user={user}
      />
    </>
  );
}

export default HomeContainer;