// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

import Swal from 'sweetalert2'
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import AssetSingle from "../../components/personas-jml/AssetSingle";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext'
// import { userDB } from "../../context/firebaseConfig";

const SingleAssetClient = () => {
    // hooks:
    const { t } = useTranslation();
    const location = useLocation()
    const nav = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [edit, setEdit] = useState(false);
    const [asset, setAsset] = useState([]);
    const [loader, setLoader] = useState(true);
    const [name, setName] = useState('');
    const [isFisico, setIsFisico] = useState(true);
    const [mesa_resuelve, setMesa_resuelve] = useState('');
    const [metodoGeneracion, setMetodoGeneracion] = useState('');
    const [urlGeneracion, setUrlGeneracion] = useState('');
    const [urlAditionalParams, setUrlAditionalParams] = useState('');
    const [id, setId] = useState('');
    const [priority_id, setPriority_id] = useState('');
    const [type_id, setType_id] = useState('');
    const [category_id, setCategory_id] = useState('');
    const [totalDesks, setTotalDesks] = useState([]);
    const [optionSelected, setOptionSelected] = useState('option1');
    // eslint-disable-next-line no-unused-vars
    const [methods, setMethods] = useState([{ name: t('SINGLE_ASSET_METHOD_10'), id: 10 }, { name: t('SINGLE_ASSET_METHOD_1'), id: 1 }, { name: t('SINGLE_ASSET_METHOD_2'), id: 2 }, { name: t('SINGLE_ASSET_METHOD_3'), id: 3 }]);
    const { idAsset } = useParams();
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `../assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbDataDesks(user).then(responseDesks => {
            // console.log(responseDesks)
            setTotalDesks(responseDesks)
            dbData(user).then(response => {
                // console.log(response)
                setAsset(response[idAsset])
                setName(response[idAsset].assetType)
                setIsFisico(response[idAsset].isFisico)
                let mesaResponse = response[idAsset].mesa_resuelve
                let isMesa = responseDesks.some(e => e.id === mesaResponse)
                let isMesaFinal = isMesa ? mesaResponse : 0
                setMesa_resuelve(isMesaFinal)
                let methodResponse = response[idAsset].metodoGeneracion
                let isMethod = methods.some(e => e.id === methodResponse)
                let isMethodFinal = isMethod ? methodResponse : 10
                setMetodoGeneracion(isMethodFinal)
                setUrlGeneracion(response[idAsset].urlGeneracion)
                let parseUrlAditionalParams = JSON.stringify(response[idAsset].urlAditionalParams) // recuperamos un objecto que pasamos a JSON
                parseUrlAditionalParams = parseUrlAditionalParams.replaceAll('"', ' ').replaceAll('{', '').replaceAll('}', '') //parseamos el JSON que viene de FB para que se muestre ok
                setUrlAditionalParams(parseUrlAditionalParams)
                setId(response[idAsset].id)
                setPriority_id(response[idAsset].priority_id)
                setType_id(response[idAsset].type_id)
                setCategory_id(response[idAsset].category_id)
                if (response[idAsset].isFisico === 'true') {
                    setOptionSelected('option1')
                } else {
                    setOptionSelected('option2')
                }
                setLoader(false)
            }).catch(e => {
                console.error(`Error recuperando los assets: ${e}`);
            });
        })
    }, [idAsset, methods, user])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/getAssets`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const dbDataDesks = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            // const params = { email: 'gjimenez@newba.cloud' } 
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/tickets/${companyPath}/getDesks`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e);
                })
        })
    }

    const sendData = (user, nameOfAsset, type, mesa_resuelve, metodoGeneracion, urlGeneracion, urlAditionalParams, id, priority_id, type_id, category_id) => {
        return new Promise((resolve, reject) => {
            let parseUrlAditionalParams = [];
            let tmp = [];
            let parsed = {};
            if (urlAditionalParams === ' ' || urlAditionalParams === '' || urlAditionalParams === null || urlAditionalParams === undefined) {
                parsed = {};
            } else {
                //parseamos urlAditionalParams para que se guarde en FB como un objeto con pares clave-valor
                parseUrlAditionalParams = urlAditionalParams.replaceAll(':', ' : ').replaceAll(',', ' , ').replaceAll(';', ' , ').replaceAll('=', ' : ').split(' ');
                tmp = parseUrlAditionalParams.filter(n => n !== '');
                parseUrlAditionalParams = tmp.join(" ");
                parseUrlAditionalParams = `{"${parseUrlAditionalParams.trim().replaceAll(' ', '"')}"}`; //formateamos a JSON
                parsed = JSON.parse(parseUrlAditionalParams) //convertimos el JSON a Objeto JS,
            }

            let params = {
                "name": nameOfAsset,
                "assetType": nameOfAsset,
                "isFisico": type === true ? "true" : "false",
                "mesa_resuelve": Number(mesa_resuelve),
                "metodoGeneracion": Number(metodoGeneracion),
                "urlGeneracion": urlGeneracion,
                "urlAditionalParams": parsed,
                "id": id,
                "priority_id": priority_id,
                "type_id": type_id,
                "category_id": category_id,
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/setAsset`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    const deleteData = (user, nameOfAsset) => {
        return new Promise((resolve, reject) => {
            let params = {
                "assetType": nameOfAsset
            }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/deleteAsset`, params, headers)
                .then(data => {
                    resolve(true)
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    // handle para controlar los cambios en los campos del fomrulario
    const handleChange = (e) => {
        const type = e.target.name;
        const value = e.target.value;
        if (type === 'nameOfRol') { setName(value) }
        if (type === 'mesa_resuelve') { setMesa_resuelve(value) }
        if (type === 'metodoGeneracion') { setMetodoGeneracion(value) }
        if (type === 'urlGeneracion') { setUrlGeneracion(value) }
        if (type === 'urlAditionalParams') { setUrlAditionalParams(value) }
        if (type === 'Hardware') { setIsFisico(true); setOptionSelected(value) }
        if (type === 'Software') { setIsFisico(false); setOptionSelected(value) }
        if (type === 'selectOptions') { setMesa_resuelve(value) }
    };

    // handle para manejar el submit del form
    const handleSubmit = (e) => {
        if (e) { e.preventDefault() }
        if (edit) {
            if (name === '' || mesa_resuelve === 0 || metodoGeneracion === '') {
                Swal.fire({
                    title: t('SWAL_TITLE_NO_DATA'),
                    text: t('SWAL_TEXT_NO_DATA'),
                    icon: 'warning',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
            } else {
                setEdit(!edit);
                setLoader(true)
                sendData(user, name, isFisico, mesa_resuelve, metodoGeneracion, urlGeneracion, urlAditionalParams, id, priority_id, type_id, category_id)
                    .then(response => {
                        Swal.fire({
                            title: t('SWAL_TITLE_SAVED'),
                            text: t('SWAL_TEXT_SAVED_SINGLE_ASSET'),
                            icon: 'success',
                            confirmButtonColor: '#4dc2f1',
                            confirmButtonText: t('SWAL_BTN_CONTINUE'),
                            showCloseButton: 'true',
                        })
                        nav(`/asset`);
                    }).catch(e => {
                        console.error(`Error: ${e}`);
                    });
            }
        } else {
            setEdit(!edit);
        }
    };

    // handle para manejar el borrado en base de datos
    const handleDelete = (e) => {
        if (e) { e.preventDefault() }
        deleteData(user, name)
            .then(response => {
                Swal.fire({
                    title: t('SWAL_TITLE_DELETED'),
                    text: t('SWAL_TEXT_DELETED_SINGLE_ASSET'),
                    icon: 'success',
                    confirmButtonColor: '#4dc2f1',
                    confirmButtonText: t('SWAL_BTN_CONTINUE'),
                    showCloseButton: 'true',
                })
                nav(`/asset`);
            }).catch(e => {
                console.error(`Error: ${e}`);
            });
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                path={path}
                screenSize={screenSize}
            />
            <AssetSingle
                screenSize={screenSize}
                darkModeState={darkmode}
                handleSubmit={handleSubmit}
                edit={edit}
                asset={asset}
                loader={loader}
                handleChange={handleChange}
                handleDelete={handleDelete}
                name={name}
                isFisico={isFisico}
                optionSelected={optionSelected}
                mesa_resuelve={mesa_resuelve}
                metodoGeneracion={metodoGeneracion}
                urlGeneracion={urlGeneracion}
                urlAditionalParams={urlAditionalParams}
                totalDesks={totalDesks}
                methodsTypes={methods}
            />
        </>
    );
}

export default SingleAssetClient;