// import React, { useEffect, Suspense } from "react";
import React from "react";
// import { Route, Switch } from "react-router-dom";
// if error with Switch[react-router-dom] => You are using react-router@v6 which uses "Routes" instead of Switch. solution: -Replace "Switch" with "Routes" or -Downgrade your react-router to v5 using npm install react-router-dom@5
import { Route, Routes } from 'react-router-dom'

// utils,services and others
import ProtectedRoute from './ProtectedRoute'

// contexts
import { AuthContextProvider } from '../context/AuthContext'

// Componentes
// import Loader from "../components/Loader"; 
import Rest from "../components/personas-jml/Reset";

// views o pages (containters en otros proyectos)
import NotFound from '../views/404NotFound'
import Login from '../views/Login'
import Home from "../views/Home";
import LoginUser from "../views/LoginUser";
import Tickets from "../views/pedidos/Tickets";
import Politicas from "../views/politicas/Politicas";
import Orders from "../views/pedidos/Orders";
import OrderSingle from "../views/pedidos/OrderSingle";
import OrdersCreate from "../views/pedidos/OrdersCreate";
import Shippings from "../views/pedidos/Shippings";
import ShippingSingle from "../views/pedidos/ShippingSingle";
import InvoicesHome from "../views/facturas/Home";
import Flujo347 from "../views/facturas/Flujo347";
import Listado from "../views/facturas/Listado";
import Duplicado from "../views/facturas//Duplicado";
import DashboardJML from "../views/personas-jml/DashboardJML";
import Tasks from "../views/personas-jml/Tasks";
import Assets from "../views/personas-jml/Assets";
import AssetSingle from "../views/personas-jml/AssetSingle";
import AssetsCreate from "../views/personas-jml/AssetsCreate";
import Roles from "../views/personas-jml/Roles";
import RolSingle from "../views/personas-jml/RolSingle";
import RolesCreate from "../views/personas-jml/RolesCreate";
// import People from "../views/personas-jml/People";  => TASKS no implementado
import KnowledgeBase from "../views/personas-jml/KnowledgeBase";
import Chat from "../views/personas-jml/Chat";


const RoutesIndex = () => {
    return (
        // <AuthContext value={{ name: 'Provider', user: null }}>
        <AuthContextProvider>
            <Routes>

                {/*1. Public Routes */}
                {/* 1.1. FACTURAS-CASEN */}
                <Route path="/invoices" element={<InvoicesHome />} />
                <Route path="/invoices/solicitud-347" element={<Flujo347 />} />
                <Route path="/invoices/listado-facturas" element={<Listado />} />
                <Route path="/invoices/duplicado-factura" element={<Duplicado />} />

                {/* 1.2. POLITICAS Las politicas si realmente fueran politicas de empresa, serían publicas y deberian ir sin el menu lateral, porque no ENTRAMOS a la aplicacion y no existe usuario  autenticado. Entonces en ese caso sí que irian aqui.*/}
                {/* <Route exact path='/politics' element={<Politicas />} /> */}

                <Route path='/' element={<Login />} />
                <Route exact path='/login' element={<Login />} /> {/* <Route exact path="/login" component={Login} /> */}

                {/*2. Protected Routes */}
                <Route element={<ProtectedRoute />} >
                    {/* 2.1. Menu inicial con los modulos */}
                    <Route exact path="/home" element={<Home />} />
                    {/* User */}
                    <Route exact path="/user" element={<LoginUser />} />

                    {/* 2.2. PEDIDOS */}
                    {/* Tickets/Incidencias de Envios */}
                    <Route exact path="/tickets" element={<Tickets />} />
                    {/* Movimientos/Pedidos */}
                    <Route exact path="/orders" element={<Orders />} />
                    <Route exact path="/orders/:orderId" element={<OrderSingle />} />
                    <Route exact path="/orders/create" element={<OrdersCreate />} />
                    {/* Gestión de envios */}
                    <Route exact path="/shipping" element={<Shippings />} />
                    <Route exact path="/shipping/:shippingId" element={<ShippingSingle />} />

                    {/* 2.3. PERSONAS-POC */}
                    <Route exact path="/dashboard-jml" element={<DashboardJML />} />
                    <Route exact path="/tasks" element={<Tasks />} />
                    <Route exact path="/people" element={<Tasks />} />
                    <Route exact path="/asset" element={<Assets />} />
                    <Route exact path="/asset/:idAsset" element={<AssetSingle />} />
                    <Route exact path="/asset/create" element={<AssetsCreate />} />
                    <Route exact path="/roles" element={<Roles />} />
                    <Route exact path="/roles/:idRol" element={<RolSingle />} />
                    <Route exact path="/roles/create" element={<RolesCreate />} />

                    <Route exact path="/knowledge" element={<KnowledgeBase />} />
                    <Route path="/bot/:idBot" element={<Chat />} />

                    {/* EN TODAS LAS RUTAS DEBERIA LLEGAR EL COMPANYPATH PARA LUEGO PODER LLAMAR A LOS WEBSERVICES ¿?¿?¿? TODO: ERR_TUTORIA => esto lo saque de la web de la poc antigua, no chukrut pq no estaba hecho... Y QUEDA PENDIENTE REVISAR Y HACER PRUEBAS DE TODO EL ALTA DE JML CUANDO INVGATE SOLUCIONE EL PROBLEMA */}
                    <Route path='/reset/:companyPath/:user' element={<Rest />} />

                    {/* 2.4. POLITICAS -  Aqui lo que tendríamos son gestión de documentos como ejemplo que si deben estar protegidos y no ser ruta publica*/}
                    <Route exact path='/politics' element={<Politicas />} />

                </Route>

                {/*3. Non defined Routes 404 not found */}
                <Route path='*' element={<NotFound />} />
                {/* TODO: TUTORIA-ERR: COMO CONTROLO RUTAS INSPERADAS, como por ejemplo una ruta /orders/petete, que no está definida arriba, pero no me sale la pagina notFound. Tal vez habra que ajustar mostrar el sidebar si vemos que hace extraño con el includos en components/Sidebar.jsx*/}
                {/* <Route path='/orders/*' element={<NotFound />} /> */}

            </Routes>
        </AuthContextProvider>
    )
}

export default RoutesIndex;