//hooks:
import React, { useState, useEffect } from "react"; //useContext
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"; //useLocation

//components
import Login from "../components/Login";
import FatalError from './500Unexpected';

//contexts: 
import { app, getAuth, signInWithEmailAndPassword } from "../context/firebaseConfig";

const LoginContainer = () => {
  // hooks:
  const { t } = useTranslation();
  const nav = useNavigate();
  // const loc = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [error500, setError500] = useState('')
  const [auth, setAuth] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  // const [loginUser, setLoginUser] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  // useEffect al cargar el componente y dependencias que hacen q recargue:
  useEffect(() => {
    //recuperamos usuario autenticado en firebase y lo asignamos al hook auth
    setAuth(getAuth(app));
  }, []);

  // manejadores de eventos   
  // handle para controlar el input para usuario
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    setMessage(false);
  };

  // handle para controlar el input para contraseña
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setMessage(false);
  };

  // handle para manejar el submit del form
  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage(false);
    // setLoading(true);
    await signInWithEmailAndPassword(auth, username, password)
      .then(async result => {
        // console.log(result)
        // setLoginUser(result)
        // return nav('/tickets'); // nav(to); /home   
        return nav('/home');

      }).catch(error => {
        console.log(error);
        setError(t(error.code))
        // setError500(t(error.code || error)) //No lo activamos porque si metemos mal user o password apareceria la vista de error unexpected 500
        setLoading(false);
        setMessage(true);
        setUsername('');
        setPassword('')
      });
  };

  if (error500)
    return <FatalError />

  return (
    <>
      <Login
        screenSize={screenSize}
        username={username}
        password={password}
        message={message}
        loading={loading}
        onChangeUsername={onChangeUsername}
        onChangePassword={onChangePassword}
        handleLogin={handleLogin}
      />
    </>

  );
}

export default LoginContainer //este nombre nos es indiferente poruqe luego importamos el fichero completo en las rutas