// hooks:
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuthUser";

// import Swal from 'sweetalert2'; //  is defined but never used  no-unused-vars
import axios from "axios";
import { pathPart } from "../../utils/commons";

// components:
import Assets from "../../components/personas-jml/Assets";

//views
import Sidebar from "../Sidebar";

// contexts: 
import { getAuthHeader } from '../../context/AuthContext' 
// import { userDB } from "../../context/firebaseConfig";

const AssetsContainer = () => {
    // hooks:
    // eslint-disable-next-line no-unused-vars
    const { t } = useTranslation();
    const nav = useNavigate();
    const location = useLocation()
    const [darkmode, setDarkmode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [assets, setAssets] = useState([]);
    const [loader, setLoader] = useState(true);
    // const [loginUser, setLoginUser] = useState({}); //aqui almacenamos la info de la BD para ese usuario
    const user = useAuthUser() //aqui obtenemos el usuario de Firebase pot contexto
    const companyPath = pathPart(window.location.host, 0)
    const path = `./assets/img/${companyPath}/logo-clear.png`

    //varios useEffects al cargar el componente y dependencias que hacen q recargue:
    useEffect(() => {
        dbData(user).then(response => {
            setAssets(response);
            setLoader(false)
        }).catch(e => {
            console.error(`Error recuperando los tickets: ${e}`);
        });
    }, [user])

    // useEffect(() => {
    //     if (error) {
    //         // console.log(error)
    //         setLoader(false)
    //         Swal.fire({
    //             title: t('SWAL_ERR_TITLE'),
    //             text: `${t('SWAL_ERR_MSG_GENERIC')}`, //[${e}]
    //             icon: 'error',
    //             confirmButtonColor: '#4dc2f1',
    //             confirmButtonText: t('SWAL_BTN_CONTINUE'),
    //             showCloseButton: 'true',
    //         })
    //     }
    // }, [t, error])

    // funciones adicionales:
    const dbData = (user) => {
        return new Promise((resolve, reject) => {
            const params = { email: user.email }
            const headers = { headers: getAuthHeader(user) }
            axios.post(`${process.env.REACT_APP_API_URL}/asset/${companyPath}/getAssets`, params, headers)
                .then(data => {
                    let responseArray = data.data.detail
                    resolve(responseArray);
                }).catch(e => {
                    resolve(e)
                })
        })
    }

    // manejadores de eventos   
    //handle para tema oscuro (no desarrollado)
    const handleDarkMode = () => {
        setDarkmode(!darkmode);
    };

    //manejador de cada movimiento para poder acceder al detalle
    const handleClick = (idAssetSelected) => {
        nav(`/asset/${idAssetSelected}`);
    };

    return (
        <>
            <Sidebar
                module={location.pathname}
                path = {path}
                screenSize={screenSize}
            />
            <Assets
                screenSize={screenSize}
                darkModeState={darkmode}
                handleClick={handleClick}
                handleDarkMode={handleDarkMode}
                assets={assets}
                loader={loader}
            />
        </>
    );
}

export default AssetsContainer;