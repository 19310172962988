import Loader from "react-loader-spinner";  //https://mhnpd.github.io/react-loader-spinner/docs/intro
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderComponent = ({ type }) => {
    // unificamos todos los posibles loaders, pasandole una prop "type" que nos identifica qué loader estamos usando
    //https://mhnpd.github.io/react-loader-spinner/docs/intro
    //Ponemos valores por defecto del loader por defecto, un reloj
    let tipo = ""
    let color = "#4dc2f1"
    let alto = 100
    let ancho = 100
    let radio = 0
    let ariaLabel = ""
    let glassColor = ""

    const styles = {
        containerLoader: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '75vh',
        },
        loader: {
            // padding: "2em",
            display: 'flex',
            alignSelf: 'center'
        },
    }

    if (type === 'dots') {
        tipo = "ThreeDots"
        ariaLabel = "three-dots-loading"
        radio = 9
    } else if (type === 'tailspin') {
        tipo = 'TailSpin'
        ariaLabel = "tail-spin-loading"
        radio = 1
    } else if (type === 'dotsSmall') {
        tipo = "ThreeDots"
        ariaLabel = "three-dots-loading"
        radio = 3
        alto = 40
        ancho = 40
    } else {
        //loader por defecto, un reloj
        tipo = 'Watch'
        ariaLabel = "watch-loading"
        radio = 50
    }

    return (
        <div style={styles.containerLoader}>
            {/* <h2>Cargando aplicación....</h2> */}
            <Loader
                style={styles.loader}
                type={tipo}
                color={color}
                height={alto}
                width={ancho}
                ariaLabel={ariaLabel}
                radius={radio}
                timeout={15000} //15 seconds
                visible={true}
                glassColor={glassColor}
            />
        </div>
    )
}

export default LoaderComponent;