// hooks:
// import React, { useState, useEffect, useContext } from "react"; //aqui importariamos el hook useContext pero como usamos el hook que hemos preparado no haria ya falta importarlo aqui
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuthUser } from "../hooks/useAuthUser"; //importamos ntro customHook en lugar del context

//components:
import Sidebar from "../components/Sidebar";
// import FatalError from './500Unexpected';

// contexts: 
// import AuthContext, { getAuthHeader } from '../context/AuthContext'  //OJO QUE AQUI NOS IMPORTARIAMOS EL CONTEXTO COMPLETO, EL ARCHIVO DEL CONTEXTO, pero como usamos el hook que hemos preparado no haria ya falta importarlo aqui
// import { getAuthHeader } from '../context/AuthContext';//si necesitasemos obtener los headers para hacer las consultas, como en Tickets.jsx importamos ademas esta funcion
import { userDB, logOut } from "../context/firebaseConfig";


const SidebarContainer = ({ module, path, location }) => {
  // hooks:
  const nav = useNavigate();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [screenSize, setScreenSize] = useState(window.innerWidth)
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(true)
  const [loginUser, setLoginUser] = useState(null)
  // const [error500, setError500] = useState('')


  //Podiamos importar este contexto directamente en los componentes donde queramos consumirlo, pero como lo vamos a recuperar de varios sitios, hemos creado un custom hook, para que nos devuelva directamente el usuario y podemos usar direcamente usuario
  // const authC = useContext(AuthContext)
  // console.log(authC) // en authC.user.email tenemos el mail y tendriamos que cambiar todos los user por authC.user
  const user = useAuthUser() //aqui obtenemos el usuario de Firebase

  // useEffect al cargar el componente y dependencias que hacen q recargue:
  useEffect(() => {
    // console.log(user.email)
    userDB(user?.email)
      .then(res => {
        // console.log(res)
        if (res.status === 'KO') throw new Error('Error intentando recuperar la informacion de usuario');
        setLoginUser(res)
        setLoader(false)
      }).catch(e => {
        console.error(`Error recuperando el usuario: ${e}`);
        setLoader(false)
        // setError500(t(e.code || e))
        /*Aqui no mostramos un modal indicando que se ha producido error, porque sino cada vez que pulsamos en alguna parte nos saldría la advertencia. Deshabilitamos boton unicamente*/
      })
  }, [user, t])

  // useEffect(() => {
  //   console.log('VISTA SCREEN', screenSize)
  //   setScreenSize(window.innerWidth)
  // }, [screenSize])

  // manejadores de eventos   
  // handle para cerrar sesion
  const handleLogout = (e) => {
    logOut()
    nav('/login');
  };

  //handle para acceder a la info de usuario del menu
  const handleUser = (e) => {
    nav("/user");
  };

  //handle para volver a la pagina de inicio o Home
  const handleBack = (e) => {
    // nav(-1)  // con esto volveriamos un atrás del navegador
    nav('/home')// de momento queremos que siempre vuelva a la Home, donde se muestran los modulos
  }

  // if (error500)
  // return <FatalError />

  return (
    <Sidebar
      handleLogout={handleLogout}
      loginUser={loginUser}
      handleUser={handleUser}
      screenSize={screenSize}
      handleBack={handleBack}
      loader={loader}
      module={module}
      path={path}
    />
  );
}

export default SidebarContainer;