import { useEffect } from 'react'
import { capitalizeFirst, pathPart } from './utils/commons'
import Routes from './routes/Routes'

const App = () => {
  const companyPath = pathPart(window.location.host, 0)
  
  //Este usseEffect actualiza dinamicamente el favicon y el title de la pestaña del panel en funcion del nombre del host cuando buscamos el companyPath en la url 
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/assets/img/${companyPath}/icon.png`;

    document.title = `${capitalizeFirst(companyPath)} - Panel de mando`;

  }, [companyPath]);

  return (
    <Routes />
  )
}

export default App;

/**
 * POSIBLEMENTE HAYA QUE DESINSTALAR ESTAS DEPENDENCIAS CUANDO CONSIGA QUE FUNCIONE LO MIO
 * antd
 * country-flag-icons
 * html-to-react
 * sass
 * sass-loader
 */