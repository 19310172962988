import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import i18resources from '../i18resources'
import i18next from "i18next";
import "flag-icons/css/flag-icons.min.css";
//https://www.npmjs.com/package/flag-icons y tb https://flagicons.lipis.dev/
import { defaultLang } from "../utils/constants";

const SelectorLanguages = ({ direction, screenSize }) => {
    // unificamos todos los posibles selectores de idioma, pasandole una prop "direction" que nos identifica qué dropdown estamos usando: dropleft, dropright, dropup
    const styles = {
        dropleft: {
            //     width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            display: "flex",
            justifyContent: 'right',
            alignItems: "center",
            marginRight: "2%",
            // marginBottom: "5%"
        },
        dropright: {
            padding: 20,
            paddingLeft: 28,
            display: "flex",
            alignItems: "center",
            backgroundColor: '#fff',
            color: '#63687e',
            border: 'none',
            outline: 0,
            width: '100%'
        },
        labelIcon: {
            display: "flex",
            // flex: 0.49,
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontSize: '90%',
            marginLeft: "15px",
            marginRight: "20px",
        }
    }

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || defaultLang);
    const changeLang = (code, e) => {
        e.preventDefault();
        setCurrentLang(code);
        i18next.changeLanguage(code);
    }

    // además. aplicamos estilos distintos en funcion de esta direccion, si es dropleft es el boton del login, y si es dropright será el selector del sidebar
    let height = direction === 'dropleft' ? 30 : 20
    let width = direction === 'dropleft' ? 30 : 20

    return (
        <div className={`dropdown ${direction}`}>
            <button className="btn btn-link dropdown-toggle" style={direction === 'dropleft' ? styles.dropleft : styles.dropright} type="button" data-toggle="dropdown" >
                <i18resources.GlobeIcon1 height={height} width={width} />
                {
                    direction === 'dropleft' ?
                        <></>
                        :
                        <span style={styles.labelIcon}>{t('SIDEBAR_LANG')}</span>
                }
            </button>

            <div className="dropdown-menu dropdown-menu-end">
                {i18resources.langList.map(({ code, name, country_code }) => (
                    <div key={country_code}>
                        <button className="dropdown-item" value={code}
                            type="button"
                            onClick={(e) => changeLang(code, e)}
                            disabled={currentLang === code}>
                            <span className={`fi fi-${country_code} mx-2`}></span>
                            {name}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SelectorLanguages