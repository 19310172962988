import React from "react";

//components
import Loader from './Loader'
import SidebarLogo from "./SidebarLogo";
import SidebarActions from "./SidebarActions";
import SelectorLanguages from "./SelectorLanguages";
import SidebarCPedidos from "./pedidos/SidebarCPedidos";
import SidebarJML from "./personas-jml/SidebarJML"

const Sidebar = ({ handleLogout, loginUser, handleUser, handleBack, screenSize, module, path, loader }) => {
  const styles = {
    linkContainer: {
      display: "flex",
      verticalAlign: "text-bottom ",
      flex: 1,
      flexDirection: 'column',
      height: '85%'
    },
    icons: {
      marginRight: "10px",
      marginLeft: "10px",
      width: '25px',
      height: '25px',
    },
    row: {
      justifyContent: "space-between",
      marginRight: "1%",
      marginTop: "1%"
    }
  };

  return (
    /* TODO: ERR_TUTORIA => lo que me gustaria es que mostrase el sidebar o el topbar en funcion del tamaño de pantalla
    ya conseguido que muestre uno u otro, pero el lateral queda feo a partir de tamaño menor a  1010 */
    screenSize > 940 ?
      <>
        <nav id="sidebar">
          {/* SIDEBAR MENU-LOGO */}
          <SidebarLogo path={path} />
          <hr />

          {loader ? (
            <Loader
              type="dotsSmall"
            />
          ) : (
            <div style={styles.linkContainer}>
              {/* SIDEBAR MENU-COMPONENT*/}
              {
                // en Home, User y Politicas está vacío, no hay submenu, solo las actions. En Modulo Facturas no presentamos tampoco sidebar, asi que se incluiría aqi. 
                module === '/home' || module === '/user' || module === '/politics' ? (
                  <ul style={styles.linkContainer}></ul>
                ) : <></>
              }
              {
                // en Modulo Pedidos, incluimos todas las rutas del mismo y apuntamos al componente especifico
                //TODO: ERR- TUTORIA:  Tal vez habra que ajustar mostrar el sidebar si vemos que hace extraño con el includos en components/Sidebar.jsx al controlar RUTAS INSPERADAS, como por ejemplo una ruta /orders/petete, que no está definida arriba, pero no me sale la pagina notFound EN ROUTES.JS.
                module === '/tickets' || module === '/orders' || module === '/orders/create' || module.includes('/orders') || module.includes('/shipping') ? (
                  <SidebarCPedidos />
                ) : <></>
              }
              {
                // en Modulo Personas, incluimos todas las rutas del mismo y apuntamos al componente especifico
                module === '/dashboard-jml' || module === '/tasks' || module.includes('/asset') || module.includes('/roles') || module.includes('/bot') || module === '/people' || module === '/knowledge' ? (
                  <SidebarJML />
                ) : <></>
              }

              {/* SIDEBAR MENU-ACTIONS */}
              <SidebarActions
                handleLogout={handleLogout}
                loginUser={loginUser}
                handleUser={handleUser}
                screenSize={screenSize}
                handleBack={handleBack}
              // location={location}
              />
            </div>
          )}
        </nav>
      </>
      :
      <>
        <div className="row" style={styles.row}>
          <div className="dropdown col col-6"  >
            <button className="btn" type="button" data-toggle="dropdown" aria-expanded="false">
              <svg style={styles.icons} fill="currentColor" className="bi bi-list text-muted" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {/* SIDEBAR MENU-LOGO EN BURGUER MENU NO LO PONEMOS */}
              {loader ? (
                <Loader
                  type="dotsSmall"
                />
              ) : (
                <div style={styles.linkContainer}>
                  {/* SIDEBAR MENU-COMPONENT*/}
                  {
                    // en Home, User y Politicas está vacío, no hay submenu, solo las actions. En Modulo Facturas no presentamos tampoco sidebar, asi que se incluiría aqi. 
                    module === '/home' || module === '/user' || module === '/politics' ? (
                      <ul style={styles.linkContainer}></ul>
                    ) : <></>
                  }
                  {
                    module === '/tickets' || module === '/orders' || module === '/orders/create' || module.includes('/orders') || module.includes('/shipping') ? (
                      <SidebarCPedidos />
                    ) : <></>
                  }
                  {
                    // en Modulo Personas, incluimos todas las rutas del mismo y apuntamos al componente especifico
                    module === '/dashboard-jml' || module === '/tasks' || module.includes('/asset') || module.includes('/roles') || module.includes('/bot') || module === '/people' || module === '/knowledge' ? (
                      <SidebarJML />
                    ) : <></>
                  }

                  {/* SIDEBAR MENU-ACTIONS como le pasamos el screensize, no mostraremos el selector aqui, sino como en login */}
                  <SidebarActions
                    handleLogout={handleLogout}
                    loginUser={loginUser}
                    handleUser={handleUser}
                    screenSize={screenSize}
                    handleBack={handleBack}
                  />
                </div>
              )}
            </div>
          </div>
          {/* El selector de idiomas como en login en resoluciones pequeñas*/}
          <SelectorLanguages direction='dropleft' screenSize={screenSize} />
        </div>
      </>
  );
};

export default Sidebar