import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { srcPedidos, srcFacturas, srcPersonas, srcDocumentos } from "../utils/constants"

// components:
import Loader from './Loader'

const Home = ({ screenSize, darkModeState, handleLogout, handleDarkMode, loader, user }) => {
  const styles = {
    container: {
      // position: "fixed",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
      padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
      height: "100%",
      overflow: `auto`,
    },
    iconDiv: {
      width: "35%",
      display: "flex",
      alignItems: "left",
    },
    icons: {
      display: "flex",
      alignItems: "center",
    },
    link: {
      padding: 15,
      display: "flex",
      alignItems: "center",
    },
    lineDetailCenter: {
      display: "flex",
      alignItems: 'right',
      justifyContent: 'space-between',
      margin: `12px 15px`,
    },
    nameOption: {
      display: "flex",
      color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsla(20, 0%, 18%, 0.95)")}`, //https://hslpicker.com/
      margin: 0,
      fontSize: "20px",
      fontFamily: "InterBold",
    },
    navbar: {
      display: "flex",
      flex: 1.5,
      margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
      marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
    },
    options: {
      display: "flex",
      flex: 11,
      flexDirection: "column",
      alignItems: 'center',
      margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
      //  justifyContent: 'space-between',
      //   margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
    },
    singleOption: {
      display: "flex",
      //   width: `${screenSize > 940 ? ("95%") : ("80vw")}`,
      width: `${screenSize > 940 ? ("100%") : ("80vw")}`,
      flexDirection: "column",
      alignItems: 'space-between',
      justifyContent: 'space-between',
      borderRadius: '5px',
      padding: "15px",
      textDecoration: "none",
      border: 'none',
      outline: 'none',
      margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
    },
    subtitleNavbar: {
      margin: 0,
      fontSize: "14px",
      color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
      fontFamily: "InterBold",
    },
    textsNavbar: {
      display: "flex",
      flex: 8,
      flexDirection: "column",
    },
    titleNavbar: {
      marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
      fontSize: "28px",
      color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
      fontFamily: "InterBold",
      lineHeight: '1.4'
    },
  };

  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <>
      <div style={styles.container}>
        <div style={styles.navbar}>
          <div style={styles.textsNavbar}>
            <h3 style={styles.titleNavbar}>{t('HOME_TITLE')}</h3>
            <h3 style={styles.subtitleNavbar}>{t('HOME_SUBTITLE')}</h3>
          </div>
        </div>
        <div style={styles.options}>
          {loader ? (
            <Loader
              type="tailspin"
            />
          ) : (
            <>
              <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleOption} >
                {/* TODO NOTA Link por url a la aplicacion de gestion de pedidos, cambiamos Link por un div con una función onclick para que nos abra el enlace en una nueva pestaña en las 4 opciones, aunque tickets y documentos estén en esta misma aplicación, pero para que los 4 funcionen de la misma manera*/}
                <Link className="nav-link active" style={styles.link} to="/tickets">
                  {/* <div style={styles.link} onClick={async () =>
                  (window.open(urlPedidos)) //abre en nueva ventana la url indicada
                }> */}
                  <div style={styles.iconDiv}>
                    <img
                      style={styles.icons}
                      src={srcPedidos} // la url la sacamos a constants.js
                      height="64"
                      alt="Módulo de Pedidos"
                    />
                  </div>
                  <div style={styles.lineDetailCenter}>
                    <div style={styles.nameOption}>
                      <div> {t('HOME_PEDIDOS')}</div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
              </div>

              <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleOption} >
                <Link className="nav-link active" style={styles.link} to="/invoices">
                  {/* <Link className="nav-link active" style={styles.link} to="/puturrruuuuuu"> */}
                  {/* <div style={styles.link} onClick={async () =>
                  (window.open(urlFacturas)) //abre en nueva ventana la url indicada
                }> */}
                  <div style={styles.iconDiv}>
                    <img
                      style={styles.icons}
                      src={srcFacturas}
                      height="64"
                      alt="Módulo de Facturas"
                    />
                  </div>
                  <div style={styles.lineDetailCenter}>
                    <div style={styles.nameOption}>
                      <div> {t('HOME_FACTURAS')}</div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
              </div>

              <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleOption} >
                <Link className="nav-link active" style={styles.link} to="/dashboard-jml">
                  <div style={styles.iconDiv}>
                    <img
                      // style={styles.icons} para ajustarle el tamaño
                      src={srcPersonas}
                      height="67"
                      alt="Módulo de Gestión de Personas"
                    />
                  </div>
                  <div style={styles.lineDetailCenter}>
                    <div style={styles.nameOption}>
                      <div> {t('HOME_PERSONAS')}</div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleOption} >
                <Link className="nav-link active" style={styles.link} to="/politics">
                  {/*  <div style={styles.link} onClick={async () =>
                  (window.open(urlDocs)) //abre en nueva ventana la url indicada
                }> */}
                  <div style={styles.iconDiv}>
                    <img
                      style={styles.icons}
                      height="100"
                      src={srcDocumentos}
                      alt="Módulo de Gestión de Documentos"
                    />
                  </div>
                  <div style={styles.lineDetailCenter}>
                    <div style={styles.nameOption}>
                      <div> {t('HOME_DOCUMENTOS')}</div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;