import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import i18next from "i18next";
import { defaultLang } from "../../utils/constants";

// components:
import Loader from '../Loader'

const KnowledgeBase = ({ screenSize, darkModeState, handleClick, articles, loader }) => {
    const styles = {
        container: {
            // position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            //   padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`,
            padding: `${screenSize > 940 ? ("3% 2% 3% 21%") : ("3% 9% 9% 9%")}`,
            height: "100%",
            overflow: `auto`,
        },
        navbar: {
            display: "flex",
            flex: 1.5,
            margin: `${screenSize > 940 ? ("0px 20px") : ("0px")}`,
            marginBottom: `${screenSize > 940 ? ("0px") : ("30px")}`,
        },
        textsNavbar: {
            display: "flex",
            flex: 8,
            flexDirection: "column",
        },
        titleNavbar: {
            marginBottom: `${screenSize > 940 ? ('5px') : ("10px")}`,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        subtitleNavbar: {
            margin: 0,
            fontSize: "14px",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
        },
        indicators: {
            display: "flex",
            flex: 11,
            flexDirection: "column",
        },
        generalIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: `${screenSize > 940 ? ('no-wrap') : ("wrap")}`,
            margin: `${screenSize > 940 ? ('20px 0px') : ("0px 0px 30px")}`,
        },
        singleGenIndicator: {
            display: "flex",
            width: `${screenSize > 940 ? ("22%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'stretch',
            borderRadius: '5px',
            padding: '0px',
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("20px 10px")}`,
        },
        borderCardT: {
            display: "flex",
            height: '4px',
            width: '100%',
            flexDirection: "column",
            backgroundColor: "hsl(195, 100%, 50%)",
            borderRadius: '5px 5px 0px 0px',
        },
        contentSingleIndicator: {
            display: "flex",
            height: '100%',
            //  flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '30px',
        },
        detailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
            letterSpacing: '5px',
            marginBottom: '20px'
        },
        detailIndicators: {
            display: "flex",
            flex: 9,
            flexDirection: "column",
        },
        titleDetailIndicators: {
            display: "flex",
            flex: 2,
            alignItems: 'center',
            fontSize: "24px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(228, 12%, 44%)")}`,
            fontFamily: "InterBold",
            margin: `${screenSize > 940 ? ("0px 20px 20px") : ("0px")}`,
        },
        rowDetailIndicators: {
            display: "flex",
            flex: 10,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // flexDirection: "row",
            flexDirection: `${screenSize > 940 ? ('row') : ("column")}`,
            flexWrap: `${screenSize > 940 ? ('wrap') : ("wrap")}`,
            margin: `${screenSize > 940 ? ('0px') : ("20px 0px")}`,
        },
        singleDetIndicator: {
            display: "flex",
            width: `${screenSize > 940 ? ("47%") : ("80vw")}`,
            flexDirection: "column",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            borderRadius: '5px',
            padding: "25px",
            textDecoration: "none",
            border: 'none',
            outline: 'none',
            margin: `${screenSize > 940 ? ('1.4%') : ("10px")}`,
        },
        lineDetail: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
        },
        lineDetailCenter: {
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: `12px 0px`,
        },
        idDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "12px",
            fontFamily: "InterRegular",
        },
        priorityDetailCard: {
            display: "flex",
            // color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            color: '#fff',
            margin: 0,
            fontSize: "10px",
            fontFamily: "InterRegular",
            padding: '3px 10px',
            backgroundColor: '#4dc2f1',
            borderRadius: '20px',
            width: '60px',
            alignItems: 'center',
            justifyContent: 'center'
        },
        descriptionDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            margin: 0,
            fontSize: "18px",
            fontFamily: "InterBold",
        },
        typeDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: 0,
            fontSize: "14px",
            fontFamily: "InterBold",
        },
        statusDetailCard: {
            display: "flex",
            color: `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,
            margin: "0px 6px",
            fontSize: "14px",
            fontFamily: "InterBold",

        }
    };

    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLng') || defaultLang);
    // eslint-disable-next-line no-unused-vars
    const changeLang = (code, e) => {
        e.preventDefault();
        setCurrentLang(code);
        i18next.changeLanguage(code);
    }

    return (
        <>
            <div style={styles.container}>
                <div style={styles.navbar}>
                    <div style={styles.textsNavbar}>
                        <h3 style={styles.titleNavbar}>{t('KNBASE_LIST_TITLE')}</h3>
                        <h3 style={styles.subtitleNavbar}>{t('KNBASE_LIST_SUBTITLE')}</h3>
                    </div>
                </div>

                {loader ? (
                    <Loader
                        type="tailspin"
                    />
                ) : (<>
                    <div style={styles.indicators}>
                        <div style={styles.generalIndicators}>
                            <Link className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleGenIndicator} to="/bot/knowledge">
                                <div style={styles.borderCardT} />
                                <div style={styles.contentSingleIndicator}>
                                    <div style={styles.textDetailCard}>{t('BOT_SEARCH')}</div>
                                    <img
                                        // src="https://i.postimg.cc/m2xp08F0/robot-1.png"
                                        src="./assets/img/robot-1.png"
                                        height="55"
                                        alt="Robot of Chatbot"
                                    />
                                </div>
                            </Link>
                        </div>
                        <div style={styles.detailIndicators}>
                            <h3 style={styles.titleDetailIndicators}>{t('KNBASE_ARTICLES_TITLE')}</h3>
                            <div style={styles.rowDetailIndicators}>
                                <h1>{t('ERRMSG_IN_DEV')}</h1>


                                {/* no podemos mostrar nada falta el servicio que consulta la lista */}
                                {/* {tickets ? (
                                    tickets.map((ticket) => {
                                        return (
                                            <div key={ticket.id} className={darkModeState ? ("buttonDark") : ("buttonLight")} style={styles.singleDetIndicator} onClick={(e) => handleClick(ticket.id)} >
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.idDetailCard}>#{ticket.id}</div>
                                                    <div style={styles.priorityDetailCard}>{ticket.priority}</div>
                                                </div>
                                                <div style={styles.lineDetailCenter}>
                                                    <div style={styles.descriptionDetailCard}>{ticket.description}</div>
                                                </div>
                                                <div style={styles.lineDetail}>
                                                    <div style={styles.typeDetailCard}>{ticket.type}</div>
                                                    <div style={styles.statusDetailCard}>{ticket.status}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (<></>)} */}
                            </div>
                        </div>
                        <div className="row">
                            <iframe title='default iframe title' width="350" height="430" allow="microphone;" src="https://console.dialogflow.com/api-client/demo/embedded/a6ccccb5-35a2-44a4-b2c7-98c662e645c9"></iframe>
                        </div>
                        <div>
                            <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
                            {/* https://cloud.google.com/dialogflow/es/docs/integrations/dialogflow-messenger */}
                            {/* TODO:GEMA este icono habrá q cambiarlo, en lugar de una url publica lo suyo es que cambiase segun el path para cada compañia, o poner uno comun a todos... */}
                            <df-messenger
                                chat-icon="https:&#x2F;&#x2F;i.postimg.cc&#x2F;RhKdwxd7&#x2F;cropped-logo-io-transparent.png"
                                // chat-icon="./assets/img/eric-feliz.png"
                                intent="WELCOME"
                                chat-title="NewbaITC-(POC)"
                                agent-id="a6ccccb5-35a2-44a4-b2c7-98c662e645c9"
                                language-code={currentLang || 'es'}
                            ></df-messenger>
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
};

export default KnowledgeBase 