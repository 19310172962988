import React from "react";
import { Link } from "react-router-dom";
import { FaInfo } from "react-icons/fa"; //https://react-icons.github.io/react-icons
import { useTranslation } from 'react-i18next';
import { contactEmail } from "../../utils/constants";
import { pathPart } from "../../utils/commons";

// components:
import SelectorLanguages from "../SelectorLanguages";

const Duplicado = ({ screenSize, handleSend, nif, information, date, sinceDate, toDate, typeSelector, message, finished, loading, handleChange, handleInfo, handleBack }) => {
    const companyPath = pathPart(window.location.host, 0)
    const email = contactEmail[companyPath]
    // eslint-disable-next-line no-unused-vars
    const { t, i18n } = useTranslation();

    const styles = {
        arrow: {
            height: 20,
            width: 20,
            display: "flex",
            flex: '1',
            margin: '0px 10px 0px 0px',
            color: 'grey',
        },
        btnInfo: {
            border: 'none',
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            flex: '1',
            padding: '10px 10px',
            borderRadius: 25,
        },
        columnImage: {
            display: "flex",
            height: "100vh",
            width: "60%",
            margin: 0,
            padding: 0,
            backgroundImage:`url(../assets/img/movilidad.jpg)`,
            //SI FUERA POR COMPAÑIA SERIA ASI: backgroundImage:`url(../assets/img/${companyPath}/login-image.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        columnInputs: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            width: `${screenSize > 940 ? ("50vw") : ("100vw")}`,
            margin: 0,
            padding: 0,
        },
        container: {
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            display: "flex",
            margin: 0,
            padding: 0,
            backgroundColor: '#fff'
        },
        date: {
            margin: '0px 0px 20px',
            fontSize: 16,
            fontFamily: "InterRegular",
            padding: '0px 0px 10px',
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            borderBottom: '0.5px solid lightgrey',
            outline: 'none',
            width: '100%'
        },
        divMargin: {
            marginBottom: 10,
            marginRight: 50,
        },
        form: {
            width: "100%",
            padding: 40,
        },
        formButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '8px 32px',
            margin: "30px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: `${screenSize > 800 ? ("70%") : ("90%")}`,
            height: 50
        },
        formButtonDisabled: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: 'grey',
            borderRadius: 25,
            border: 'none',
            padding: '8px 32px',
            margin: "30px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: `${screenSize > 800 ? ("70%") : ("90%")}`,
            height: 50
        },
        formGroup: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 0,
            padding: `${screenSize > 800 ? ('2%') : ('5%')}`,
        },
        formInput: {
            marginBottom: 20,
            fontFamily: "InterRegular",
        },
        formLabel: {
            marginBottom: 10,
            marginRight: 50,
        },
        formLabelInfo: {
            marginBottom: 10,
            marginRight: 50,
            fontFamily: "InterRegular",
            color: '#68595C',
            textAlign: "center",
            fontSize: '85%',
        },
        formSelector: {
            margin: "0px 10px 20px 0px",
            fontFamily: "InterRegular"
            //  padding: '0px 10px'
        },
        formTitle: {
            display: "flex",
            justifyContent: 'center',
            flex: '12',
            width: '100%',
            marginRight: 30
        },
        icons: {
            marginRight: "15px",
            width: '18px',
            height: '20px',
        },
        imageLogo: {
            height: "8%",
            width: "auto",
            position: "relative",
            left: "6%",
            top: "88%",
        },
        inputGroup: {
            display: "flex",
            justifyContent: 'flex-start',
            flexDirection: 'column',
            width: "100%",
        },
        inputGroupRow: {
            display: "flex",
            justifyContent: 'flex-start',
            flexDirection: 'row',
            width: "100%",
        },
        linkButton: {
            paddingRight: 20,
            paddingLeft: 30,
            // marginTop: "2.5%",
            display: "flex",
            alignItems: "center",
            backgroundColor: '#fff',
            color: '#63687e',
            border: 'none',
            outline: 0,
            width: '100%',
            fontSize: '100%'
        },
        linkHome: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            margin: "40px 0px 0px",
            color: '#fff',
            fontWeight: 500,
            outline: 0,
            width: `${screenSize > 800 ? ("70%") : ("90%")}`,
            padding: '13px 32px',
            textAlign: 'center'
        },
        messageFinalGroup: {
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: "100%",
        },
        row: {
            marginRight: 0,
            marginLeft: 0,
            padding: "0.2%"
        },
        selector: {
            width: `${screenSize > 800 ? ("50vw") : ("100vw")}`,
            display: "flex",
            justifyContent: 'right',
            alignItems: "center",
            padding: '1%',
            // marginTop: "-8%"
        },
        textError: {
            margin: 0,
            fontSize: 16,
            color: 'hsl(197, 85%, 62%)',
            alignSelf: 'flex-start',
            lineHeight: 1.5
        },
        title: {
            fontSize: `${screenSize > 800 ? ("28px") : ("25px")}`,
            margin: '0',
            textAlign: "center"
        },
        titleGroup: {
            display: "flex",
            alignSelf: 'flex-start',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: "100%",
            marginBottom: 40,
            marginTop: -20
        },
    }

    
    return (
        <div style={styles.container}>
            {screenSize > 940 ? (
                <div style={styles.columnImage} >
                    <img
                        src={`../assets/img/${companyPath}/logo-transparent.png`}
                        alt={`Logo of of company ${companyPath}`}
                        style={styles.imageLogo}
                    />
                    {/* <img src="../assets/img/logo-high.png" alt="logo of Newba ITC" style={{ height: 45, margin: '-7% 0 10% 0' }} /> */}
                </div>
            ) : (<></>)}
            <div>
                <div className="row" style={styles.row}>
                    <button className="col btn btn-link" style={styles.linkButton} onClick={handleBack}>
                        <svg style={styles.icons} fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                        </svg>
                        {t('SIDEBAR_HOME')}
                    </button>
                    <div className="col" style={styles.selector}>
                        {/* SELECTOR LENGUAJES */}
                        <SelectorLanguages direction='dropleft' screenSize={screenSize} />
                    </div>
                </div>

                <div style={styles.columnInputs} >
                    <form onSubmit={handleSend} style={styles.form}>
                        <div className="form-group" style={styles.formGroup}>
                            <img
                                src={`../assets/img/${companyPath}/logo-high.png`}
                                alt={`Logo of of company ${companyPath}`}
                                style={{ height: 130, margin: '-9% 0 10% 0' }}
                            />

                            <div style={styles.titleGroup}>
                                <Link to="/invoices">
                                    <svg style={styles.arrow} fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>
                                </Link>
                                <div style={styles.formTitle}>
                                    <h1 style={styles.title}>{t('INVOICES_HOME_LINK3')}</h1>
                                </div>

                                {/* Botón de ayuda que al pulsar aparece un popup de ayuda mostrando info al usario, que se configura en el containters y se exporta por props aqui*/}
                                <div>
                                    <button type="button" onClick={handleInfo} style={styles.btnInfo}><FaInfo /></button>
                                </div>
                            </div>

                            {finished ? (
                                <div style={styles.messageFinalGroup}>
                                    <label style={styles.formLabel}>{t('INVOICES_MESSAGE_FINISHED')}</label>
                                    <label style={styles.formLabelInfo}>{t('INVOICES_HOME_FOOTER')}</label>
                                    <Link style={styles.linkHome} to="/invoices">{t('INVOICES_LINK_NEWREQ')}</Link>
                                </div>
                            ) : (
                                <>
                                    <div className="form-group" style={styles.inputGroup}>
                                        <label style={styles.formLabel} htmlFor="nif">{t('INVOICES_LABEL_NIF')}</label>
                                        <input
                                            name="nif"
                                            type="text"
                                            className="form-control"
                                            id="nif"
                                            value={nif}
                                            onChange={(e) => handleChange(e)}
                                            style={styles.formInput}
                                            required
                                        />
                                    </div>
                                    <div className="form-group" style={styles.inputGroup}>
                                        <label style={styles.formLabel} htmlFor="information">{t('INVOICES_LABEL_REQBY')}</label>
                                        <div className="form-group" style={styles.inputGroupRow}>
                                            <select name="selector" className="form-select" style={styles.formSelector} onChange={handleChange}>
                                                {/* React uses value instead of selected for consistency across the form components. You can use defaultValue to set an initial value. You can use defaultValue instead selected */}
                                                {/* <option value="4" selected={typeSelector === "4"}>Fecha de Emisión</option> */}
                                                <option value="2" defaultValue={typeSelector === "2"}>{t('INVOICES_OPTION_NUMERO')}</option>
                                                <option value="1" defaultValue={typeSelector === "1"}>{t('INVOICES_OPTION_ALBARAN')}</option>
                                                <option value="3" defaultValue={typeSelector === "3"}>{t('INVOICES_OPTION_IMPORTE')}</option>
                                                {/* <option value="4" defaultValue={typeSelector === "4"}>Fecha de Emisión</option> */}
                                                <option value="5" defaultValue={typeSelector === "5"}>{t('INVOICES_OPTION_RANGO')}</option>
                                            </select>
                                            {typeSelector === "4" ? (
                                                <input type="date" id="start" name="date"
                                                    //value="2010-07-22"
                                                    min="2019-01-01" max="2023-12-31"
                                                    onChange={handleChange}
                                                    value={date}
                                                    style={styles.date}
                                                    required
                                                />
                                            ) : typeSelector === "5" ? (
                                                //si el ancho de pantalla es menor a 800 tendremos que poner las fechas del rango una bajo la otra, no en paralelo
                                                screenSize > 575 ? (
                                                    <>
                                                        <div style={styles.divMargin}  >
                                                            <label style={styles.formLabel} htmlFor="sinceDate">{t('INVOICES_LABEL_SINCEDATE')}</label>
                                                            <input type="date" id="sinceDate" name="sinceDate"
                                                                //value="2010-07-22"
                                                                min="2019-01-01" max="2025-12-31"
                                                                onChange={handleChange}
                                                                value={sinceDate}
                                                                style={styles.date}
                                                                required
                                                            />
                                                        </div>
                                                        <div style={styles.divMargin} >
                                                            <label style={styles.formLabel} htmlFor="toDate">{t('INVOICES_LABEL_TODATE')}</label>
                                                            <input type="date" id="toDate" name="toDate"
                                                                //value="2010-07-22"
                                                                min="2019-01-01" max="2025-12-31"
                                                                onChange={handleChange}
                                                                value={toDate}
                                                                style={styles.date}
                                                                required
                                                            />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="form-group" style={styles.inputGroup}>
                                                        <label style={styles.formLabel} htmlFor="sinceDate">{t('INVOICES_LABEL_SINCEDATE')}</label>
                                                        <input type="date" id="sinceDate" name="sinceDate"
                                                            min="2019-01-01" max="2025-12-31"
                                                            onChange={handleChange}
                                                            value={sinceDate}
                                                            style={styles.date}
                                                            required
                                                        />
                                                        <label style={styles.formLabel} htmlFor="toDate">{t('INVOICES_LABEL_TODATE')}</label>
                                                        <input type="date" id="toDate" name="toDate"
                                                            min="2019-01-01" max="2025-12-31"
                                                            onChange={handleChange}
                                                            value={toDate}
                                                            style={styles.date}
                                                            required
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <input
                                                    name="information"
                                                    type="text"
                                                    className="form-control"
                                                    id="information"
                                                    value={information}
                                                    onChange={handleChange}
                                                    style={styles.formInput}
                                                    required
                                                />
                                            )}
                                        </div>

                                        {
                                            typeSelector === "3" ? <h3 style={styles.formLabelInfo}> {t('INVOICES_MESSAGE_IMPORTE')}</h3> : <></>
                                        }

                                        {message === '1' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR_NONIF')} <a style={{ color: "grey" }} href={`mailto:${email}`}>{email}</a>{t('INVOICES_MESSAGE_ERR_SORRY')}</h3> : <></>}
                                        {message === '2' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR_NOMAIL')} <a style={{ color: "grey" }} href={`mailto:${email}`}>{email}</a> {t('INVOICES_MESSAGE_ERR_SORRY')}</h3> : <></>}
                                        {message === '3' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR_FORMATNIF')}</h3> : <></>}
                                        {message === '4' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR_NOINPUTNIF')}</h3> : <></>}
                                        {message === '5' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR')}</h3> : <></>}
                                        {message === '6' ? <h3 style={styles.textError}>{t('INVOICES_MESSAGE_ERR_NODOC')}<a style={{ color: "grey" }} href={`mailto:${email}`}>{email}</a>.</h3> : <></>}

                                    </div>
                                </>
                            )}
                            {finished ? (<></>) : (
                                <>
                                    {loading ? (
                                        <button type="submit" style={styles.formButton} disabled>
                                            <div className="spinner-border text-light spinner-border-sm" role="status" />
                                        </button>
                                    ) : (
                                        //<button type="submit" >Solicitar</button> REVISAR ENTRE FECHAS NO FUNCIONA DISABLED
                                        <button type="submit" style={(information === '' && typeSelector !== "5" && typeSelector !== "4") || nif === '' || (typeSelector === "4" && typeSelector !== "5" && date === '') || (typeSelector === "5" && sinceDate === '' && toDate === '') ? (styles.formButtonDisabled) : (styles.formButton)} >{t('INVOICES_BTN_SUBMIT')}</button>
                                    )}

                                    {/*ESTE ES EL BUENO, PARA TENER COPIA <button type="submit" style={(information === '' && typeSelector !== "4") || nif === '' || (typeSelector === "4" && date === '')  ? (styles.formButtonDisabled) : (styles.formButton)} disabled={(information === '' && typeSelector !== "4") || nif === '' || (typeSelector === "4" && date === '')}>Solicitar</button> */}
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Duplicado